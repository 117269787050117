import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import {
  WorkspaceAsset,
  useFilteredWorkspaceRoutines,
  useUpdateMutation,
} from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { Control, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Platform,
  Pressable,
  ScrollView,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { Text } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { array, boolean, number, string, object as yupObject } from 'yup';
import { Spacing } from '../../../styles';
import { Colors } from '../../../themes';
import { SaveFormTop } from '../../forms';
import { TopModalBar } from '../../modals/topModalBar';
import { RequestRoutineModal } from './requestRoutine';

type EditRoutineModalProps = {
  workspaceAsset: WorkspaceAsset;
  editRoutineModalVisible: boolean;
  onClose: () => void;
  modalStyle?: StyleProp<ViewStyle>;
  isWeb?: boolean;
};
export const EditRoutineModal: React.FC<EditRoutineModalProps> = ({
  editRoutineModalVisible,
  onClose,
  workspaceAsset,
  modalStyle,
  isWeb,
}) => {
  const { t } = useTranslation();

  const formSchema = yupObject({
    routines: array().of(
      yupObject({
        routineButtonText: string(),
        title: string(),
        routineId: string(),
        order: number(),
        isAssetRoutine: boolean(),
      })
    ),
  });

  const { mutateAsync: updateWorkspaceAsset } = useUpdateMutation({
    modelName: 'WorkspaceAsset',
    query: UPDATE_WORKSPACEASSET,
  });

  const routineData = useFilteredWorkspaceRoutines(
    {
      workspace: { eq: workspaceAsset.workspace.id },
      deactivated: { ne: true },
    },
    workspaceAsset.workspace.id,
    !workspaceAsset
  );

  const assetRoutines: string[] = useMemo(() => {
    if (!workspaceAsset.routines || workspaceAsset.routines.length === 0) {
      return [];
    }

    return workspaceAsset.routines.map((routine) => routine.id);
  }, [workspaceAsset]);

  const workspaceRoutines = useMemo(() => {
    if (!routineData || routineData.length === 0) {
      return [];
    }
    return routineData
      .filter((r) => r.deactivated !== true)
      .map((routine) => ({
        routineButtonText: routine.config.routineButtonText,
        title: routine.config.title,
        routineId: routine.id,
        order: routine.order,
        isAssetRoutine: assetRoutines.includes(routine.id),
      }));
  }, [routineData, assetRoutines]);

  const selectedRoutines = useMemo(() => {
    return workspaceRoutines
      .filter((routine) => routine.isAssetRoutine === true)
      .sort((a, b) => {
        let routineA, routineB;

        if (
          workspaceAsset.routineOrder &&
          workspaceAsset.routineOrder.length > 0
        ) {
          routineA = workspaceAsset.routineOrder.find(
            (x) => x.workspaceRoutineId === a.routineId
          );
          routineB = workspaceAsset.routineOrder.find(
            (x) => x.workspaceRoutineId === b.routineId
          );
        }
        const aOrder = routineA?.order ?? a.order;
        const bOrder = routineB?.order ?? b.order;
        return aOrder - bOrder;
      });
  }, [workspaceRoutines, workspaceAsset.routineOrder]);

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      routines: selectedRoutines,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    const defaultValues = { routines: selectedRoutines };
    reset(defaultValues);
  }, [routineData, selectedRoutines, reset]);

  const onCancel = () => {
    onClose();
    reset();
  };

  const onSubmit = async (data: any) => {
    const routineList: RoutineButtonData[] = data.routines;

    const routineOrder = routineList.map((routine, i) => {
      return { order: i, workspaceRoutineId: routine.routineId };
    });

    const routines = routineList.map((routine) => {
      return { id: routine.routineId };
    });

    const input = {
      id: workspaceAsset.id,
      routineOrder: routineOrder,
      routines: routines,
    };

    await updateWorkspaceAsset({ input });
    onClose();
  };

  const modalChildren = () => (
    <SaveFormTop
      title={t('routines.editRoutines.title')}
      saveLabel={t('topNav.save')}
      onSubmit={handleSubmit(onSubmit)}
      hasBorder={true}
      onCancel={onCancel}
      topBar={TopModalBar}
      isDirty={isDirty}
      isValid={isValid}
    >
      <SafeAreaView style={styles.contentContainer}>
        <ScrollView>
          <Text style={styles.routineDescription}>
            {t('routines.editRoutines.routineInWorkplace')}
          </Text>
          <RoutineButtons
            routineButtons={workspaceRoutines}
            control={control}
            name="routines"
          />
          <RequestRoutineModal
            assetId={workspaceAsset.asset.id}
            workspaceId={workspaceAsset.workspace.id}
            isWeb={isWeb}
          />
        </ScrollView>
      </SafeAreaView>
    </SaveFormTop>
  );

  return isWeb ? (
    <Modal
      visible={editRoutineModalVisible}
      animationType="fade"
      transparent={true}
      onRequestClose={onCancel}
    >
      <View style={styles.webModalBackground}>
        <View style={styles.modalContainer}>{modalChildren()}</View>
      </View>
    </Modal>
  ) : (
    <Modal
      visible={editRoutineModalVisible}
      presentationStyle={'pageSheet'}
      animationType={'slide'}
      onRequestClose={onCancel}
      style={modalStyle ?? styles.modalContainer}
    >
      {modalChildren()}
    </Modal>
  );
};

type RoutineButtonData = {
  routineId: string;
  routineButtonText: string | undefined;
  title: string;
  order: number;
  isAssetRoutine: boolean;
};
type RoutineButtonsProps = {
  routineButtons: RoutineButtonData[];
  control: Control<any>;
  name: string;
};

const RoutineButtons: React.FC<RoutineButtonsProps> = (props) => {
  const { control, name, routineButtons } = props;

  const {
    fields: selectedRoutines,
    append: appendRoutine,
    remove: removeRoutine,
    move: moveRoutine,
  } = useFieldArray({
    control: control,
    name: name,
  });

  const unselectedRoutines = useMemo(
    () => _.differenceBy(routineButtons, selectedRoutines, 'routineId'),
    [selectedRoutines, routineButtons]
  );

  const onRemoveRoutine = (routine: RoutineButtonData, index: number) => {
    removeRoutine(index);
  };

  const onAppendRoutine = (routine: RoutineButtonData, index: number) => {
    appendRoutine(routine);
  };

  const onMoveDownRoutine = (routine: RoutineButtonData) => {
    const fromIndex = selectedRoutines.findIndex((field) => {
      return field.routineId === routine.routineId;
    });

    if (fromIndex === selectedRoutines.length - 1) return;

    const toIndex = fromIndex + 1;

    routine.order = toIndex;
    routineMove(selectedRoutines, fromIndex, toIndex);
    moveRoutine(fromIndex, toIndex);
  };

  const onMoveupRoutine = (routine: RoutineButtonData) => {
    const fromIndex = selectedRoutines.findIndex((field) => {
      return field.routineId === routine.routineId;
    });

    if (fromIndex === 0) return;

    const toIndex = fromIndex - 1;

    routine.order = toIndex;
    routineMove(selectedRoutines, fromIndex, toIndex);
    moveRoutine(fromIndex, toIndex);
  };

  const routineMove = (
    routineList: RoutineButtonData[],
    fromIndex: number,
    toIndex: number
  ) => {
    if (toIndex >= routineList.length) {
      let k = toIndex - routineList.length + 1;
      while (k--) {
        routineList.push(undefined);
      }
    }
    routineList.splice(toIndex, 0, routineList.splice(fromIndex, 1)[0]);
  };

  return (
    <View style={{ marginBottom: 12 }}>
      {selectedRoutines?.map((routine, index) => {
        return (
          <View key={routine.routineId} style={styles.routineButtons}>
            <AssignRoutineButton
              routine={routine}
              onClick={() => onRemoveRoutine(routine, index)}
              buttonStyle={styles.routineButton}
              leftButtonIcon={
                <MaterialCommunityIcons
                  name={'check'}
                  size={24}
                  color={Colors.blue}
                />
              }
            >
              <View style={styles.moveButtonsContainer}>
                <Pressable
                  style={styles.upDownButton}
                  onPress={() => onMoveupRoutine(routine)}
                >
                  <MaterialCommunityIcons
                    name="chevron-up"
                    size={28}
                    color={Colors.blue}
                  />
                </Pressable>
                <Pressable
                  style={styles.upDownButton}
                  onPress={() => onMoveDownRoutine(routine)}
                >
                  <MaterialCommunityIcons
                    name="chevron-down"
                    size={28}
                    color={Colors.blue}
                  />
                </Pressable>
              </View>
            </AssignRoutineButton>
          </View>
        );
      })}
      {unselectedRoutines?.map((routine, index) => {
        return (
          <View key={routine.routineId} style={styles.routineButtons}>
            <AssignRoutineButton
              routine={routine}
              onClick={() => onAppendRoutine(routine, index)}
              buttonStyle={styles.unselectedRoutineButton}
            />
          </View>
        );
      })}
    </View>
  );
};

type AssingRoutineButtonProps = {
  routine: RoutineButtonData;
  onClick: () => void;
  buttonStyle?: StyleProp<ViewStyle>;
  leftButtonIcon?: ReactNode;
  children?: ReactNode;
};

const AssignRoutineButton = (props: AssingRoutineButtonProps) => {
  const { t } = useTranslation();

  return (
    <TouchableOpacity
      onPress={props.onClick}
      style={[styles.assignButton, props.buttonStyle]}
      activeOpacity={0.7}
    >
      {props.leftButtonIcon && (
        <View style={{ marginRight: 8 }}>{props.leftButtonIcon}</View>
      )}
      <View style={styles.labelContainer}>
        <Text style={styles.assignButtonTitle}>{props.routine.title}</Text>
        <Text style={styles.assignButtonLabel}>
          {t('routines.editRoutines.label')}
          {props.routine.routineButtonText}
        </Text>
      </View>
      {props.children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  webModalBackground: {
    backgroundColor: Colors.blackInactive,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    ...Platform.select({
      web: {
        width: 560,
        height: 650,
        borderRadius: 12,
        backgroundColor: Colors.primaryGrayBackground,
        overflow: 'hidden',
      },
      default: {
        flex: 1,
        height: '100%',
        textAlign: 'center',
      },
    }),
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    paddingVertical: Spacing.basePadding.paddingVertical,
    marginBottom: 0,
    paddingBottom: 60,
    backgroundColor: Colors.primaryGrayBackground,
  },
  routineDescription: {
    paddingBottom: Spacing.basePadding.base / 2,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 16,
  },
  routineButtons: {
    marginVertical: 8,
    flex: 1,
  },
  routineButton: {
    justifyContent: 'flex-start',
    paddingHorizontal: 8,
  },
  moveButtonsContainer: {
    width: 68,
    height: 30,
    right: 0,
    marginLeft: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  upDownButton: {
    width: 30,
    height: 30,
    borderWidth: 2,
    borderColor: Colors.blue,
    borderRadius: 15,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
  },
  unselectedRoutineButton: {
    justifyContent: 'flex-start',
    paddingLeft: 33,
    backgroundColor: Colors.primaryGrayBackground,
  },
  labelContainer: {
    height: 36,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  assignButton: {
    backgroundColor: Colors.white,
    borderRadius: 8,
    borderColor: Colors.blue,
    borderWidth: 2,
    height: 54,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  assignButtonTitle: {
    fontSize: 16,
    fontWeight: '500',
  },
  assignButtonLabel: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.grayText,
  },
});

const UPDATE_WORKSPACEASSET = `
  mutation Mutation($input: MutateWorkspaceAssetInput!) {
  updateWorkspaceAsset(input: $input) {
    id
  }
}
`;
