import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Media, MediaStatus } from '@gripp/shared-logic';
import { useEffect, useMemo, useState } from 'react';
import { Image, Platform, Pressable, StyleSheet, View } from 'react-native';
import { useOpenedState } from '../../hooks/useOpenedState';
import { Colors } from '../../themes';
import LoadingIndicator from '../loadingIndicator';
import { ImageData, SizePrefix, useTypedMediaItem } from '../media';
import { ImageLightBox } from '../modalImages/imageLightbox';

type AssetHeaderImageProps = {
  assetCoverImage: Media;
  imgSize?: number;
  maxRetries?: number;
  retryDelay?: number;
  invalidateQuery?: () => void;
};

export const AssetHeaderImage = (props: AssetHeaderImageProps) => {
  const { isOpened, onOpen, onClose } = useOpenedState();

  const [retryCount, setRetryCount] = useState<number>(0);
  const maxRetries = props.maxRetries ?? 5;
  const retryDelay = props.retryDelay ?? 5000;
  const { typedMediaItem: coverImage, handleRefreshMediaItem } =
    useTypedMediaItem<ImageData>(props.assetCoverImage);

  const isLoading = useMemo(
    () =>
      props?.assetCoverImage?.status === MediaStatus.Pending &&
      retryCount < maxRetries,
    [props?.assetCoverImage, retryCount, maxRetries]
  );
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (!props?.assetCoverImage) {
      handleRefreshMediaItem(undefined);
      return;
    }

    if (isLoading) {
      timeoutId = setTimeout(() => {
        props.invalidateQuery?.();
        setRetryCount((prev) => prev + 1);
      }, retryDelay);
    } else {
      setRetryCount(0);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [props, isLoading, retryDelay, handleRefreshMediaItem]);

  return (
    <View>
      {coverImage ? (
        <>
          <Pressable onPress={onOpen}>
            {isLoading ? (
              <View
                style={[
                  styles.cardImage,
                  {
                    width: props.imgSize || 56,
                    height: props.imgSize || 56,
                    borderWidth: 0,
                  },
                ]}
              >
                <LoadingIndicator size={20} backgroundColor={Colors.white} />
              </View>
            ) : (
              <Image
                style={[
                  styles.cardImage,
                  { width: props.imgSize || 56, height: props.imgSize || 56 },
                ]}
                source={{ uri: coverImage.urlsBySize?.[SizePrefix.extraSmall] }}
              />
            )}
          </Pressable>
          <ImageLightBox
            url={coverImage.urlsBySize?.[SizePrefix.original]}
            isOpened={isOpened}
            onClose={onClose}
          />
        </>
      ) : (
        <MaterialCommunityIcons
          name="qrcode"
          size={props.imgSize || 56}
          style={styles.cardImage}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  cardImage: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    ...Platform.select({
      web: {
        marginRight: 20,
        borderWidth: 1,
        borderColor: Colors.grayDivider,
      },
      default: {
        marginRight: 16,
      },
    }),
  },
});
