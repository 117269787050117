import {
  RoutineType,
  WorkflowQuestionType,
  WorkspaceRoutine,
  useAllWorkspaces,
  useCreateMutation,
  useCurrentWorkspace,
} from '@gripp/shared-logic';
import {
  Colors,
  Layout,
  SaveFormTop,
  Select,
  TopModalBar,
} from '@gripp/shared-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Modal, StyleSheet, View } from 'react-native';
import { Menu } from 'react-native-paper';
import { menuStyles } from './menuStyles';

type CopyRoutineMenuItemProps = {
  workspaceRoutine: WorkspaceRoutine;
  onSelected: () => void;
};

export const CopyRoutineMenuItem = (props: CopyRoutineMenuItemProps) => {
  const { t } = useTranslation();
  const data = useAllWorkspaces();
  const [workspaces, setWorkspaces] = useState<any[]>([]);
  const workspace = useCurrentWorkspace();
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<
    string | undefined
  >();
  const [showModal, setShowModal] = useState(false);
  const { mutateAsync } = useCreateMutation({
    modelName: 'WorkspaceRoutine',
    query: CREATE_ROUTINE,
  });

  useEffect(() => {
    if (workspace) {
      setSelectedWorkspaceId(workspace.id);
    }
  }, [workspace]);

  useEffect(() => {
    if (!data) return;

    setWorkspaces(
      data
        .filter((x) => x.id !== props.workspaceRoutine.workspace?.id)
        .map((w) => ({
          label: w.name,
          value: w.id,
        }))
    );
  }, [data, props.workspaceRoutine.workspace?.id]);

  const onCancel = () => {
    setShowModal(false);
    props.onSelected();
  };

  const onSubmit = async () => {
    const routineToCreate: any = {
      ...props.workspaceRoutine,
      workspace: { id: selectedWorkspaceId },
      order: 0,
    };
    delete routineToCreate.id;

    // wipe out link to embedded routine
    if (routineToCreate.type === RoutineType.Workflow) {
      const questions = routineToCreate.config.pages.flatMap((p) =>
        p.questions.filter((q: any) => q.type === WorkflowQuestionType.Routine)
      );
      questions.forEach((question: any) => {
        question.workspaceRoutineId = undefined;
      });
    }

    await mutateAsync({ input: routineToCreate });

    setShowModal(false);
    props.onSelected();
  };

  return (
    <>
      <Menu.Item
        leadingIcon="content-duplicate"
        style={[menuStyles.white, menuStyles.menuItem]}
        onPress={() => setShowModal(true)}
        title={t('routines.menu.copyToWorkspace')}
      />
      <Modal visible={showModal} onRequestClose={onCancel} transparent>
        <View style={styles.modalContainer}>
          <View style={styles.container}>
            <SaveFormTop
              topBar={TopModalBar}
              isValid={!!selectedWorkspaceId}
              saveLabel={t('routines.copyToWorkspace.save')}
              title={t('routines.copyToWorkspace.title')}
              onCancel={onCancel}
              onSubmit={onSubmit}
            >
              <View style={styles.form}>
                <Select
                  label={t('routines.copyToWorkspace.workspace')}
                  items={workspaces}
                  onSelectItem={(item) => setSelectedWorkspaceId(item)}
                  dropDownPickerProps={{
                    value: workspace?.id,
                  }}
                />
              </View>
            </SaveFormTop>
          </View>
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: Dimensions.get('window').height / 7,
    backgroundColor: Colors.blackInactive,
  },
  container: {
    flex: 1,
    width: '100%',
    maxWidth: 560,
  },
  form: {
    ...Layout.container,
    width: '100%',
    maxWidth: 560,
    maxHeight: 130,
    backgroundColor: Colors.primaryGrayBackground,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    padding: 20,
  },
});

const CREATE_ROUTINE = `
  mutation Mutation($input: CreateWorkspaceRoutineInput!) {
    createWorkspaceRoutine(input: $input) {
      id
    }
  }
`;
