import { BaseTheme, Colors, toastConfig } from '@gripp/shared-ui';
import { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import { PaperProvider } from 'react-native-paper';
import Toast from 'react-native-toast-message';
import { Banner } from './banner';
import { Menu } from './menu';

type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => (
  <PaperProvider theme={BaseTheme}>
    <View style={styles.container}>
      <Menu />
      <View style={styles.row}>
        <Banner />
        <View style={[styles.row, styles.content]}>{children}</View>
      </View>
    </View>
    <Toast config={toastConfig} />
  </PaperProvider>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  row: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: Colors.primaryGrayBackground,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 32,
    paddingHorizontal: 40,
  },
});
