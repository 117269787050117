import { useExportHook, useExportProps } from './types';
import { useEffect, useState } from 'react';
import { useCustomQuery } from '@gripp/shared-logic';
import moment from 'moment';

export const useUserActivityExport: useExportHook = (props: useExportProps) => {
  const [rows, setRows] = useState([]);
  const { data, isLoading } = useCustomQuery({
    cacheKey: ['exportUserActivities', props.workspace?.id],
    query: EXPORT_USER_ACTIVITIES,
    variables: {
      filter: {
        workspaceId: props.workspace?.id,
      },
    },
    options: {
      enabled: props.download,
      gcTime: 10000,
      staleTime: 10000,
    },
  });

  const headers = [
    { label: 'Date', key: 'date' },
    { label: 'Time', key: 'time' },
    { label: 'Person', key: 'person' },
    { label: 'Event Type', key: 'eventType' },
    { label: 'Asset Name', key: 'asset' },
    { label: 'Groups', key: 'groups' },
    { label: 'Workplace', key: 'workspace' },
    { label: 'Location', key: 'location' },
  ];

  useEffect(() => {
    if (!data) return;

    const exportData = data?.activity?.exportUserActivity?.data?.map(
      (activity: any) => ({
        ...activity,
        date: moment(activity.date).format('MM/DD/YYYY'),
        time: moment(activity.date).format('h:mm a'),
      })
    );

    setRows(exportData);
    props.onDataReady();
  }, [data]);

  return {
    headers,
    rows,
    isLoading,
  };
};

const EXPORT_USER_ACTIVITIES = `
  query Activity($filter: ExportUserActivityFilter) {
    activity {
      exportUserActivity(filter: $filter) {
        data {
          date
          person
          eventType
          asset
          groups
          workspace
          location
        }
      }
    }
  } 
`;
