import { EmojiType } from 'rn-emoji-keyboard/lib/typescript/types';
import { Reaction } from '@gripp/shared-logic';

export type UserInfoModal = {
  users: UserInfoReaction[];
  reactionType: EmojiType['emoji'];
};

export type UserInfoReaction = {
  id: string;
  name: {
    first: string;
    last: string;
  };
  reactionId: string;
  avatar: {
    id: string;
    name: string;
    extension: string;
    status: string;
  };
};

export type ReactionMap = {
  reaction: Reaction;
  count: number;
  users: UserInfoReaction[];
};

export enum BaseReaction {
  THUMBS_UP = 'thumbs_up',
  THUMBS_DOWN = 'thumbs_down',
  HEART = 'heart',
  LAUGHING = 'laughing',
  RAISING_HANDS = 'raising_hands',
  RED_QUESTION_MARK = 'red_question_mark',
}

export const BaseReactionMap: Record<BaseReaction, EmojiType> = {
  [BaseReaction.THUMBS_UP]: {
    emoji: '👍',
    name: 'thumbs up',
    slug: 'thumbs_up',
    toneEnabled: true,
    unicode_version: '0.6',
  },
  [BaseReaction.THUMBS_DOWN]: {
    emoji: '👎',
    name: 'thumbs down',
    slug: 'thumbs_down',
    toneEnabled: true,
    unicode_version: '0.6',
  },
  [BaseReaction.HEART]: {
    emoji: '❤️',
    name: 'heart',
    slug: 'heart',
    toneEnabled: true,
    unicode_version: '0.6',
  },
  [BaseReaction.LAUGHING]: {
    emoji: '😂',
    name: 'laughing',
    slug: 'laughing',
    toneEnabled: true,
    unicode_version: '0.6',
  },
  [BaseReaction.RAISING_HANDS]: {
    emoji: '🙌',
    name: 'raising hands',
    slug: 'raising_hands',
    toneEnabled: true,
    unicode_version: '0.6',
  },
  [BaseReaction.RED_QUESTION_MARK]: {
    emoji: '❓',
    name: 'red question mark',
    slug: 'red_question_mark',
    toneEnabled: false,
    unicode_version: '0.6',
  },
};
