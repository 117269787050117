import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import {
  CheckboxQuestionConfig,
  CheckboxResponseState,
} from '@gripp/shared-logic';
import { Text } from 'react-native-paper';
import { RoutineQuestionMessage, RoutineQuestionMessageProps } from '../types';

export const CheckboxQuestionMessage: RoutineQuestionMessage<
  CheckboxQuestionConfig,
  CheckboxResponseState
> = (
  props: RoutineQuestionMessageProps<
    CheckboxQuestionConfig,
    CheckboxResponseState
  >
) => {
  const { response, question } = props;

  return question?.options.map((option: string, index: number) => (
    <Text style={props.textStyle} key={`${response?.questionId}-${index}`}>
      [
      {response.options.includes(option) ? (
        <Icon name="check" style={props.textStyle} />
      ) : (
        '   '
      )}
      ] {option}
    </Text>
  ));
};
