import { BlurView } from 'expo-blur';
import React, { useEffect, useRef, useState } from 'react';
import {
  Animated,
  Modal,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { Colors } from '../../../themes/colors';

type ReactionPickerProps = {
  children: React.ReactNode;
  isVisible: boolean;
  onDismiss: () => void;
  anchorRef: React.RefObject<View>;
  showContainer: boolean;
};

export const ReactionPickerView = ({
  children,
  isVisible,
  onDismiss,
  anchorRef,
  showContainer,
}: ReactionPickerProps) => {
  const opacity = useRef(new Animated.Value(0)).current;
  const translateY = useRef(new Animated.Value(0)).current;
  const scaleContainer = useRef(new Animated.Value(0.5)).current;
  const yAnimationDist = 30;
  const menuHeight = 50;

  const [containerPosition, setContainerPosition] = useState({
    top: 0,
    right: 0,
  });

  useEffect(() => {
    if (isVisible && showContainer) {
      Animated.parallel([
        Animated.timing(opacity, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(translateY, {
          toValue: -yAnimationDist,
          duration: 200,
          useNativeDriver: true,
        }),
        Animated.timing(scaleContainer, {
          toValue: 1.0,
          duration: 200,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.timing(opacity, {
          toValue: 0,
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(translateY, {
          toValue: 0,
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(scaleContainer, {
          toValue: 0.5,
          duration: 50,
          useNativeDriver: true,
        }),
      ]).start();
    }
  }, [isVisible, opacity, translateY, scaleContainer, showContainer]);

  // Calculate position based on anchor ref
  useEffect(() => {
    if (isVisible && anchorRef?.current) {
      anchorRef.current.measureInWindow((x, y, width, height) => {
        setContainerPosition({
          top: y + yAnimationDist + (height - menuHeight) / 2,
          right: x,
        });
      });
    }
  }, [isVisible, anchorRef]);

  return (
    <Modal transparent visible={isVisible} animationType="fade">
      <BlurView intensity={5} style={{ flex: 1 }}>
        <TouchableWithoutFeedback onPress={onDismiss}>
          <View
            style={showContainer ? styles.overlayDark : styles.overlayHidden}
          >
            <TouchableWithoutFeedback>
              <Animated.View
                style={[
                  styles.container,
                  {
                    opacity,
                    transform: [{ translateY }, { scale: scaleContainer }],
                    top: containerPosition.top,
                    height: menuHeight,
                  },
                  { backgroundColor: showContainer ? Colors.white : undefined },
                ]}
              >
                {children}
              </Animated.View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </BlurView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  overlayDark: {
    flex: 1,
    backgroundColor: Colors.blackOverlay,
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayHidden: {
    backgroundColor: undefined,
  },
  container: {
    position: 'absolute',
    left: 20,
    right: 20,
    borderRadius: 24,
    overflow: 'hidden',
    shadowColor: Colors.black,
    shadowOffset: { width: 4, height: 8 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
  },
});
