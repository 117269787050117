import { BaseColumnCheckbox, Colors } from '@gripp/shared-ui';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Platform, StyleProp, StyleSheet, Text } from 'react-native';

const BaseColumns: Record<string, { element: (info: any) => JSX.Element }> = {
  name: {
    element: (info) => getInfoLabel(styles.nameLabel, info),
  },
  jobTitle: {
    element: (info) => getInfoLabel(styles.greyLabel, info),
  },
  accountType: {
    element: (info) => getAccountLabel(styles.greyLabel, info),
  },
};

const getAccountLabel = (labelStyle: StyleProp<any>, info: any) => {
  return getLabel(labelStyle, info.getValue()[0].name);
};

const getInfoLabel = (labelStyle: StyleProp<any>, info: any) => {
  return getLabel(labelStyle, info.getValue());
};

const getLabel = (labelStyle: StyleProp<any>, label: string) => {
  return <Text style={[labelStyle, styles.cellText]}>{label}</Text>;
};

export const useAccountColumns = () => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper();

  const createCheckboxColumn = (
    accessor: string,
    cell: (info: any) => JSX.Element,
    header: (info: any) => JSX.Element
  ) => {
    return columnHelper.accessor(accessor, {
      cell: cell,
      header: header,
      meta: { style: styles.checkboxCell },
    });
  };

  const createInfoColumn = (
    accessor: string,
    cell: (info: any) => JSX.Element
  ) => {
    return columnHelper.accessor(accessor, {
      cell: cell,
      header: () => t(`groups.assignments.${accessor}`),
      meta: {
        style: styles.cell,
      },
    });
  };

  const updatedColumns: ColumnDef<unknown, never>[] = [];

  updatedColumns.push(
    createCheckboxColumn(
      'selected',
      BaseColumnCheckbox.checkbox,
      BaseColumnCheckbox.checkboxHeader
    )
  );

  Object.entries(BaseColumns).forEach(([key, cell]) => {
    updatedColumns.push(createInfoColumn(key, cell.element));
  });

  return updatedColumns;
};

const styles = StyleSheet.create({
  cell: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    paddingVertical: 4,
    ...Platform.select({
      web: {
        height: 50,
        paddingRight: 16,
      },
      default: {
        height: 68,
        paddingRight: 12,
      },
    }),
  },
  checkboxCell: {
    maxWidth: 50,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  nameLabel: {
    color: Colors.black,
  },
  greyLabel: {
    color: Colors.grayText,
  },
  cellText: {
    fontSize: 16,
    lineHeight: 17,
    ...Platform.select({
      web: {
        fontWeight: '500',
        flexWrap: 'nowrap',
      },
      default: {
        fontWeight: '400',
        flexWrap: 'wrap',
      },
    }),
  },
});
