import { PickerMode } from '@gripp/shared-logic';
import { useEffect, useRef, useState } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Colors } from '../../themes/colors';
import { RenderMediaItem } from './renderMediaItem';
import { MediaGalleryProps } from './types';
import { useTypedMedia } from './useTypedMedia';

export const HorizontalMediaGallery = (props: MediaGalleryProps) => {
  const { handleAdd, handleUploadProgress, typedMedia, handleDelete } =
    useTypedMedia(props.media);
  const [contentContainerStyle, setContentContainerStyle] = useState({} as any);
  const MediaPicker = props.mediaPicker;
  const scrollRef = useRef<ScrollView>(null);

  useEffect(() => {
    if (Platform.OS === 'web') {
      let width = (typedMedia.length - 1) * 20 + 80;
      if (width < 380) width = 380;
      setContentContainerStyle({
        width: width,
      });
    }
    scrollRef.current?.scrollToEnd({ animated: true });
  }, [typedMedia?.length]);

  return (
    <View style={styles.container}>
      <MediaPicker
        workspaceId={props.workspaceId}
        setMedia={(media) => handleAdd(media, props.onMediaAdded)}
        mode={PickerMode.CAMERA}
        icon={'attachment'}
        iconColor={Colors.primaryDark}
        iconSize={24}
        setProgress={handleUploadProgress}
        setUploading={props.onUploadingChanged}
        style={styles.picker}
        acceptedMediaTypes={props.acceptedMediaTypes}
      />
      <ScrollView
        ref={scrollRef}
        horizontal={true}
        showsHorizontalScrollIndicator={true}
        scrollEnabled={true}
        contentContainerStyle={contentContainerStyle}
        persistentScrollbar={true}
      >
        {typedMedia.map((m) => (
          <View style={styles.mediaItemContainer} key={m.id}>
            <RenderMediaItem
              mediaItem={m}
              key={m.id}
              canDelete={props.canEditMedia}
              deleteMediaItem={(mediaId) =>
                handleDelete(mediaId, props.onMediaDeleted)
              }
            />
          </View>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    display: 'flex',
    alignSelf: 'flex-start',
    justifyContent: 'space-between',
  },
  picker: {
    alignSelf: 'center',
    height: 80,
    width: 80,
    borderRadius: 8,
    marginLeft: 2,
    marginRight: 2,
    marginBottom: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.primaryGrayBackground,
  },
  mediaItemContainer: {
    marginLeft: 8,
    marginRight: 2,
  },
});
