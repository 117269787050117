import { StyleSheet, View } from 'react-native';
import { StepBackButton } from './stepBackButton';
import { StepPrimaryButton } from './stepPrimaryButton';

type Props = {
  label: string;
  onPress: () => void;
  onBack?: () => void;
  disabledStepPrimary?: boolean;
  disabledStepBack?: boolean;
};

export const StepButtons = ({
  label,
  onPress,
  onBack,
  disabledStepPrimary = false,
  disabledStepBack = false,
}: Props) => {
  return (
    <View style={styles.container}>
      <StepPrimaryButton
        onPress={onPress}
        label={label}
        disabled={disabledStepPrimary}
      />
      {onBack && (
        <StepBackButton onPress={onBack} disabled={disabledStepBack} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 100,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});
