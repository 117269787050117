import {
  ActivityFilterKey,
  PermissionValues,
  useCurrentAccount,
  useCurrentWorkspace,
} from '@gripp/shared-logic';
import { ActivitySearchInput, FilterType } from '@gripp/shared-ui';
import { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Outlet } from 'react-router-dom';
import { CurrentIssueContextProvider } from '../../issues/issueContext';
import { FilterBar } from '../../shared/components/filter/filterBar';
import { ActivitiesProps, ActivityList } from './activityList';

export const ActivityFold = (props: ActivitiesProps) => {
  const workspace = useCurrentWorkspace(true);
  const { account } = useCurrentAccount();
  const isImpersonateWorkspace = useMemo(
    () =>
      workspace &&
      account &&
      account.hasPermission(PermissionValues.globalAdmin),
    [account, workspace]
  );
  const heightStyle = { maxHeight: props.maxHeight };
  const listMaxHeight = isImpersonateWorkspace
    ? (props?.maxHeight ?? 0) - 50
    : props.maxHeight ?? 0;

  const filterKey = props.assetId
    ? ActivityFilterKey.AssetActivity
    : ActivityFilterKey.Activity;

  return (
    <CurrentIssueContextProvider>
      <View style={styles.filtersContainer}>
        <View style={styles.activitySearchContainer}>
          <ActivitySearchInput filterKey={filterKey} />
        </View>
        <FilterBar
          filterButtonTypes={[
            FilterType.ACTIVITYGROUPS,
            FilterType.ACTIVITYTYPES,
            FilterType.ACTIVITYDATE,
          ]}
          filterKey={filterKey}
        />
      </View>
      <View style={[styles.container, heightStyle]}>
        <View style={styles.listContainer}>
          <ActivityList listMaxHeight={listMaxHeight} {...props} />
        </View>
        <View style={styles.gutter} />
        <Outlet />
      </View>
    </CurrentIssueContextProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  filtersContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 44,
    marginBottom: 20,
  },
  activitySearchContainer: {
    width: 350,
    marginRight: 20,
  },
  listContainer: {
    width: '45%',
    overflow: 'hidden',
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
  },
  gutter: {
    width: 24,
  },
});
