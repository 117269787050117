import { Forbidden } from './components/forbidden';
import { Login } from './components/login';
import Register from './components/register';
import VerifyAuthCode from './components/verifyAuthCode';
import { authPaths } from './paths';

export const authRoutes = [
  { path: authPaths.login, element: <Login /> },
  { path: authPaths.register, element: <Register /> },
  { path: authPaths.forbidden, element: <Forbidden /> },
  { path: authPaths.verifyAuthCode, element: <VerifyAuthCode /> },
];
