import { useStorageKeys } from '@gripp/shared-logic';
import { VerifyAuthCode as VerifyAuthCodeBase } from '@gripp/shared-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { localStorageHandler } from '../../shared/localStorageHandler';

export default function VerifyAuthCode() {
  const navigate = useNavigate();
  const [registerStr, setRegisterStr] = useState('');
  const storageKeys = useStorageKeys();

  useEffect(() => {
    const checkNewRegister = async () => {
      const register = await localStorageHandler.getItem(
        storageKeys.newRegister
      );
      if (register) setRegisterStr(register);
    };

    checkNewRegister();
  }, []);

  const handleRedirect = async (redirectPath: string) => {
    navigate(redirectPath);
    return Promise.resolve();
  };

  return (
    <VerifyAuthCodeBase
      onTryAnotherMethod={async () => {
        await localStorageHandler.setItem(storageKeys.newRegister, '');
        navigate('/login');
      }}
      handleRedirect={handleRedirect}
      hideBackButton
      isNewAccount={registerStr === 'register'}
    />
  );
}
