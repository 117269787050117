import { Pressable, StyleSheet, View } from 'react-native';
import { EmojiType } from 'rn-emoji-keyboard/lib/typescript/types';
import { AddEmojiIcon } from '../../emoji/addEmojiIcon';
import { SingleEmoji } from '../../emoji/singleEmoji';
import { BaseReaction, BaseReactionMap } from './types';

type EmojiPickerBaseRowProps = {
  onOpenEmojiPicker: () => void;
  onEmojiSelected: (emoji: EmojiType) => void;
};

export const EmojiPickerBaseRow = (props: EmojiPickerBaseRowProps) => (
  <View style={styles.baseReactionRowContainer}>
    {Object.entries(BaseReaction).map(([key, value]) => (
      <SingleEmoji
        key={`single-emoji-${key}`}
        emojiStyle={styles.emojiStyle}
        emoji={BaseReactionMap[value].emoji}
        onPress={() => props.onEmojiSelected(BaseReactionMap[value])}
      />
    ))}

    <FindEmojiButton onOpenEmojiPicker={props.onOpenEmojiPicker} />
  </View>
);

export const FindEmojiButton = (props: Partial<EmojiPickerBaseRowProps>) => (
  <Pressable onPress={props.onOpenEmojiPicker}>
    <AddEmojiIcon />
  </Pressable>
);

const styles = StyleSheet.create({
  baseReactionRowContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 20,
  },
  emojiStyle: {
    fontWeight: 400,
    fontSize: 24,
  },
});
