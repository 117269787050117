import {
  AssetFilterBaseColumns,
  AssetFilterModel,
  GlobalAdminColumns,
  useAssetListFilter,
  useCurrentWorkspace,
  useSetAssetListFilter,
  useStatefulWorkspaceRoutines,
  useValidateAssetListOrder,
} from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, View } from 'react-native';
import { array, boolean, string, object as yupObject } from 'yup';
import { FormContext } from '../forms';
import { DropdownFooter } from '../modals/dropdownFooter';
import { ApplyButton } from './applyButton';
import { CheckBoxSelector } from './checkBoxSelector';
import { ColumnSelection } from './columnSelection';
import { FilterType } from './filterButton';
import { FilterHeader } from './filterHeader';
import { filterStyles as styles } from './filterStyle';
import { GroupFilter } from './groupFilter';
import { SwitchToggle } from './switchToggle';

interface AssetFilterProps {
  closeFilter: () => void;
  type: FilterType;
}

export const AssetFilter = ({ closeFilter, type }: AssetFilterProps) => {
  const { t } = useTranslation();
  const workspace = useCurrentWorkspace(true);
  const workspaceRoutines = useStatefulWorkspaceRoutines(workspace?.id);
  const isWeb = Platform.OS === 'web';

  const { data: assetFilter } = useAssetListFilter();
  const validateAssetListOrder = useValidateAssetListOrder();

  const { mutateAsync: setAssetListFilter } = useSetAssetListFilter();

  const formSchema = yupObject({
    ...{ keyword: string().optional().nullable() },
    columns: array().of(string().optional().nullable()).optional().nullable(),
    groups: array().of(string().optional().nullable()).optional().nullable(),
    isCombinedGroupsFilter: boolean().optional().nullable(),
  });

  const formMethods = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    values: {
      columns: assetFilter?.columns,
      keyword: assetFilter?.keyword || '',
      groups: assetFilter?.groups,
      isCombinedGroupsFilter: assetFilter?.isCombinedGroupsFilter,
    },
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
    getValues,
    setValue,
    watch,
  } = formMethods;
  const selectedColumns = watch('columns');

  const isColumnsFilter = type === FilterType.ASSETCOLUMNS;
  const isGroupsFilter = type === FilterType.ASSETGROUPS;

  const resetForm = useCallback(() => {
    reset({
      ...selectedColumns,
      ...(isColumnsFilter && { columns: new AssetFilterModel().columns }),
      ...(isGroupsFilter && { groups: [], isCombinedGroupsFilter: false }),
    });
  }, [reset, isColumnsFilter, isGroupsFilter, selectedColumns]);

  const onReset = async () => {
    const updatedFields = {
      ...(isColumnsFilter && { columns: new AssetFilterModel().columns }),
      ...(isGroupsFilter && { groups: [], isCombinedGroupsFilter: false }),
    };

    resetForm();

    const mergedFilters = { ...assetFilter, ...updatedFields };
    await applyFilter(new AssetFilterModel(mergedFilters));
  };

  const onSubmit = async (data: any) => {
    if (!data?.keyword.length) {
      delete data.keyword;
    }
    await applyFilter(new AssetFilterModel(data));
  };

  const applyFilter = async (filterModel: any) => {
    await Promise.all([
      setAssetListFilter(filterModel),
      validateAssetListOrder(filterModel),
    ]);

    closeFilter();
  };

  const canReset = () => {
    return !Object.values(getValues()).every((value) => value === '');
  };

  const onColumnChange = (column: string) => {
    if (selectedColumns?.includes(column)) {
      setValue(
        'columns',
        selectedColumns.filter((c) => c !== column),
        { shouldDirty: true, shouldTouch: true, shouldValidate: true }
      );
    } else {
      setValue('columns', [...(selectedColumns || []), column], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  };

  useEffect(() => {
    if (!assetFilter) {
      resetForm();
    }
  }, [assetFilter, resetForm]);

  const renderGroups = () => {
    return (
      workspace && (
        <View style={styles.groupsSection}>
          <GroupFilter
            fieldName="groups"
            workspace={workspace}
            headerStyle={styles.filterRowHeader}
          />
        </View>
      )
    );
  };

  const renderStandardData = () => {
    return (
      <ColumnSelection
        baseColumns={AssetFilterBaseColumns}
        adminColumns={GlobalAdminColumns}
        i18nPrefix="assets.filter"
      >
        {workspaceRoutines?.map((routine) => (
          <CheckBoxSelector
            key={routine.id}
            option={`routine:${routine.id}`}
            options={selectedColumns}
            onChange={onColumnChange}
            label={routine.name!}
          />
        ))}
      </ColumnSelection>
    );
  };

  return (
    <FormContext.Provider value={formMethods}>
      {!isWeb && (
        <FilterHeader
          canReset={canReset}
          onReset={onReset}
          goBack={closeFilter}
        />
      )}
      <View style={styles.container}>
        {isGroupsFilter && (
          <SwitchToggle
            fieldName="isCombinedGroupsFilter"
            label={t('assets.filter.filterToggle')}
          />
        )}
        <View
          style={[
            styles.scrollViewContainer,
            isWeb ? { height: 416 } : { marginBottom: 30 },
          ]}
        >
          <ScrollView
            contentContainerStyle={styles.scrollViewContent}
            scrollEnabled={true}
            showsVerticalScrollIndicator={true}
          >
            {isGroupsFilter && renderGroups()}
            {isColumnsFilter && renderStandardData()}
          </ScrollView>
        </View>

        {isWeb ? (
          <DropdownFooter
            isDirty={isDirty}
            onSubmit={handleSubmit(onSubmit)}
            canReset={canReset}
            onReset={onReset}
          />
        ) : (
          <ApplyButton isDirty={isDirty} onSubmit={handleSubmit(onSubmit)} />
        )}
      </View>
    </FormContext.Provider>
  );
};
