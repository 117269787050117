import Svg, { Path } from 'react-native-svg';
import { Colors } from '../../themes';

export const AddEmojiIcon = ({ size = 28, color = Colors.blackText }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 33 32" fill="none">
      <Path
        d="M8.50004 13.3333C8.50004 12.2267 9.39337 11.3333 10.5 11.3333C11.6067 11.3333 12.5 12.2267 12.5 13.3333C12.5 14.44 11.6067 15.3333 10.5 15.3333C9.39337 15.3333 8.50004 14.44 8.50004 13.3333ZM15.1667 24C18.2734 24 20.9134 22.0533 21.98 19.3333H8.35337C9.42004 22.0533 12.06 24 15.1667 24ZM19.8334 15.3333C20.94 15.3333 21.8334 14.44 21.8334 13.3333C21.8334 12.2267 20.94 11.3333 19.8334 11.3333C18.7267 11.3333 17.8334 12.2267 17.8334 13.3333C17.8334 14.44 18.7267 15.3333 19.8334 15.3333ZM28.5 2H25.8334V4.66667H23.1667V7.33333H25.8334V10H28.5V7.33333H31.1667V4.66667H28.5V2ZM25.8334 16.6667C25.8334 22.56 21.06 27.3333 15.1667 27.3333C9.27337 27.3333 4.50004 22.56 4.50004 16.6667C4.50004 10.7733 9.27337 6 15.1667 6C17.1134 6 18.9267 6.53333 20.5 7.44V4.45333C18.86 3.73333 17.06 3.33333 15.1534 3.33333C7.79337 3.33333 1.83337 9.30667 1.83337 16.6667C1.83337 24.0267 7.79337 30 15.1534 30C22.5267 30 28.5 24.0267 28.5 16.6667C28.5 15.2667 28.2734 13.9333 27.8734 12.6667H25.0334C25.54 13.9067 25.8334 15.2533 25.8334 16.6667Z"
        fill={color}
      />
    </Svg>
  );
};
