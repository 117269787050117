import { MaterialIcons } from '@expo/vector-icons';
import { FeatureName, useIsFeatureEnabled } from '@gripp/shared-logic';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  KeyboardAvoidingView,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Colors } from '../../themes';
import Credentials from './credentials';
import { DividerText } from './dividerText';
import EmailCodeLogin from './emailCodeLogin';
import { LogoHeader } from './logoHeader';
import { LoginProps } from './props';
import SmsCodeLogin from './smsCodeLogin';
import { styles } from './styles';
import { ToggleLoginType } from './toggleLoginType';

const enum LoginStrategy {
  SMS = 'SMS',
  Email = 'EMAIL',
  Password = 'PASSWORD',
}

const IconButton = ({
  icon,
  onPress,
  label,
}: {
  icon: keyof typeof MaterialIcons.glyphMap;
  onPress: () => void;
  label: string;
}) => {
  return (
    <Pressable style={localStyles.iconBtn} onPress={onPress}>
      <MaterialIcons name={icon} style={localStyles.icon} size={24} />
      <View>
        <Text style={localStyles.btnLabel}>{label}</Text>
      </View>
    </Pressable>
  );
};

export const CodeLogin = (props: LoginProps) => {
  const { t } = useTranslation();

  const [loginStrategy, setLoginStrategy] = useState(LoginStrategy.SMS);

  const isNewRegistrationFlow = useIsFeatureEnabled(
    FeatureName.NewRegistrationFlow
  );

  const renderFooter = isNewRegistrationFlow ? (
    <View>
      <Text style={localStyles.description}>
        {loginStrategy === LoginStrategy.SMS
          ? t('login.smsConfirmationMessage')
          : t('login.emailConfirmationMessage')}
      </Text>
      <DividerText text={t('or')} />
      <View style={localStyles.toggleNewContainer}>
        <IconButton
          icon={
            loginStrategy === LoginStrategy.SMS
              ? 'mail-outline'
              : 'phone-iphone'
          }
          onPress={() =>
            setLoginStrategy(
              loginStrategy === LoginStrategy.SMS
                ? LoginStrategy.Email
                : LoginStrategy.SMS
            )
          }
          label={
            loginStrategy === LoginStrategy.SMS
              ? t('login.withEmail')
              : t('login.withPhone')
          }
        />
      </View>
    </View>
  ) : (
    <View style={localStyles.toggleContainer}>
      <ToggleLoginType
        text={
          loginStrategy === LoginStrategy.SMS
            ? t('login.withEmail')
            : t('login.withPhone')
        }
        onChangeLoginType={() =>
          setLoginStrategy(
            loginStrategy === LoginStrategy.SMS
              ? LoginStrategy.Email
              : LoginStrategy.SMS
          )
        }
      />
      <DividerText text={t('or')} />
      <ToggleLoginType
        text={
          loginStrategy === LoginStrategy.Password
            ? t('login.withEmail')
            : t('login.withPassword')
        }
        onChangeLoginType={() =>
          setLoginStrategy(
            loginStrategy === LoginStrategy.Password
              ? LoginStrategy.Email
              : LoginStrategy.Password
          )
        }
      />
    </View>
  );

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? -90 : -120}
      style={styles.container}
    >
      {!props.hideLogoHeader && <LogoHeader />}
      {loginStrategy === LoginStrategy.SMS && <SmsCodeLogin {...props} />}
      {loginStrategy === LoginStrategy.Email && <EmailCodeLogin {...props} />}
      {loginStrategy === LoginStrategy.Password && <Credentials {...props} />}
      {renderFooter}
    </KeyboardAvoidingView>
  );
};

const localStyles = StyleSheet.create({
  toggleContainer: {
    marginTop: 60,
    minWidth: 260,
  },
  toggleNewContainer: {
    minWidth: 350,
    flexDirection: 'column',
    gap: 20,
    marginTop: 30,
  },
  iconBtn: {
    borderWidth: 1,
    borderColor: Colors.blackText,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 44,
  },
  btnLabel: {
    fontSize: 16,
    lineHeight: 16,
    fontWeight: '500',
  },
  icon: { position: 'absolute', left: 20 },
  description: {
    maxWidth: 350,
    marginTop: 12,
    marginBottom: 30,
    color: Colors.grayText,
    fontSize: 12,
  },
});
