import { StyleSheet, Platform } from 'react-native';
import { Colors } from '../../themes/colors';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.primaryGrayBackground,
    flex: 1,
    width: '100%',
    top: 0,
    paddingHorizontal: 20,
    ...Platform.select({
      web: {
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        left: 0,
        right: 0,
      },
    }),
  },
  headerImage: {
    paddingBottom: 65,
  },
  textInput: {
    width: '100%',
    height: 52,
    borderRadius: 6,
    backgroundColor: Colors.white,
    ...Platform.select({
      web: {
        maxWidth: 350,
      },
    }),
  },
  buttonText: {
    color: Colors.white,
    lineHeight: 16,
    fontWeight: '600',
    textAlign: 'center',
  },
  containerButton: {
    width: '100%',
    ...Platform.select({
      web: {
        maxWidth: 350,
      },
    }),
  },
  button: {
    height: 44,
    width: '100%',
    borderRadius: 8,
    justifyContent: 'center',
    borderWidth: 2,
    ...Platform.select({
      web: {
        maxWidth: 353,
      },
    }),
  },
  disabledButton: {
    backgroundColor: Colors.grayDisabled,
  },
  enabledButton: {
    backgroundColor: Colors.primary,
  },
  text: {
    paddingBottom: 16,
    lineHeight: 19,
    fontSize: 16,
    color: Colors.black,
    textAlign: 'center',
  },
  errorContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: Colors.danger,
    backgroundColor: Colors.white,
    color: Colors.white,
  },
  dividerContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    height: 1,
    paddingHorizontal: 56,
    marginBottom: 10,
    position: 'relative',
    marginTop: 10,
    ...Platform.select({
      web: {
        maxWidth: 350,
        paddingHorizontal: 0,
      },
    }),
  },
  divider: {
    backgroundColor: Colors.grayDivider,
    flex: 1,
    height: 1,
  },
  dividerText: {
    position: 'relative',
    bottom: 1,
    fontSize: 16,
    textAlign: 'center',
    paddingHorizontal: 8,
    height: 20,
    color: Colors.grayFill,
  },
  toggleContainer: {
    width: '100%',
    alignItems: 'center',
    paddingHorizontal: 56,
    ...Platform.select({
      web: {
        maxWidth: 353,
        paddingHorizontal: 38,
      },
    }),
  },
  toggleButton: {
    borderWidth: 0,
    backgroundColor: Colors.primaryGrayBackground,
    ...Platform.select({
      web: {
        maxWidth: 353,
      },
    }),
  },
  toggleText: {
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 16,
    color: Colors.primaryDark,
  },
  completedContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 12,
  },
  inputContainer: { width: 350, paddingBottom: 12 },
});
