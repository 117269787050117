import {
  Media,
  MediaPickerComponent,
  useCurrentLocation,
  useCurrentWorkspace,
  useCustomMutation,
  useGroups,
} from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocalSearchParams } from 'expo-router';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View, ViewStyle } from 'react-native';
import { Text } from 'react-native-paper';
import { string, object as yupObject } from 'yup';
import { DividerText } from '../../screens';
import { Layout, Spacing } from '../../styles';
import { Colors } from '../../themes';
import {
  Accordion,
  ControlledMultiSelectDropdown,
  ControlledTextInput,
} from '../forms';
import { KeyboardSafeView } from '../keyboard/keyboardSafeView';
import { HorizontalMediaGallery, SingleImageInput } from '../media';
import { LeftButton, SaveButton } from '../navigation';

export type NewAssetStyles = {
  container: ViewStyle;
  contentContainer: ViewStyle;
  imageGalleryContainer?: ViewStyle;
};

export type NewAssetProps = {
  onCancel: () => void;
  mediaPicker: MediaPickerComponent;
  topComponentBar: any;
  styles: StyleSheet.NamedStyles<NewAssetStyles>;
  onSaved: (assetId: string) => void;
};

export const NewAsset = (props: NewAssetProps) => {
  const MediaPicker = props.mediaPicker;
  const TopComponentBar = props.topComponentBar;

  const { t } = useTranslation();
  const params = useLocalSearchParams();
  const { tagId, tagCode } = params;
  const [coverImageId, setCoverImageId] = useState<string | undefined>();
  const [uploading, setUploading] = useState(false);
  const { mutate, isPending: isLoading } = useCustomMutation({
    query: createAsset,
  });
  const [assetMedia, setAssetMedia] = useState<string[]>([]);
  const workspace = useCurrentWorkspace();
  const { getLocation } = useCurrentLocation();
  const { groups } = useGroups(workspace?.id);

  const addMedia = (newMediaItem: Media) => {
    if (!newMediaItem) return;
    setAssetMedia((prevState) => [...prevState, newMediaItem.id]);
  };

  const deleteMedia = (mediaId: string) => {
    setAssetMedia((prevState) => prevState.filter((id) => id !== mediaId));
  };

  const formSchema = yupObject({
    name: string().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      description: '',
      tagCode: tagCode,
    },
  });

  const canSave = useMemo(() => {
    return isDirty && isValid && !uploading && !isLoading;
  }, [isValid, uploading, isLoading, isDirty]);

  const setCoverImage = (image: Media) => {
    setCoverImageId(image.id);
  };

  const onSubmit = async (data: any) => {
    let location;
    if (tagId) {
      location = await getLocation();
    }
    delete data.tagCode;
    const input: any = {
      ...data,
      ...(tagId && { tagId }),
      ...(location && { lastLocation: location }),
      workspaceId: workspace.id,
      mediaIds: assetMedia.length > 0 ? assetMedia : undefined,
      coverImageId: coverImageId,
    };
    mutate(
      { input: input },
      {
        onSettled: (data: any, error?: Error) => {
          if (error) return Promise.reject(error);

          const assetId = data?.asset?.addAsset?.id;
          props.onSaved(assetId);
        },
      }
    );
  };

  const cancelButton = (
    <LeftButton
      label={t('topNav.cancel')}
      hideCaret={true}
      onPress={() => props.onCancel()}
      style={[styles.baseButton, styles.cancelButton]}
    />
  );
  const addButton = (
    <SaveButton
      onPress={handleSubmit(onSubmit)}
      label={t('topNav.add')}
      disabled={!canSave}
      isLoading={isLoading}
      activeTextColor={Colors.white}
      style={[
        styles.baseButton,
        canSave ? styles.addButtonEnabled : styles.addButtonDisabled,
      ]}
    />
  );

  return (
    <>
      <TopComponentBar
        title={t('asset.newTitle')}
        left={cancelButton}
        right={addButton}
      />
      <KeyboardSafeView style={[props.styles.container]}>
        <ScrollView
          showsHorizontalScrollIndicator={false}
          scrollEnabled
          showsVerticalScrollIndicator={false}
          style={props.styles.contentContainer}
        >
          <View>
            <View style={styles.baseContainer}>
              <View>
                <Text variant={'titleMedium'} style={styles.formItemTitle}>
                  1. {t('asset.assetPicture')}:
                </Text>
              </View>
              <View style={styles.coverImage}>
                <SingleImageInput
                  onImageAdded={setCoverImage}
                  onUploadingChanged={setUploading}
                  imagePicker={MediaPicker}
                  allowsEditing={true}
                  displayWidth={100}
                />
              </View>
            </View>
            <View style={[styles.baseContainer, styles.formItem]}>
              <View>
                <Text variant={'titleMedium'} style={styles.formItemTitle}>
                  2. {t('asset.assetName')}:
                </Text>
              </View>
              <ControlledTextInput
                control={control}
                placeholder={t('asset.namePlaceholder')}
                name="name"
                outlinedTextInput
              />
            </View>

            <DividerText
              text={t('asset.optionalSetting')}
              styleContainer={styles.dividerContainer}
            />

            <View style={styles.baseContainer}>
              <View>
                <Text variant={'titleMedium'} style={styles.formItemTitle}>
                  {t('asset.newAdditionalMedia')}
                </Text>
              </View>
              <View>
                <HorizontalMediaGallery
                  onMediaAdded={addMedia}
                  onMediaDeleted={deleteMedia}
                  mediaPicker={MediaPicker as MediaPickerComponent}
                  onUploadingChanged={setUploading}
                  canEditMedia={true}
                />
              </View>
            </View>
            <View style={[styles.baseContainer, styles.formItem]}>
              <View>
                <Text variant={'titleMedium'} style={styles.formItemTitle}>
                  {t('asset.secondaryDescription')}
                </Text>
              </View>
              <ControlledTextInput
                control={control}
                placeholder={t('asset.descriptionPlaceholder')}
                name="description"
                outlinedTextInput
              />
            </View>
            <View style={[styles.baseContainer, styles.formItem]}>
              <View>
                <Text variant={'titleMedium'} style={styles.formItemTitle}>
                  {t('asset.details')}
                </Text>
              </View>
              <ControlledTextInput
                control={control}
                placeholder={t('asset.detailsPlaceHolder')}
                name="details"
                textArea={true}
                outlinedTextInput
              />
            </View>
            <View
              style={[
                styles.baseContainer,
                styles.formItem,
                styles.lastFormItem,
              ]}
            >
              <View>
                <Text variant={'titleMedium'} style={styles.formItemTitle}>
                  {t('asset.groups')}
                </Text>
              </View>
              <ControlledMultiSelectDropdown
                control={control}
                placeholder={t('asset.groupsPlaceHolder')}
                searchPlaceholder={t('asset.groupsSearchPlaceHolder')}
                name="groupIds"
                options={groups.map((group) => {
                  return {
                    value: group.id,
                    label: group.name,
                  };
                })}
              />
            </View>
            {tagCode && (
              <View style={styles.formItem}>
                <Accordion
                  title={t('asset.advancedAssetInformation')}
                  items={[
                    <View>
                      <View>
                        <Text
                          variant={'titleMedium'}
                          style={styles.formItemTitle}
                        >
                          {t('asset.tagCode')}
                        </Text>
                      </View>
                      <ControlledTextInput
                        control={control}
                        name="tagCode"
                        editable={false}
                      />
                    </View>,
                  ]}
                />
              </View>
            )}
          </View>
        </ScrollView>
      </KeyboardSafeView>
    </>
  );
};

const createAsset = `
  mutation Asset($input: AddAssetInput!) {
    asset {
      addAsset(input: $input) {
        id
      }
    }
  }
`;

const styles = StyleSheet.create({
  baseContainer: {
    backgroundColor: Colors.white,
    borderRadius: 8,
    paddingVertical: Spacing.basePadding.paddingVertical,
    paddingHorizontal: Spacing.basePadding.base,
    flexDirection: 'column',
    width: '100%',
  },
  coverImage: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  baseButton: {
    width: 64,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 10,
    marginHorizontal: Spacing.baseMargin.base,
  },
  cancelButton: {
    marginLeft: 8,
    marginRight: Spacing.baseMargin.marginHorizontal * 1.3,
  },
  addButtonDisabled: {
    borderColor: Colors.grayDisabled,
    borderWidth: 1,
  },
  addButtonEnabled: {
    backgroundColor: Colors.primary,
  },
  container: {
    ...Layout.container,
    backgroundColor: Colors.primaryGrayBackground,
  },
  contentContainer: {
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    paddingVertical: Spacing.basePadding.paddingVertical,
  },
  formItem: {
    width: '100%',
    marginTop: Spacing.baseMargin.marginVertical,
  },
  lastFormItem: {
    marginBottom: 20,
  },
  formItemTitle: {
    fontSize: 16,
    lineHeight: 16,
    color: Colors.black,
    fontWeight: '700',
    marginBottom: Spacing.baseMargin.marginVertical,
  },
  dividerContainer: {
    paddingHorizontal: 0,
    marginTop: 35,
    marginBottom: 35,
    maxWidth: '100%',
  },
});
