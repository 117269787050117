import { useQuery } from '@tanstack/react-query';
import { EnvConfig } from '../../envConfig';
import { useStorageKeys } from '../../storage';
import { AUTHENTICATION_QUERY_KEY } from '../constants';
import { Authentication, JwtAuthentication } from '../domain';
import { AuthenticationFunction } from './authenticationFunction';
import { useGrippContext } from '../../grippContext';

export const useAuthentication: AuthenticationFunction = (): Authentication => {
  const { secureStorageHandler, logger } = useGrippContext();
  const storageKeys = useStorageKeys();

  if (!secureStorageHandler || !logger)
    return { isLoading: true, isAuthenticated: false };

  const { data } = useQuery<Authentication>({
    queryKey: AUTHENTICATION_QUERY_KEY,
    initialData: { isLoading: true, isAuthenticated: false },
    queryFn: async () => {
      try {
        //check for local dev access token
        if (EnvConfig.auth?.accessToken) {
          const baseAuth = new JwtAuthentication(EnvConfig.auth.accessToken);

          if (baseAuth.isExpired) {
            throw new Error('Local Access Token is expired, please update');
          }

          return {
            isLoading: false,
            ...baseAuth,
          };
        } else {
          // check storage
          const accessToken = await Promise.race([
            secureStorageHandler.getItem(storageKeys.accessToken),
            new Promise((_, reject) =>
              setTimeout(
                () =>
                  reject(
                    new Error(
                      'Timeout: failed to get access token from secure storage'
                    )
                  ),
                5000
              )
            ),
          ]).catch(async (error) => {
            logger?.error(
              error,
              'Failed to get access token from secure storage'
            );
            await secureStorageHandler
              .deleteItem(storageKeys.accessToken)
              .then(() => logger?.info('Deleted unreadable access token'));
            return null;
          });

          if (!accessToken) {
            return { isLoading: false, isAuthenticated: false };
          }

          const baseAuth = new JwtAuthentication(accessToken as string);

          if (baseAuth.isExpired) {
            await secureStorageHandler.deleteItem(storageKeys.accessToken);
          }

          return {
            isLoading: false,
            ...baseAuth,
          };
        }
      } catch (error) {
        logger?.error(error, 'Failed to get authentication');
        return { isLoading: false, isAuthenticated: false };
      }
    },
    gcTime: Infinity,
    refetchOnReconnect: false,
  });

  return data;
};
