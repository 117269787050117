{
  "accountPage": {
    "title": "Cuenta",
    "header": {
      "accountTypes": "Topes de cuenta",
      "newUsersSetup": "Las nuevas usuarias se agregarán como"
    },
    "types": {
      "general": "General",
      "admin": "Admin",
      "restricted": "Restringida",
      "anonymous": "Anónima"
    },
    "table": {
      "headers": {
        "accountType": "Tipo de cuenta",
        "accessActivity": "¿Acceso a la actividad?",
        "accessSettings": "¿Acceso a Configuración?"
      },
      "columns": {
        "restrictedToActivitiesNote": "Limitada a activos y grupos compartidos",
        "generalToSettingsNote": "Limitada a activos y personas"
      }
    },
    "info": {
      "header": "Tipos de cuenta explicados",
      "admin": "Ideal para propietarios de lugares de trabajo, altos directivos y administradores de oficina.",
      "general": "Ideal para personal y empleados de tiempo completo. (Nuestro valor predeterminado recomendado.)",
      "restricted": "Ideal para visitantes conocidos, proveedores, trabajadores a tiempo parcial y contratistas que requieren acceso parcial o limitado.",
      "anonymous": "Para personas que no se han registrado con Gripp. Solo pueden enviar informes, notas o rutinas en función de lo que escanean."
    },
    "menu": {
      "accountPage": "Cuenta"
    }
  },
  "account": {
    "allWorkspaces": "Todos los espacios de trabajo",
    "create": {
      "button": "Agregar usuario",
      "form": {
        "email": "Correo electrónico",
        "firstName": "Nombre de pila",
        "lastName": "Apellido",
        "role": "Role",
        "workspace": "Espacio de trabajo"
      },
      "validation": {
        "emailRequired": "correo electronico es requerido",
        "role": "Se requiere rol",
        "validEmail": "Por favor introduzca una dirección de correo electrónico válida"
      }
    },
    "filter": {
      "apply": "Aplicar",
      "columnSelection": "Columnas (máximo {{count}})",
      "columns": {
        "createdAt": "Agregado",
        "email": "Correo electrónico",
        "groups": "Grupos",
        "jobTitle": "Título profesional",
        "name": "Nombre (requerido)",
        "phone": "Número de teléfono",
        "roles": "Tipo de permiso",
        "workspace": "Espacio de trabajo"
      },
      "searchPlaceholder": "Buscar nombre, número o correo electrónico",
      "columnButton": "columnas",
      "groupsButton": "Grupos",
      "accountTypesButton": "Tipos de cuenta",
      "noAccountTypes": "No hay tipos de cuenta"
    },
    "groups": {
      "addPeopleTo": "Añadir {{count}} personas a:",
      "addPersonTo": "Añadir 1 persona a:"
    },
    "list": {
      "accountType": "Tipo de permiso",
      "added": "Agregado",
      "email": "Correo electrónico",
      "groups": "Grupos",
      "jobTitle": "Título profesional",
      "name": "Nombre",
      "phone": "Número de teléfono",
      "workspace": "Espacio de trabajo"
    },
    "topBar": {
      "peopleSelected": "{{count}} personas seleccionados",
      "personSelected": "1 persona seleccionada",
      "results": "{{count}} resultados"
    },
    "menu": {
      "confirmDelete": {
        "primaryButton": "Sí",
        "secondaryButton": "Cancelar",
        "title": "¿Estás seguro de que quieres eliminar {{accountName}}?"
      }
    }
  },
  "activity": {
    "filter": {
      "apply": "Aplicar",
      "assetName": "Nombre del activo",
      "cancel": "Cancelar",
      "enterKeywords": "Ingrese palabras claves...",
      "filter": "Filtrar",
      "groups": "Grupos",
      "activityTypes": "Tipos de Actividades",
      "notes": "Notas",
      "pending": "Pendiente",
      "recentlyReported": "Reciente Reportado",
      "recentlyUpdated": "Reciente Actualizado",
      "reset": "Reiniciar",
      "routines": "Rutinas",
      "searchPlaceholder": "Buscar títulos de actividades",
      "solved": "Resuelto",
      "sortBy": "Ordenar Por",
      "title": "Filtros",
      "labels": "Etiquetas",
      "urgent": "Urgente",
      "customDate": "Fecha personalizada",
      "date": "Fecha",
      "all": "Todo",
      "today": "Hoy",
      "thisMonth": "Este mes",
      "thisWeek": "Esta semana",
      "to": "A",
      "from": "De"
    },
    "header": {
      "title": "Actividad",
      "view": "Ver",
      "viewAll": "Ver todo"
    },
    "menu": {
      "title": "Actividad"
    },
    "textEmpty": "Sin actividad",
    "topBar": {
      "results": "resultados"
    }
  },
  "activityFilterButton": {},
  "admin": {
    "menu": {
      "admin": "Gente",
      "workspaces": "Espacios de trabajo"
    }
  },
  "alerts": {
    "create": {
      "alertName": "Nombre de la alerta",
      "asset(s)": "Activo(s)",
      "assetSearchPlaceholder": "Buscar un nombre de activo",
      "assets": "Activos",
      "doNotAlert": "<0>(Opcional)</0><1>No alertar entre:</1>",
      "groupSearchPlaceholder": "Buscar un nombre de grupo",
      "groups": "Grupos",
      "promptRoutine": "<0>(Opcional)</0><1>Seleccione una rutina</1><0>para avisar con esta alerta:</0>",
      "promptRoutinePlaceholder": "ninguna rutina seleccionada",
      "title": "Crear Alerta",
      "what": "1. Escoge lo que:",
      "when": "3. Escoge cuándo:",
      "why": "2. Escoge por qué:"
    },
    "createButton": "Crear Nueva",
    "edit": {
      "alertName": "Nombre de la alerta",
      "asset(s)": "Activo(s)",
      "assets": "Activos",
      "doNotAlert": "No alertar entre",
      "groups": "Grupos",
      "none": "Ninguno",
      "promptRoutine": "<0>Seleccione una rutina</0><1>para avisar con esta alerta</1>",
      "promptRoutinePlaceholder": "ninguna rutina seleccionada",
      "setFor": "Esta alerta está configurada para",
      "setOn": "Configurar la alerta en",
      "title": "Editar Alerta",
      "when": "Cuando"
    },
    "menu": {
      "confirmDelete": {
        "primaryButton": "Sí",
        "secondaryButton": "Cancelar",
        "title": "¿Está seguro de que desea eliminar {{alertName}}?"
      },
      "delete": "Borrar",
      "disableAlert": "Desactivar alerta",
      "enableAlert": "Habilitar alerta",
      "viewAlertActivities": "Ver actividades de alerta"
    },
    "new": {
      "header": "Alertas > Crear Alerta"
    },
    "title": "Automatización",
    "trigger": {
      "counter": {
        "ceiling": "El contador va arriba",
        "daysSinceCompleted": "La rutina no se ha completado durante",
        "daysSinceCompleted-end": "Días",
        "every": "Cada",
        "every-end": "Días",
        "floor": "El contador va abajo",
        "onCompletionCount": "La rutina se ha completado cada",
        "onCompletionCount-end": "Veces",
        "onInterval": "El contador alcanza el intervalo de"
      },
      "default": {
        "daysSinceCompleted": "La rutina no se ha completado durante",
        "daysSinceCompleted-end": "Días",
        "every": "Cada",
        "every-end": "Días",
        "onCompletionCount": "La rutina se ha completado cada",
        "onCompletionCount-end": "Veces"
      }
    }
  },
  "asset": {
    "additionalMedia": "Medios adicionales",
    "advancedAssetInformation": "Información avanzada del activo",
    "customAlert": {
      "tagAlreadyAdded": "Esta etiqueta ya está agregada a este activo.",
      "tryAnotherTag": "Esta etiqueta ya está en uso y no se puede seleccionar. Pruebe con otra etiqueta."
    },
    "delete": {
      "button": "Eliminar activo",
      "cancel": "Cancelar",
      "confirm": "Borrar",
      "title": "¿Está seguro de que desea eliminar este recurso?"
    },
    "description": "Descripción",
    "descriptionPlaceholder": "Opcional",
    "details": "detalles",
    "detailsPlaceHolder": "Opcional",
    "editTitle": "Información del activo",
    "groups": "Grupos",
    "groupsPlaceHolder": "(Opcional) Seleccionar grupo(s)",
    "groupsSearchPlaceHolder": "Buscar un nombre de grupo",
    "header": {
      "activity": "Actividad",
      "buttons": {
        "addNew": "Agregar Informe / Nota",
        "newNote": "Nota",
        "reportIssue": "Informe",
        "routines": "Rutinas"
      },
      "description": "Descripción",
      "lastChecked": "Última comprobación",
      "lastUpdated": "Última actualización",
      "recentActivity": "Actividad reciente"
    },
    "make": "Hacer",
    "mediaLibrary": "Biblioteca de medios",
    "model": "Modelo",
    "name": "Nombre",
    "namePlaceholder": "Qué es esto?",
    "newIssue": "¿Algo nuevo?",
    "newTitle": "Configuración de activos",
    "tagCode": "Gripp ID",
    "assetPicture": "Tome una foto del activo",
    "assetName": "Dale un nombre a esto",
    "newAdditionalMedia": "¿Alguna imagen adicional para cargar? \n(Placas de datos, calcomanías OEM, etc.)",
    "secondaryDescription": "¿Alguna descripción secundaria?",
    "additionalDetails": "¿Algún detalle adicional para agregar? \n(Vins, números de serie, etc.)",
    "optionalSetting": "Configuración opcional"
  },
  "assets": {
    "filter": {
      "apply": "Aplicar",
      "columnButton": "columnas",
      "columnSelection": "Columnas",
      "columns": {
        "createdAt": "Creado en",
        "description": "Descripción",
        "groups": "Grupos",
        "lastAddress": "Ubicación",
        "lastScannedAt": "Último escaneado",
        "lastUpdated": "Última actualización",
        "name": "Nombre (requerido)",
        "notes": "# de Notas",
        "reports": "# de Informes Activos",
        "routines": "# de Rutinas",
        "tagCode": "Gripp ID",
        "workspaces": "Espacio de trabajo"
      },
      "enterKeywords": "Inserte palabra clave...",
      "groups": "Grupos",
      "groupsButton": "Grupos",
      "keywords": "Palabra clave",
      "reset": "Reiniciar",
      "searchPlaceholder": "Buscar nombre, descripción o Gripp ID",
      "title": "Filtros",
      "filterToggle": "Filtración combinada"
    },
    "groups": {
      "addAssetTo": "Añadir 1 activo a:",
      "addAssetsTo": "Añadir {{count}} activos a:"
    },
    "list": {
      "createdAt": "Creado en",
      "description": "Descripción",
      "groups": "Grupos",
      "lastAddress": "Ubicación",
      "lastScannedAt": "Último escaneado",
      "lastUpdated": "Última actualización",
      "name": "Nombre",
      "notes": "Notas",
      "reports": "Informes activos",
      "routines": "Rutinas",
      "tagCode": "Gripp ID",
      "workspaces": "Espacio de trabajo"
    },
    "menu": {
      "title": "Activos"
    },
    "textEmpty": "Sin Activos",
    "topBar": {
      "allAssets": "Todos los activos",
      "assetSelected": "1 activo seleccionado",
      "assetsSelected": "{{count}} activos seleccionados",
      "results": "{{count}} resultados"
    }
  },
  "avatar": {
    "menu": {
      "profile": "Perfil",
      "signout": "Desconectar"
    }
  },
  "binaryCheck": {
    "button": "Actualizar",
    "instructions1": "¡Hay una nueva actualización disponible!",
    "instructions2": "Gripp puede no funcionar hasta entonces."
  },
  "camera": {
    "Cancel": "Cancelar",
    "grippInviteDetected": "¡Se ha detectado una invitación de Gripp!",
    "grippTagDetected": "¡Etiqueta Gripp detectada!",
    "noCameraAccess": "Sin acceso a la cámara",
    "notGrippInvite": "No es una invitación de Gripp",
    "notGrippTagg": "No es una etiqueta Gripp",
    "tryAgain": "Inténtalo de nuevo"
  },
  "changesSaved": "Cambios Guardados",
  "customAlert": {
    "ok": "DE ACUERDO"
  },
  "deleteAccount": {
    "button": "Borrar cuenta",
    "confirm": "Confirmar Eliminación",
    "header": "Borrar Cuenta",
    "reasonInput": "Háganos saber cualquier detalle adicional para eliminar su cuenta (opcional):",
    "submitted": "Se ha enviado su solicitud de eliminación de cuenta."
  },
  "dropdown": {
    "dropdownFooter": {
      "apply": "Aplicar",
      "reset": "Reiniciar"
    }
  },
  "edit": "Editar",
  "email": "Correo electrónico",
  "features": {
    "title": "Características"
  },
  "firstName": "Nombre de pila",
  "forbidden": "Prohibido",
  "groups": {
    "groupsSelector": {
      "addToGroup": "Agregar al grupo",
      "addtoNewGroup": "Agregar a un nuevo grupo",
      "apply": "Aplicar",
      "clearGroups": "Borrar grupos"
    },
    "noGroups": "Sin grupos",
    "menu": {
      "title": "Grupos",
      "editGroup": "Editar Grupo"
    },
    "create": {
      "label": "Crear un Nuevo Grupo",
      "validation": {
        "name_required": "El nombre del grupo es obligatorio"
      },
      "cancel": "Cancelar",
      "create": "Crear",
      "placeholder": "Ej. Grupo de Tractores"
    },
    "list": {
      "name": "Nombre del Grupo",
      "people": "Personas",
      "assets": "Activos",
      "reports": "Informes Activos"
    },
    "common": {
      "textEmpty": "No hay grupos"
    },
    "filter": {
      "searchPlaceholder": "Buscar nombre de grupo, activos o personas",
      "columnButton": "Columnas",
      "columns": {
        "name": "Nombre del grupo",
        "reports": "Informes activos",
        "assets": "Activos",
        "people": "Personas"
      },
      "columnSelection": "Selección de columnas"
    },
    "assignments": {
      "name": "Nombre",
      "jobTitle": "Título profesional",
      "accountType": "Tipo de cuenta",
      "assetSearchPlaceholder": "Buscar nombre de equipo",
      "accountSearchPlaceholder": "Buscar nombre de persona o titulo",
      "emptyAccountList": "No hay cuentas",
      "assets": "Activos",
      "people": "Personas"
    },
    "deleteGroup": {
      "deleteGroup": "Borrar Grupo",
      "alertBody1": "Estas seguro que quieres borrar",
      "alertBody2": "Nota: ",
      "alertBody3": "Esto no eliminará ningún activo asociado con este ",
      "alertBody4": "grupo.",
      "delete": "Borrar",
      "cancel": "Cancelar"
    }
  },
  "invitation": {
    "initial": {
      "heading": "¡Estás invitado a {{workspaceName}}!",
      "login": "Clave de inicio de sesión",
      "step1": "Paso 1",
      "step1Instructions": "Descargar la aplicación Gripp",
      "step2": "Paso 2",
      "step2Instructions": "Utilice esta clave para iniciar sesión"
    },
    "invitePeople": {
      "copiedInvitationLink": "Enlace de invitación copiado",
      "copyInviteLink": "Copiar enlace de invitación",
      "errorCopyingInvitationLink": "Algo salió mal al copiar la URL de la invitación.",
      "invitePeopleForm": "Invitar personas a través de correos electrónicos",
      "invitePeopleQR": "Invitar personas a través del código QR",
      "linkAllowsToJoinWorkplace": "Este enlace permite que cualquiera solicite unirse a su lugar de trabajo.",
      "placeholder": "Correos electrónicos",
      "submit": "Entregar",
      "to": "Para"
    },
    "pending": {
      "accepted": "Siga el enlace enviado a su correo electrónico para iniciar sesión en su cuenta Gripp.",
      "cancel": "Cancelar",
      "join": "Unirse",
      "joining": "Te estás uniendo a {{workspaceName}}",
      "title": "Crear perfil"
    },
    "qr": {
      "invitesYou": "te invita a unirte"
    }
  },
  "issue": {
    "anonymous": {
      "addNote": "Añadir la nota",
      "addPicture": "Añade una foto",
      "cancel": "Cancelar",
      "createReport": "Crear reporte",
      "issue": "Asunto",
      "noteAdded": "¡Nota agregada!",
      "noteAddedDownloadGrippApp": "¡Descarga la app de Gripp para tomar notas aún más fácilmente!",
      "placeholder": "¿Qué estas viendo?",
      "report": "Informe",
      "reportAddedDownloadGrippApp": "¡Descarga la app de Gripp para hacer informes aún más rápido!",
      "reported": "¡Problema reportado!",
      "routineCompleted": "¡Boom, actualización confirmada!",
      "routineCompletedDownloadGrippApp": "¡Descarga la app de Gripp para disfrutar de la mejor experiencia.!",
      "submit": "Entregar"
    },
    "common": {
      "addPicture": "Fotos",
      "description": "Descripción",
      "followUp": "¿Requiere seguimiento?",
      "note": "No, solo lo apunto como referencia",
      "report": "Sí, se necesita seguimiento",
      "saveLabel": "Guardar"
    },
    "delete": {
      "confirm": "¿Confirmar eliminación?"
    },
    "editTitleModal": {
      "cancel": "Cancelar",
      "header": "Editar título",
      "save": "Guardar"
    },
    "latestMessage": {
      "media": "[Medios]"
    },
    "menu": {
      "delete": "Borrar",
      "editTitle": "Editar título",
      "print": "Imprimir",
      "reassign": "Reasignar"
    },
    "new": {
      "addIt": "Agregar Informe / Nota",
      "addPicture": "Añade una foto",
      "cancel": "Cancelar",
      "issue": "Asunto",
      "note": "Nota",
      "optionalPlaceholder": "Opcional",
      "placeholder": "¿Qué estas viendo?",
      "report": "Informe",
      "reported": "¡Problema reportado!",
      "submit": "Entregar",
      "title": "Título"
    },
    "reassign": {
      "instructions": "¿A cuál de los siguientes activos desea reasignar?",
      "placeholder": "Buscar un nombre de activo",
      "saveLabel": "Guardar",
      "title": "Reasignar"
    },
    "topBar": {
      "allReplies": "respuestas"
    }
  },
  "job": "Título profesional",
  "jobTitle": "Título profesional",
  "lastName": "Apellido",
  "loading": "Cargando",
  "login": {
    "codeSentTo": "Se envió un código de inicio de sesión a",
    "email": "Correo electrónico",
    "error": {
      "emailNotFound": "Correo electrónico no encontrado; comuníquese con el administrador de su organización",
      "invalidCredentials": "Credenciales no válidas",
      "processError": "Hubo un error al procesar su solicitud"
    },
    "login": "Iniciar sesión",
    "password": "Contraseña",
    "resendCode": "Reenviar codigo",
    "tabs": {
      "new": {
        "alreadyInvited": "si ya tienes una invitación.",
        "inBrowser": "en tu navegador",
        "logInKey": "Pulse la tecla de inicio de sesión",
        "scanQRInvitation": "Escanee la etiqueta Gripp o la invitación QR",
        "title": "Soy nuevo!"
      },
      "returning": {
        "title": "Estoy volviendo"
      }
    },
    "tryAnotherMethod": "Prueba otro método",
    "withEmail": "Continuar con el correo electrónico",
    "withMagicLink": "no tengo contraseña",
    "withPassword": "Tengo contraseña",
    "withPhone": "Continuar con el teléfono",
    "continue": "Continuar",
    "skip": "Saltar",
    "smsConfirmationMessage": "Te enviaremos un mensaje de texto para confirmar tu número. Puede tomar unos segundos.",
    "emailConfirmationMessage": "Te enviaremos un código de verificación para confirmar tu correo electrónico. Puede tomar unos segundos.",
    "aboutYourself": "¡Cuéntanos sobre ti!",
    "existingAccount": "¿Ya tienes una cuenta de Gripp?"
  },
  "logout": "Cerrar sesión",
  "mentions": {
    "unknownUser": "Usuario desconocida"
  },
  "modalMessages": {
    "completed": "Completada"
  },
  "or": "o",
  "onboarding": {
    "shareInfo": "¡Comparte más sobre ti con Gripp!",
    "industrial": "Campo industrial",
    "source": "¿Cómo escuchaste acerca de Gripp?"
  },
  "people": {
    "menu": {
      "people": "Personas"
    }
  },
  "phone": "Teléfono",
  "plusMenu": {
    "add-asset": "Agregar activo",
    "invite": "Invitar",
    "no-tag": "¿Sin etiqueta?",
    "note": "Nota",
    "report": "Informe",
    "scan": "Escanear"
  },
  "preferences": {
    "language": {
      "languages": {
        "english": "Inglés",
        "spanish": "Español"
      },
      "preferredLanguageTitle": "Idioma preferido"
    },
    "menu": {
      "title": "Ajustes"
    },
    "notifications": {
      "groupNotificationsTitle": "Notificaciones por grupo",
      "issueReplies": "Mensajes",
      "issueSeverityChanges": "Cambios de color",
      "issueTitleChanges": "Cambios de título",
      "notificationTitle": "Notificaciones",
      "schedule": {
        "accept": "Aceptar",
        "cancel": "Cancelar",
        "endTime": "Hora de finalización:",
        "everyDay": "Cada día",
        "notSet": "No establecido",
        "notificationSchedule": "Calendario de notificaciones",
        "set": "Colocar",
        "startTime": "Hora de inicio:",
        "weekDays": "Días de la semana"
      },
      "severities": {
        "blue": "Nuevas Rutinas",
        "purple": "Nuevas Notas",
        "red": "Nuevos Reportes",
        "yellow": "Nuevas Alertas"
      }
    }
  },
  "profile": {
    "edit": {
      "menu": {
        "title": "Editar Perfil"
      }
    }
  },
  "recent_activities": "Actividades recientes",
  "requestRoutine": {
    "apply": "Aplicar",
    "cancel": "Cancelar",
    "header": "Rutina",
    "instruction": "Por favor envíe su idea de rutina:",
    "placeHolder": "¿Qué deberíamos empezar a hacer?"
  },
  "routines": {
    "admin": {
      "create": "Guardar",
      "instructions": "Instrucciones",
      "issuePrefix": "Prefijo de problema",
      "reportOnException": "¿Informe sobre excepción?",
      "routineButtonText": "Botón de rutina",
      "title": "Título",
      "type": "Tipo",
      "update": "Actualizar"
    },
    "assign": "Asignar rutinas",
    "cancel": "Cancelar",
    "confirm": "Confirmar",
    "copyToWorkspace": {
      "save": "Copiar",
      "title": "Copiar al espacio de trabajo",
      "workspace": "Espacio de trabajo"
    },
    "counter": {
      "admin": {
        "decimalPlaces": "Habilitar decimales (opcional)",
        "tallyCounter": "Contador de Cuentas",
        "uom": "Unidad de medida"
      },
      "user": {
        "genericCounter": {
          "change": "Cambiar:",
          "initialValue": "Valor inicial:",
          "newValue": "Nuevo valor:"
        },
        "tallyCounter": {
          "add": "+ Agregar",
          "adding": "Añadiendo:",
          "instructions": "¿Cuántas unidades quieres agregar o quitar?",
          "newValue": "Nuevo valor:",
          "oldValue": "Valor Anterior:",
          "placeholder": "Introducir número...",
          "remove": "- Eliminar",
          "removing": "Eliminando:"
        }
      }
    },
    "createNew": "Crear nueva rutina",
    "edit": {
      "header": "Rutinas > Editar rutina"
    },
    "editRoutines": {
      "editRoutinesButton": "Editar Rutinas",
      "label": "Etiqueta:",
      "routineInWorkplace": "Todas las rutinas disponibles en su lugar de trabajo",
      "title": "Editar"
    },
    "exceptions": {
      "addDetails": "Agregue detalles adicionales o imágenes del problema:",
      "exceptionsNoticed": "{{count}} excepción detectada",
      "exceptionsNoticed_plural": "{{count}} excepciones detectadas",
      "reportCreated": "Se creó un informe",
      "viewRelatedReport": "Ver informe relacionado",
      "viewRelatedRoutine": "Ver rutina relacionada"
    },
    "header": {
      "title": "Rutinas",
      "view": "Ver",
      "viewAll": "Ver todo"
    },
    "menu": {
      "copyToWorkspace": "Copiar al espacio de trabajo",
      "delete": {
        "cancel": "Cancelar",
        "confirm": "Sí",
        "item": "Borrar",
        "title": "¿Estás seguro de que deseas eliminar esta rutina?"
      },
      "editRoutine": "Editar rutina",
      "export": "Exportar datos",
      "exporting": "Exportador..."
    },
    "message": {
      "messageTitle": "{{name}} rutina completada:"
    },
    "modalCancel": {
      "primary": "Sí",
      "secondary": "Cancelar",
      "title": "¿Salir de la rutina sin someterse?"
    },
    "new": {
      "chooseTypeButton": "Siguiente paso: elegir opciones",
      "header": "Rutinas > Crear rutina"
    },
    "quickConfirm": {
      "admin": {
        "addOption": "Agregar opción",
        "options": "Opciones"
      }
    },
    "requestButton": "+ Solicitar Rutina",
    "signature": {
      "clear": "Claro",
      "done": "Hecho",
      "sign": "Firmar",
      "signature": "Firma",
      "submit": "Entregar"
    },
    "status": {
      "admin": {
        "addStatus": "Agregar estado",
        "statuses": "Estados"
      }
    },
    "submit": "Entregar",
    "types": {
      "COUNTER": "Encimera",
      "QUICK_CONFIRM": "Confirmación rápida",
      "STATUS": "Estado",
      "WORKFLOW": "Mini formulario"
    },
    "update": "Actualizar",
    "workflow": {
      "admin": {
        "page": {
          "addQuestion": "Agregar pregunta",
          "instructions": "Instrucciones",
          "page": "Página {{página}}",
          "questionType": "tipo de pregunta"
        },
        "question": {
          "addOption": "Agregar opción",
          "exception": {
            "HasResponse": "Tiene respuesta",
            "MissingResponse": "Respuesta faltante",
            "Off": "Apagado",
            "label": "Manejo de excepciones:"
          },
          "instructions": "Instrucciones",
          "multiline": "Multilínea",
          "options": "Opciones",
          "question": "Pregunta {{pregunta}}"
        },
        "routine": {
          "label": "Rutina",
          "select": "Selecciona una rutina"
        }
      },
      "execution": {
        "optional": "Opcional",
        "submit": "Entregar"
      },
      "questionTypes": {
        "CHECKBOX": "Selección múltiple",
        "COUNTER": "Encimera",
        "MEDIA": "Medios de comunicación",
        "QUICK_CONFIRM": "Confirmación rápida",
        "RADIO": "Selección única",
        "ROUTINE": "Rutina",
        "SIGNATURE": "Firma",
        "STATUS": "Estado",
        "TEXT": "Texto"
      }
    }
  },
  "saveChanges": "Guardar Cambios",
  "signOut": "Desconectar",
  "support": {
    "genericDescription": "A veces pasan cosas, pero si vuelves a ver esta página, avísanos para que podamos arreglarlo.",
    "sendEmail": "O envíame un correo electrónico a",
    "title": "Vaya, nos hemos equivocado."
  },
  "tags": {
    "assetName": "Nombre del activo",
    "batches": {
      "title": "Etiquetar lotes"
    },
    "status": "Estado",
    "tagCode": "Número de etiqueta",
    "title": "Etiquetas",
    "updatedAt": "Actualizado En",
    "version": "Versión"
  },
  "textEmptyList": "Sin datos",
  "topNav": {
    "Add": "Agregar",
    "activities": "Actividad",
    "add": "Agregar",
    "assetInfo": "Ver activo",
    "assets": "Activos",
    "back": "Atrás",
    "backButton": {
      "cancel": "Continua editando",
      "confirmBack": "¡Hay cambios sin guardar!",
      "ok": "Salir sin guardar",
      "primary": "Guardar cambios",
      "secondary": "Continuar sin guardar",
      "title": "¿Salir sin guardar?"
    },
    "cancel": "Cancelar",
    "customAlert": {
      "cancel": "Cancelar",
      "deleteAccount": "¿Salir sin guardar?",
      "exit": "Exit without saving?",
      "signOut": "¿Estás seguro de que te gustaría salir?",
      "yes": "Sí"
    },
    "edit": "Editar",
    "editAsset": "Información del activo",
    "invite": "Invitar a la gente",
    "moreAssetInfo": "Más información",
    "save": "Guardar"
  },
  "translation": {
    "seeOriginal": "Ver original",
    "seeTranslation": "Ver traducción"
  },
  "versionCheck": {
    "description": "¡Actualizaciones disponibles! Es posible que algunas funciones no funcionen hasta que actualice el navegador.",
    "refreshButton": "Refrescar"
  },
  "workspace": {
    "create": {
      "addButton": "Entregar",
      "administratorEmail": "Correo electrónico del administrador",
      "administratorFirstName": "Nombre del administrador",
      "administratorLastName": "Apellido del administrador",
      "copyRoutines": "Copiar rutinas",
      "title": "Crear espacio de trabajo",
      "validation": {
        "admin_email_required": "Se requiere el correo electrónico del administrador",
        "admin_first_name_required": "Se requiere el nombre del administrador",
        "admin_last_name_required": "Se requiere el apellido del administrador",
        "name_required": "El nombre del espacio de trabajo es obligatorio.",
        "valid_emil": "El correo electrónico del administrador debe ser una dirección de correo electrónico válida."
      },
      "workspaceName": "Nombre del espacio de trabajo"
    },
    "createButton": "Crear espacio de trabajo",
    "list": {
      "administrator": "Administrador",
      "workspace": "Espacio de trabajo"
    }
  },
  "permissions": {
    "allSet": "¡Todo listo, vamos a comenzar con Gripp!",
    "backButton": "Atrás",
    "camera": {
      "button": "Habilitar cámara",
      "denied": {
        "description1": "¡No se pierda ningún detalle, habilite el acceso de la cámara para capturar e informe sin esfuerzo!",
        "title": "Gire el acceso a la cámara, para capturar, mensaje"
      },
      "initial": {
        "description1": "Tomar notas y problemas de informes a menudo requieren que tome una foto de lo que ve, por lo que es importante habilitar el acceso de la cámara.",
        "title": "Acceso a su cámara, para capturar, mensaje"
      }
    },
    "continueButton": "Continuar",
    "enableInSettings": "Debe habilitar este permiso en el menú del sistema para continuar.",
    "gallery": {
      "button": "Habilitar la galería",
      "denied": {
        "description1": "¡No te pierdas nada! \nHabilite el acceso de la galería para cargar solo las imágenes que elija.",
        "title": "Encienda el acceso a la galería de fotos, para subir imágenes."
      },
      "initial": {
        "description1": "Similar a la cámara, a veces ya tienes imágenes que quieres subir en Gripp.",
        "description2": "Gripp solo tendrá acceso a las imágenes que elija en el punto de cargar.",
        "title": "Acceso a su galería de fotos para subir fotos."
      }
    },
    "location": {
      "button": "Habilitar ubicación",
      "denied": {
        "description1": "No se pierda ninguna ubicación de activos, realice un seguimiento de ellos fácilmente.",
        "title": "Encienda la ubicación, para rastrear sus activos."
      },
      "initial": {
        "description1": "Gripp usa la ubicación de su teléfono para actualizar la ubicación de sus activos cuando los escanea.",
        "description2": "Como mínimo, le pedimos que permita la ubicación mientras usa la aplicación.",
        "title": "Acceso a su ubicación, para rastrear sus activos."
      }
    },
    "notification": {
      "button": "Habilitar notificaciones",
      "denied": {
        "description1": "¡Mantente informado! \nObtenga alertas de equipo, problemas e informes, sin mensajes de marketing.",
        "title": "Encienda las notificaciones, para mantenerse actualizado."
      },
      "initial": {
        "description1": "Al igual que muchas aplicaciones, se notificará cuando llegue una nueva alerta, problema o informe. \nNo querrías perderte nada importante.",
        "description2": "Estos son solo para notificaciones de equipo y no están relacionadas con las notificaciones de marketing.",
        "title": "Habilitar notificaciones, para evitar perder una actualización."
      }
    },
    "openSetting": "Abrir configuración",
    "welcome": {
      "description1": "Para usar Gripp, necesitamos habilitar varios permisos.",
      "description2": "Te guiaremos a través de cada uno para explicar por qué lo necesitamos.",
      "description3": "Puedes ajustar todas las configuraciones en cualquier momento a través de los ajustes de tu teléfono.",
      "title": "¡Bienvenido a Gripp!"
    }
  }
}