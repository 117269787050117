import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { Spacing } from '../../styles';
import { Colors } from '../../themes';
import { CustomAlert } from '../customAlert';
import { SaveButton } from '../navigation';
import { SaveFormTopProps } from './formTypes';

export const SaveFormTop = (props: SaveFormTopProps) => {
  const TopComponentBar = props.topBar;
  const { t } = useTranslation();
  const canSave = useMemo(() => {
    const isDirty = props.isDirty === undefined ? true : props.isDirty;
    const isValid = props.isValid === undefined ? true : props.isValid;
    return isDirty && isValid;
  }, [props.isDirty, props.isValid]);
  const [alertVisible, setAlertVisible] = useState(false);
  const showAlert = () => setAlertVisible(true);
  const hideAlert = () => setAlertVisible(false);
  const rightButton = (
    <SaveButton
      onPress={props.onSubmit}
      disabled={!canSave}
      isLoading={false}
      activeTextColor={canSave ? Colors.white : undefined}
      label={props.saveLabel ?? t('topNav.save')}
      style={[
        styles.baseButton,
        canSave ? styles.addButtonEnabled : styles.addButtonDisabled,
      ]}
    />
  );

  return (
    <>
      <TopComponentBar
        title={props.title}
        showSave={true}
        onRightPress={props.onSubmit}
        rightPressDisabled={!canSave}
        confirmBack={props.isDirty && !alertVisible}
        showAlert={showAlert}
        additionalHeaderBlock={props.additionalHeaderBlock}
        saveLabel={props.saveLabel}
        right={rightButton}
        onCancel={props.onCancel}
      />
      <CustomAlert
        visible={alertVisible}
        hideAlert={hideAlert}
        action={props.onCancel}
        title={t('topNav.customAlert.exit')}
        primaryButtonLabel={t('topNav.customAlert.yes')}
        secondaryButtonLabel={t('topNav.customAlert.cancel')}
      />
      {props.children}
    </>
  );
};

const styles = StyleSheet.create({
  baseButton: {
    width: 86,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 10,
    marginRight: Spacing.baseMargin.base,
  },
  addButtonDisabled: {
    borderColor: Colors.grayDivider,
    borderWidth: 2,
  },
  addButtonEnabled: {
    backgroundColor: Colors.primary,
  },
});
