import { StyleSheet } from 'react-native';
import { Colors } from '../../../themes';
import { PopUpView } from '../../modals';
import { UserInfoModal } from './types';
import { UserReaction } from './userReaction';

type UserReactionsViewProps = {
  usersReactions: UserInfoModal;
  isOpened: boolean;
  onUsersInfoClose: () => void;
};

export const UserReactionsView: React.FC<UserReactionsViewProps> = (props) => {
  const { usersReactions, onUsersInfoClose, isOpened } = props;

  return (
    <PopUpView
      isVisible={isOpened}
      onDismiss={onUsersInfoClose}
      containerStyle={styles.container}
    >
      <UserReaction
        users={usersReactions.users}
        reactionType={usersReactions.reactionType}
      />
    </PopUpView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 225,
    backgroundColor: Colors.white,
    borderRadius: 12,
  },
});
