import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import {
  Colors,
  FilterType,
  GroupSearchInput,
  GroupsList,
} from '@gripp/shared-ui';
import { StyleProp, StyleSheet, Text, View } from 'react-native';
import { FilterBar } from '../../shared/components/filter/filterBar';
import { ModalButton } from '../../shared/components/modalButton';
import { useMaxHeight } from '../../shared/hooks/useMaxHeight';
import { EditGroupMenu } from './editGroupMenu';
import { AssignmentType, GroupAssignmentForm } from './groupAssignmentForm';

export const Groups = () => {
  const { containerMaxHeight, listMaxHeight } = useMaxHeight(64, 0, 228);

  const getLabel = (labelStyle: StyleProp<any>, label: string) => {
    return <Text style={[labelStyle, styles.cellText]}>{label}</Text>;
  };

  const AssetsButton = (data: any) => {
    return (
      <ModalButton
        label=""
        customButton={
          <View style={[styles.iconContainer]}>
            <MaterialCommunityIcons
              name="qrcode"
              color={Colors.blackText}
              size={16}
              style={styles.icon}
            />
            {getLabel(styles.countText, data.assets)}
          </View>
        }
        modalComponent={({ onComplete }) => {
          return (
            <GroupAssignmentForm
              groupId={data.id}
              title={data.name}
              onComplete={onComplete}
              assignmentType={AssignmentType.Assets}
            />
          );
        }}
        containerStyle={styles.modalContainer}
      />
    );
  };

  const PeopleButton = (data: any) => {
    return (
      <ModalButton
        label=""
        customButton={
          <View style={[styles.iconContainer]}>
            <MaterialCommunityIcons
              name="account-multiple"
              color={Colors.blackText}
              size={16}
              style={styles.icon}
            />
            {getLabel(styles.countText, data.people)}
          </View>
        }
        modalComponent={({ onComplete }) => {
          return (
            <GroupAssignmentForm
              groupId={data.id}
              title={data.name}
              onComplete={onComplete}
              assignmentType={AssignmentType.People}
            />
          );
        }}
        containerStyle={styles.modalContainer}
      />
    );
  };

  return (
    <>
      <View style={styles.filtersContainer}>
        <View style={styles.groupSearchContainer}>
          <GroupSearchInput />
        </View>
        <FilterBar filterButtonTypes={[FilterType.GROUPCOLUMNS]} />
      </View>
      <View
        style={[
          styles.groupsContainer,
          {
            maxHeight: containerMaxHeight,
          },
        ]}
      >
        <GroupsList
          maxHeight={listMaxHeight}
          assetButton={AssetsButton}
          peopleButton={PeopleButton}
          editGroupMenu={EditGroupMenu}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 44,
    marginBottom: 20,
  },
  groupSearchContainer: {
    width: 390,
    marginRight: 20,
  },
  groupsContainer: {
    flex: 1,
    borderRadius: 8,
    overflow: 'hidden',
    zIndex: -1,
  },
  modalContainer: {
    width: 640,
    height: '80%',
    minHeight: 450,
    alignSelf: 'center',
    backgroundColor: Colors.primaryGrayBackground,
    borderRadius: 10,
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    marginRight: 2,
  },
  countText: {
    fontSize: 12,
  },
  cellText: {
    fontSize: 14,
    fontWeight: '500',
    flexWrap: 'nowrap',
  },
});
