import { StyleSheet, TouchableOpacity } from 'react-native';
import { Colors } from '../../themes';
import FixedText from '../FixedText';

type Props = {
  label: string;
  onPress: () => void;
  disabled?: boolean;
};

export const StepPrimaryButton = ({
  label,
  onPress,
  disabled = false,
}: Props) => {
  return (
    <TouchableOpacity
      style={styles.content}
      onPress={onPress}
      disabled={disabled}
    >
      <FixedText
        style={styles.label}
        allowFontScaling={false}
        maxFontSizeMultiplier={1}
      >
        {label}
      </FixedText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  content: {
    height: 44,
    borderRadius: 12,
    backgroundColor: Colors.primary,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    fontWeight: '500',
    fontSize: 16,
    color: Colors.white,
  },
});
