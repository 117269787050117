import { useTranslation } from 'react-i18next';
import { StyleSheet, Text } from 'react-native';
import { Button } from 'react-native-paper';
import { Colors } from '../../themes';

type StepBackButtonProps = { onPress: () => void; disabled?: boolean };

export const StepBackButton = ({
  onPress,
  disabled = false,
}: StepBackButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      contentStyle={styles.content}
      labelStyle={styles.label}
      onPress={onPress}
      disabled={disabled}
    >
      <Text allowFontScaling={false} maxFontSizeMultiplier={1}>
        {t('permissions.backButton')}
      </Text>
    </Button>
  );
};

const styles = StyleSheet.create({
  content: {
    height: 44,
  },
  label: {
    fontWeight: '500',
    fontSize: 16,
    color: Colors.orangeDark,
  },
});
