import {
  cacheKeys,
  FIND_GROUP_ASSIGNMENTS,
  useCurrentWorkspace,
  useCustomMutation,
  useCustomQuery,
} from '@gripp/shared-logic';
import {
  Colors,
  LoadingIndicator,
  SaveFormTop,
  TopModalBar,
  useGetDimensions,
} from '@gripp/shared-ui';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import '../../shared/style/reactTabStyle.css';
import { EditGroupAccountsList } from './editGroupAccountsList';
import { EditGroupAssetsList } from './editGroupAssetsList';
import { GroupAssignmentSearchInput } from './groupAssignmentSearchInput';

export enum AssignmentType {
  Assets = 'assets',
  People = 'people',
}

type GroupAssignmentFormProps = {
  groupId: string;
  title: string;
  assignmentType: AssignmentType;
  onComplete: () => void;
};

export const GroupAssignmentForm = ({
  title,
  onComplete,
  groupId,
  assignmentType,
}: GroupAssignmentFormProps) => {
  const [originalAssetAssignments, setOriginalAssetAssignments] = useState<
    string[] | undefined
  >();

  const [originalAccountAssignments, setOriginalAccountAssignments] = useState<
    string[] | undefined
  >();

  const [assetAssignments, setAssetAssignments] = useState<
    string[] | undefined
  >(undefined);

  const [accountAssignments, setAccountAssignments] = useState<
    string[] | undefined
  >(undefined);

  const [tabIndex, setTabIndex] = useState(0);
  const [assignmentMode, setAssignmentMode] = useState<AssignmentType>(
    AssignmentType.Assets
  );
  const tabIndexAssignment = [AssignmentType.Assets, AssignmentType.People];
  const isAssets = assignmentMode === AssignmentType.Assets;
  const isAccounts = assignmentMode === AssignmentType.People;

  const { t } = useTranslation();

  const { data: assignments } = useCustomQuery({
    query: FIND_GROUP_ASSIGNMENTS,
    cacheKey: [],
    variables: {
      groupId: groupId,
    },
  });

  const { mutateAsync: assignAssetsToGroup, isPending: isAssetsLoading } =
    useCustomMutation({
      query: ASSIGN_ASSETS_TO_GROUP,
    });

  const { mutateAsync: assignAccountsToGroup, isPending: isAccountsLoading } =
    useCustomMutation({
      query: ASSIGN_ACCOUNTS_TO_GROUP,
    });

  useEffect(() => {
    setKeyword('');
  }, [assignmentType, assignmentMode, tabIndex]);

  useEffect(() => {
    if (assignments) {
      const assetAssignments = assignments.group.findGroupAssignments.assets;
      setOriginalAssetAssignments(assetAssignments);
      setAssetAssignments(assetAssignments);

      const accounts = assignments.group.findGroupAssignments.accounts;
      setOriginalAccountAssignments(accounts);
      setAccountAssignments(accounts);
    }
  }, [assignments]);

  useEffect(() => {
    let assignmentToIndex;

    switch (assignmentType) {
      case AssignmentType.Assets:
        assignmentToIndex = 0;
        break;
      case AssignmentType.People:
        assignmentToIndex = 1;
        break;
      default:
        assignmentToIndex = 0;
        break;
    }

    setTabIndex(assignmentToIndex);
    setAssignmentMode(assignmentType);
  }, [assignmentType]);

  const currentWorkspace = useCurrentWorkspace(true);
  const queryClient = useQueryClient();
  const { dimensions, onLayout } = useGetDimensions();
  const [keyword, setKeyword] = useState('');

  const height = dimensions?.height ?? 0;
  const tableResultsBlockHeight = 50;
  const listMaxHeight = height - tableResultsBlockHeight;

  const handleAssetsSubmit = async () => {
    const input = {
      assetIds: assetAssignments,
      groupId: groupId,
      workspaceId: currentWorkspace.id,
    };

    await assignAssetsToGroup(
      { input: input },
      {
        onSettled: async () => {
          await queryClient.invalidateQueries({
            queryKey: cacheKeys('group').all,
          });
        },
      }
    );

    onComplete();
  };

  const handleAccountsSubmit = async () => {
    const input = {
      accountIds: accountAssignments,
      groupId: groupId,
      workspaceId: currentWorkspace.id,
    };

    await assignAccountsToGroup(
      { input: input },
      {
        onSettled: async () => {
          await queryClient.invalidateQueries({
            queryKey: cacheKeys('group').all,
          });
        },
      }
    );

    onComplete();
  };

  const isDirty = () => {
    if (isAssets) {
      return (
        originalAssetAssignments &&
        assetAssignments &&
        !(
          originalAssetAssignments.length === assetAssignments.length &&
          originalAssetAssignments.every((item) =>
            assetAssignments.includes(item)
          ) &&
          assetAssignments.every((item) =>
            originalAssetAssignments.includes(item)
          )
        )
      );
    } else {
      return (
        originalAccountAssignments &&
        accountAssignments &&
        !(
          originalAccountAssignments.length === accountAssignments.length &&
          originalAccountAssignments.every((item) =>
            accountAssignments.includes(item)
          ) &&
          accountAssignments.every((item) =>
            originalAccountAssignments.includes(item)
          )
        )
      );
    }
  };

  return (
    <SaveFormTop
      onSubmit={isAssets ? handleAssetsSubmit : handleAccountsSubmit}
      title={title}
      topBar={TopModalBar}
      onCancel={onComplete}
      isDirty={isDirty()}
    >
      {(isAssets && isAssetsLoading) || (isAccounts && isAccountsLoading) ? (
        <LoadingIndicator flexContainer />
      ) : (
        <View style={styles.container}>
          <GroupAssignmentSearchInput
            keyword={keyword}
            onChange={setKeyword}
            placeholder={
              isAssets ? 'assetSearchPlaceholder' : 'accountSearchPlaceholder'
            }
          />

          <View style={[styles.assignmentContainer]} onLayout={onLayout}>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => {
                setTabIndex(index);
                setAssignmentMode(tabIndexAssignment[index]);
              }}
            >
              <TabList>
                <Tab>{t('groups.assignments.assets')}</Tab>
                <Tab>{t('groups.assignments.people')}</Tab>
              </TabList>

              <View style={styles.listContainer}>
                <TabPanel>
                  {assetAssignments && (
                    <EditGroupAssetsList
                      workspace={currentWorkspace}
                      groupId={groupId}
                      maxHeight={listMaxHeight}
                      keyword={keyword}
                      assignments={assetAssignments}
                      updateAssignments={setAssetAssignments}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {accountAssignments && (
                    <EditGroupAccountsList
                      workspace={currentWorkspace}
                      groupId={groupId}
                      maxHeight={listMaxHeight}
                      keyword={keyword}
                      assignments={accountAssignments}
                      updateAssignments={setAccountAssignments}
                    />
                  )}
                </TabPanel>
              </View>
            </Tabs>
          </View>
        </View>
      )}
    </SaveFormTop>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    paddingHorizontal: 20,
    paddingTop: 24,
    paddingBottom: 20,
  },
  assignmentContainer: {
    flex: 1,
    marginTop: 20,
  },
  listContainer: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
    overflow: 'hidden',
  },
  inputStyle: {
    backgroundColor: Colors.white,
    borderColor: Colors.grayDivider,
    borderWidth: 1,
    borderRadius: 8,
  },
});

const ASSIGN_ASSETS_TO_GROUP = `
  mutation Group($input: AssignToGroupInput!) {
    group {
      assignToGroup(input: $input) {
        message
        success
      }
    }
  }
  `;

const ASSIGN_ACCOUNTS_TO_GROUP = `
  mutation AssignToGroup($input: AssignToGroupInput!) {
    group {
      assignToGroup(input: $input) {
        message
        success
      }
    }
  }
  `;
