import { useEffect, useState } from 'react';
import { Image, StyleSheet } from 'react-native';
import { ProgressBar } from 'react-native-paper';
import { ImageThumbnailComponent, ImageThumbnailProps } from './types';

type Source = {
  uri?: string;
};

export const ImageThumbnail: ImageThumbnailComponent = (
  props: ImageThumbnailProps
) => {
  const [source, setSource] = useState<Source>({});
  const cardStyle = props.style ? props.style : styles.imageCard;

  useEffect(() => {
    if (props.imageUri) {
      setSource({ uri: props.imageUri });
    }
  }, [props.imageUri]);

  return (
    <>
      <Image style={cardStyle} source={source} />
      <ProgressBar
        animatedValue={props.progress}
        visible={props.uploading ?? false}
      />
    </>
  );
};

export const styles = StyleSheet.create({
  imageCard: {
    height: 100,
    width: 100,
  },
});
