import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import {
  ColorValue,
  InputModeOptions,
  KeyboardTypeOptions,
  Platform,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import { TextInput } from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { AutocompleteTextInput } from './formTypes';

type ControlledTextInputProps = {
  control: any;
  name: string;
  placeholder?: string;
  editable?: boolean;
  autoFocus?: boolean;
  textArea?: boolean;
  innerRef?: any;
  showClearValue?: boolean;
  outlinedTextInput?: boolean;
  keyboardType?: KeyboardTypeOptions;
  autoComplete?: AutocompleteTextInput;
  height?: number;
  disableInnerStyle?: boolean;
};

export const ControlledTextInput = (props: ControlledTextInputProps) => {
  const editable = props.editable === undefined ? true : props.editable;

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, onBlur, value } }) => (
        <StyledTextInput
          onChangeText={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={props.placeholder}
          editable={editable}
          autoFocus={props.autoFocus}
          textArea={props.textArea}
          innerRef={props.innerRef}
          right={iconClearValue({
            showClearValue: props.showClearValue,
            value,
            onChange,
          })}
          outlinedTextInput={props.outlinedTextInput}
          keyboardType={props.keyboardType}
          autoComplete={props.autoComplete}
          height={props.height}
          disableInnerStyle={props.disableInnerStyle}
        />
      )}
    />
  );
};

export type StyledTextInputProps = Omit<
  ControlledTextInputProps,
  'control' | 'name'
> & {
  onChangeText: (text: string) => void;
  onBlur: () => void;
  onFocus?: () => void;
  value?: any;
  left?: ReactNode;
  right?: ReactNode;
  outlineStyle?: StyleProp<ViewStyle>;
  disableInnerStyle?: boolean;
  height?: number;
  keyboardType?: KeyboardTypeOptions;
  autoComplete?: AutocompleteTextInput;
  width?: number;
  inputMode?: InputModeOptions | undefined;
  backgroundColor?: ColorValue;
  inputInnerStyle?: StyleProp<ViewStyle>;
  pointerEvents?: 'none' | 'auto' | 'box-none' | 'box-only';
};

export const StyledTextInput = (props: StyledTextInputProps) => {
  const editable = props.editable === undefined ? true : props.editable;

  return (
    <TextInput
      left={props.left}
      right={props.right}
      mode="outlined"
      style={[
        {
          height: props.height || 55,
          width: props.width || 'auto',
          backgroundColor: props?.backgroundColor ?? Colors.white,
        },
        props.outlinedTextInput && {
          borderWidth: 1,
          borderColor: Colors.tabInactive,
          borderRadius: 8,
        },
      ]}
      contentStyle={[
        props.disableInnerStyle
          ? undefined
          : props.textArea
          ? [inputStyles.baseInputContent, inputStyles.areaInputContent]
          : [inputStyles.baseInputContent],
        Platform.select({
          android: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }),
        props.inputInnerStyle,
      ]}
      value={props.value}
      multiline={props.textArea}
      inputMode={props.inputMode}
      outlineStyle={props.outlineStyle ?? inputStyles.textOutline}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      onChangeText={(value) => props.onChangeText(value)}
      autoFocus={props.autoFocus}
      placeholder={props.placeholder}
      editable={editable}
      activeOutlineColor={Colors.black}
      placeholderTextColor={Colors.grayDisabled}
      ref={props.innerRef}
      keyboardType={props.keyboardType}
      autoComplete={props.autoComplete}
      pointerEvents={props.pointerEvents}
    />
  );
};

const iconClearValue = ({
  showClearValue = false,
  value,
  onChange,
}: {
  showClearValue?: boolean;
  value: any;
  onChange: (...event: any[]) => void;
}) =>
  showClearValue && (
    <TextInput.Icon
      icon="close"
      color={value?.length ? Colors.primaryDark : Colors.grayText}
      onPress={() => onChange('')}
    />
  );

const inputStyles = StyleSheet.create({
  areaInputContent: {
    height: 100,
    paddingTop: 20,
  },
  baseInputContent: {
    paddingHorizontal: 20,
    backgroundColor: '#fff',
    borderRadius: 8,
    width: 'auto',
  },
  textOutline: {
    borderWidth: 0,
  },
});
