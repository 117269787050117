import {
  AuthCodeInput,
  FeatureName,
  useIsFeatureEnabled,
  useSendAuthCode,
} from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { InferType, object as yupObject } from 'yup';
import {
  ControlledPhoneInput,
  PhoneValidator,
} from '../../components/forms/controlledPhoneInput';
import { LoginProps } from './props';
import { styles } from './styles';
import { ViewError } from './viewError';

const formSchema = yupObject({
  phone: PhoneValidator,
});

type FormData = InferType<typeof formSchema>;

export default function SmsCodeLogin(props: LoginProps) {
  const { t } = useTranslation();
  const [error] = useState<string | undefined>(undefined);
  const { sendAuthCodeAsync, isPending: isLoading } = useSendAuthCode();
  const isNewRegistrationFlow = useIsFeatureEnabled(
    FeatureName.NewRegistrationFlow
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      phone: undefined,
    },
  });

  const onSubmit = async (data: FormData) => {
    const input = {
      phone: data.phone,
    } as AuthCodeInput;
    const handleCodeSent = props.onCodeSent ?? (() => null); // to fix type warning issue

    if (isNewRegistrationFlow) {
      await sendAuthCodeAsync(input, handleCodeSent, props.onRegister);
    } else {
      await sendAuthCodeAsync(input, handleCodeSent);
    }
  };

  return (
    <View
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View
        style={
          isNewRegistrationFlow
            ? styles.inputContainer
            : [{ maxWidth: 450 }, { paddingBottom: 28 }]
        }
      >
        <Text style={styles.inputLabel}>{t('phone')}</Text>
        <ControlledPhoneInput
          control={control}
          name="phone"
          placeholder={'phone number'}
          height={isNewRegistrationFlow ? 52 : 48}
        />
      </View>

      <View style={styles.containerButton}>
        <Button
          mode="contained"
          disabled={!isValid || isLoading}
          onPress={handleSubmit(onSubmit)}
          loading={isLoading}
          style={[
            styles.button,
            !isValid || isLoading
              ? styles.disabledButton
              : styles.enabledButton,
          ]}
        >
          {isNewRegistrationFlow ? (
            <Text style={styles.buttonText}>{t('login.continue')}</Text>
          ) : (
            <Text style={styles.buttonText}>{t('login.login')}</Text>
          )}
        </Button>
      </View>
      <ViewError error={error} />
    </View>
  );
}
