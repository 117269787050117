/* eslint-disable @typescript-eslint/no-explicit-any */
import { FIND_ASSIGNABLE_ACCOUNTS } from '@gripp/shared-logic';
import { InfiniteDataGrid } from '@gripp/shared-ui';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountColumns } from './useAccountColumns';

export type EditGroupAccountsListProps = {
  workspace: any;
  groupId: string;
  maxHeight?: number;
  keyword?: string;
  assignments: string[];
  updateAssignments: (assignments: string[]) => void;
};

export const EditGroupAccountsList = ({
  workspace,
  groupId,
  maxHeight,
  keyword,
  assignments,
  updateAssignments,
}: EditGroupAccountsListProps) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<any>(undefined);
  const columns = useAccountColumns() as ColumnDef<any, any>[];

  useEffect(() => {
    setFilter({
      keyword,
      groupId,
      assignments,
      workspaceId: workspace?.id,
    });
  }, [keyword, groupId, workspace]);

  return !filter ? null : (
    <InfiniteDataGrid
      modelName="account"
      hideResultsLabel={true}
      query={FIND_ASSIGNABLE_ACCOUNTS}
      filter={filter}
      pageSize={50}
      columns={columns}
      listMaxHeight={maxHeight as number}
      containerStyle={{ height: maxHeight }}
      paddingBottom={0}
      textEmpty={t('groups.assignments.emptyAccountList')}
      dataPrefix="group.findAssignableAccounts"
      updateRowsSelected={(rows) => {
        updateAssignments(rows);
      }}
      isRowSelected={(row) => {
        return assignments.includes(row.id);
      }}
      enableScrollRefresh={true}
      showScrollIndicator={true}
    />
  );
};
