import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, Text, TextProps, TextStyle } from 'react-native';

interface FixedTextProps extends TextProps {
  translationKey?: string;
  children?: React.ReactNode;
  maxScale?: number;
  style?: StyleProp<TextStyle>;
}

const FixedText: React.FC<FixedTextProps> = ({
  translationKey,
  children,
  maxScale = 1.5,
  style,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Text
      {...props}
      allowFontScaling={false}
      maxFontSizeMultiplier={maxScale}
      style={style}
    >
      {translationKey ? t(translationKey) : children}
    </Text>
  );
};

export default FixedText;
