export type LoginProps = {
  handleRedirect: (url: string) => Promise<void>;
  getRedirectUrl?: () => Promise<string>;
  onEasterEgg?: () => void;
  hideLogoHeader?: boolean;
  hideBackButton?: boolean;
  onBack?: () => void;
  onCodeSent?: () => void;
  onTryAnotherMethod?: () => void;
  onRegister?: () => void;
  isNewAccount?: boolean;
};
