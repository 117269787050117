import { useCreateMutation } from '@gripp/shared-logic';
import {
  ControlledTextInput,
  SaveFormTop,
  Spacing,
  TopModalBar,
  useAutoFocusRef,
} from '@gripp/shared-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import * as yup from 'yup';

const CREATE_GROUP = `
  mutation createGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
        id
    }
  }
`;

type Props = {
  onComplete: () => void;
  workspaceId: string;
};

export const CreateGroupForm = ({ workspaceId, onComplete }: Props) => {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useCreateMutation({
    modelName: 'group',
    query: CREATE_GROUP,
  });

  const formSchema = yup.object({
    name: yup.string().required(t('groups.create.validation.name_required')),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: { name: '' },
  });

  const onSubmit = async (data: yup.InferType<typeof formSchema>) => {
    const input = {
      name: data.name,
      workspace: { id: workspaceId },
    };
    await mutateAsync(
      { input },
      {
        onSettled: (data: any, error?: Error) => {
          if (error) return Promise.reject(error);
          onComplete();
        },
      }
    );
  };

  const elementFocusCallBack = useAutoFocusRef();

  return (
    <View style={styles.contentContainer}>
      <SaveFormTop
        isDirty={isDirty && !isPending}
        isValid={isValid && !isPending}
        onSubmit={handleSubmit(onSubmit)}
        title={t('groups.create.label')}
        saveLabel={t('groups.create.create')}
        topBar={TopModalBar}
        onCancel={onComplete}
      >
        <View style={styles.formItem}>
          <View>
            <Text style={styles.formItemTitle}>{t('groups.list.name')}</Text>
          </View>
          <ControlledTextInput
            control={control}
            name="name"
            placeholder={t('groups.create.placeholder')}
            innerRef={elementFocusCallBack}
          />
        </View>
      </SaveFormTop>
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    width: '100%',
  },
  header: {
    paddingVertical: 15,
    paddingHorizontal: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formItemTitle: {
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 16,
    marginBottom: 10,
  },
  formItem: {
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    paddingTop: Spacing.basePadding.paddingVertical,
  },
});
