import {
  FIND_GROUP,
  useDeleteMutation,
  useFindByQuery,
  useUpdateMutation,
} from '@gripp/shared-logic';
import {
  Colors,
  ControlledTextInput,
  CustomAlert,
  SaveFormTop,
  TopModalBar,
} from '@gripp/shared-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAudioPlayer } from 'expo-audio';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import Toast from 'react-native-toast-message';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const audioSource = require('../../../static-assets/delete_pop.mp3');

type EditGroupFormProps = {
  onComplete: () => void;
  workspaceId: any;
  groupId: string;
};

export const EditGroupForm = ({
  workspaceId,
  onComplete,
  groupId,
}: EditGroupFormProps) => {
  const { t } = useTranslation();
  const translationPath = 'groups.deleteGroup';

  const filter = useMemo(() => {
    return {
      id: { eq: groupId },
      workspace: { eq: workspaceId?.id },
    };
  }, [workspaceId, groupId]);

  const { data, isLoading: isNameLoading } = useFindByQuery({
    modelName: 'group',
    query: FIND_GROUP,
    variables: { filter },
  });

  const [groupName, setGroupName] = useState('');
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const player = useAudioPlayer(audioSource);

  const { mutateAsync, isPending } = useUpdateMutation({
    modelName: 'group',
    query: UPDATE_GROUP,
  });

  const { mutateAsync: deleteGroupAsync, isPending: isDeleteGroupPending } =
    useDeleteMutation({
      modelName: 'group',
      query: DELETE_GROUP,
    });

  const formSchema = yup.object({
    name: yup.string().required('Group name is required'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: { name: groupName },
  });

  useEffect(() => {
    if (!data || !data.items.length) return;

    reset({ name: data.items[0].name });
    setGroupName(data.items[0].name);
  }, [data]);

  const onSubmit = async (data: yup.InferType<typeof formSchema>) => {
    const input = {
      id: groupId,
      name: data.name,
      workspace: { id: workspaceId?.id },
    };

    await mutateAsync(
      { input },
      {
        onSettled: () => {
          onComplete();
        },
      }
    );
  };

  const onGroupDelete = async () => {
    const input = {
      id: groupId,
      workspace: { id: workspaceId?.id },
    };

    await deleteGroupAsync({ input });

    onComplete();
    showToast();
    player.play();
  };

  const showToast = () => {
    Toast.show({
      type: 'blackToast',
      text1: 'Woosh!',
      text2: `${groupName} has been deleted.`,
      position: 'bottom',
      autoHide: true,
      visibilityTime: 2500,
    });
  };

  return (
    !isNameLoading && (
      <>
        <View style={{ flex: 1, width: '100%' }}>
          <SaveFormTop
            onSubmit={handleSubmit(onSubmit)}
            title={groupName}
            topBar={TopModalBar}
            isDirty={isDirty}
            onCancel={onComplete}
          >
            <View style={styles.innerFormContainer}>
              <ControlledTextInput
                control={control}
                name="name"
                placeholder="Group Name"
              />
            </View>
            <View style={styles.bottomContainer}>
              <Pressable
                style={styles.deleteButton}
                onPress={() => {
                  setShowDeleteAlert(true);
                }}
              >
                <Text style={styles.deleteButtonLabel}>
                  {t(`${translationPath}.deleteGroup`)}
                </Text>
              </Pressable>
            </View>
          </SaveFormTop>
        </View>
        <CustomAlert
          visible={showDeleteAlert}
          hideAlert={() => setShowDeleteAlert(false)}
          title={
            <>
              {t(`${translationPath}.alertBody1`)} <b>{groupName}</b>? <br />
              <br />
              <b>{t(`${translationPath}.alertBody2`)}</b>
              {t(`${translationPath}.alertBody3`)}
              {t(`${translationPath}.alertBody4`)}
            </>
          }
          primaryButtonLabel={t(`${translationPath}.delete`)}
          secondaryButtonLabel={t(`${translationPath}.cancel`)}
          action={() => onGroupDelete()}
          containerStyle={styles.deleteAlertContainer}
        />
      </>
    )
  );
};

const styles = StyleSheet.create({
  innerFormContainer: {
    margin: 20,
  },
  bottomContainer: {
    flex: 1,
    borderTopWidth: 1,
    borderTopColor: Colors.graySpacer,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
  },
  deleteButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  deleteButtonLabel: {
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.25,
    color: Colors.danger,
  },
  deleteAlertContainer: {
    width: 350,
  },
});

const UPDATE_GROUP = `
  mutation UpdateGroup($input: MutateGroupInput!) {
  updateGroup(input: $input) {
    name
    workspace {
      id
    }
  }
}  
`;

const DELETE_GROUP = `
mutation DeleteGroup($input: MutateGroupInput!) {
  deleteGroup(input: $input) {
    id
    name
    workspace {
      id
    }
  }
}
`;
