import { useGrippContext, useStorageKeys } from '@gripp/shared-logic';
import { RegisterForm } from '@gripp/shared-ui';
import { useNavigate } from 'react-router-dom';

export default function Register() {
  const navigate = useNavigate();
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();
  return (
    <RegisterForm
      onVerify={async () => {
        await storageHandler.setItem(storageKeys.newRegister, 'register');
        navigate('/verify-auth-code');
      }}
      onBack={() => navigate('/login')}
    />
  );
}
