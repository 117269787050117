/* eslint-disable @typescript-eslint/no-explicit-any */
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { GroupFilterModel, useCurrentWorkspace } from '@gripp/shared-logic';
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleProp, StyleSheet, Text, View } from 'react-native';
import { Colors } from '../../themes';
import { BaseColumnCheckbox } from '../baseColumnCheckbox';

export type GroupTableData = {
  name: string;
  numReportsRed: string;
  numReportsYellow: string;
  assets: string;
  people: string;
};

const IconSize = 16;

const BaseColumns: Record<
  string,
  {
    element: (info: CellContext<GroupTableData, unknown>) => JSX.Element;
    exportCell?: (info: GroupTableData) => string;
  }
> = {
  name: {
    element: (info) => getInfoLabel(styles.nameLabel, info),
  },
  reports: {
    element: (info) => {
      const data = info.row.original;

      return (
        <View style={styles.iconRow}>
          <View style={[styles.iconContainer, { marginRight: 12 }]}>
            <MaterialCommunityIcons
              name="flag"
              color={Colors.red}
              size={IconSize}
              style={styles.icon}
            />
            {getLabel([styles.greyLabel, styles.flagText], data.numReportsRed)}
          </View>

          <View style={styles.iconContainer}>
            <MaterialCommunityIcons
              name="flag-outline"
              color={Colors.yellow}
              size={IconSize}
              style={styles.icon}
            />
            {getLabel(
              [styles.greyLabel, styles.flagText],
              data.numReportsYellow
            )}
          </View>
        </View>
      );
    },
    exportCell: (data) =>
      `Red: ${data.numReportsRed}, Yellow: ${data.numReportsYellow}`,
  },
  assets: {
    element: (info) => {
      const data = info.row.original;

      const AssetsButton = () => {
        return (
          <View style={[styles.iconContainer, { marginRight: 12 }]}>
            <MaterialCommunityIcons
              name="qrcode"
              color={Colors.blackText}
              size={IconSize}
              style={styles.icon}
            />
            {getLabel(styles.countText, data.assets)}
          </View>
        );
      };

      return <View style={styles.iconRow}>{<AssetsButton />}</View>;
    },
    exportCell: (data) => data.assets,
  },
  people: {
    element: (info) => {
      const data = info.row.original;

      return (
        <View style={styles.iconRow}>
          <View style={[styles.iconContainer, { marginRight: 12 }]}>
            <MaterialCommunityIcons
              name="account-multiple"
              color={Colors.blackText}
              size={IconSize}
              style={styles.icon}
            />
            {getLabel(styles.countText, data.people)}
          </View>
        </View>
      );
    },
    exportCell: (data) => data.people,
  },
};

export const useColumns = (
  filter?: GroupFilterModel,
  customAssetsButton?: (data: GroupTableData) => JSX.Element,
  customPeopleButton?: (data: GroupTableData) => JSX.Element
) => {
  const [columns, setColumns] = useState<ColumnDef<unknown, never>[]>([]);

  const { t } = useTranslation();
  const columnHelper = createColumnHelper();
  const workspace = useCurrentWorkspace(true);
  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    if (!filter) return;

    const updatedColumns: ColumnDef<unknown, never>[] = [];

    if (isWeb && workspace) {
      updatedColumns.push(
        createCheckboxColumn(
          'selected',
          BaseColumnCheckbox.checkbox,
          BaseColumnCheckbox.checkboxHeader
        )
      );
    }

    const customizedBaseColumns = {
      ...BaseColumns,
      assets: {
        ...BaseColumns.assets,
        element: (info) => {
          const data = info.row.original as GroupTableData;

          return customAssetsButton
            ? customAssetsButton(data)
            : BaseColumns.assets.element(info);
        },
      },
      people: {
        ...BaseColumns.people,
        element: (info) => {
          const data = info.row.original as GroupTableData;
          return customPeopleButton
            ? customPeopleButton(data)
            : BaseColumns.people.element(info);
        },
      },
    };

    Object.entries(customizedBaseColumns).forEach(([key, cell]) => {
      if (filter.columns?.includes(key)) {
        updatedColumns.push(
          createInfoColumn(key, cell.element, undefined, cell.exportCell, true)
        );
      }
    });

    setColumns(updatedColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, workspace]);

  const createCheckboxColumn = (
    accessor: string,
    cell: (info: any) => JSX.Element,
    header: (info: any) => JSX.Element
  ) => {
    return columnHelper.accessor(accessor, {
      cell: cell,
      header: header,
      meta: { style: styles.checkboxCell },
    });
  };

  const createInfoColumn = (
    accessor: string,
    cell: (info: any) => JSX.Element,
    headerOverride?: string,
    exportCell?: (info: GroupTableData) => string,
    sortable = true
  ) => {
    return columnHelper.accessor(accessor, {
      cell: cell,
      header: () => headerOverride ?? t(`groups.list.${accessor}`),
      meta: {
        style: styles.cell,
        sortable: sortable,
        exportCell: exportCell,
      },
    });
  };

  return columns;
};

const getLabel = (labelStyle: StyleProp<any>, label: string) => {
  return <Text style={[labelStyle, styles.cellText]}>{label}</Text>;
};

const getInfoLabel = (labelStyle: StyleProp<any>, info: any) => {
  return getLabel(labelStyle, info.getValue());
};

const styles = StyleSheet.create({
  cell: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    paddingVertical: 4,
    ...Platform.select({
      web: {
        height: 50,
        paddingRight: 16,
      },
      default: {
        height: 68,
        paddingRight: 12,
      },
    }),
  },
  checkboxCell: {
    maxWidth: 50,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  nameLabel: {
    color: Colors.black,
  },
  greyLabel: {
    color: Colors.grayText,
  },
  cellText: {
    fontSize: 14,
    ...Platform.select({
      web: {
        fontWeight: '500',
        flexWrap: 'nowrap',
      },
      default: {
        fontWeight: '400',
        flexWrap: 'wrap',
      },
    }),
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    marginRight: 2,
  },
  iconRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
  },
  flagText: {
    fontSize: 14,
    lineHeight: 16,
  },
  countText: {
    fontSize: 12,
  },
});
