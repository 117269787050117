import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import {
  useCustomMutation,
  useGrippContext,
  useInvalidateConfig,
  useSendAuthCode,
  useStorageKeys,
} from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { OtpInput } from 'react-native-otp-entry';
import { TouchableButton } from '../../components';
import { getFormattedPhoneNumber } from '../../components/forms/controlledPhoneInput';
import { Colors } from '../../themes/colors';
import { DividerText } from './dividerText';
import { LogoHeader } from './logoHeader';
import { LoginProps } from './props';
import { styles } from './styles';
import { ToggleLoginType } from './toggleLoginType';
import { useAccessTokenReceived } from './useAccessTokenReceived';
import { ViewError } from './viewError';

export const VerifyAuthCode = (props: LoginProps) => {
  const { t } = useTranslation();

  const { mutateAsync } = useCustomMutation({
    query: validateAuthCode,
  });

  const { sendAuthCodeAsync } = useSendAuthCode();

  const [error, setError] = useState<string | undefined>(undefined);
  const [sentTo, setSentTo] = useState<string>('');
  const [icon, setIcon] = useState<string>('sms');

  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();
  const onAccessTokenReceived = useAccessTokenReceived();
  const invalidateConfig = useInvalidateConfig();

  const getAuthCodeInput = async () => {
    return JSON.parse(
      (await storageHandler.getItem(storageKeys.authCodeInput)) as string
    );
  };

  useEffect(() => {
    const getSentTo = async () => {
      const authCodeInput = await getAuthCodeInput();
      setSentTo(
        authCodeInput.phone
          ? getFormattedPhoneNumber(
              authCodeInput.phone.countryCode,
              authCodeInput.phone.callingCode,
              authCodeInput.phone.phoneNumber
            )
          : authCodeInput.email
      );
      setIcon(authCodeInput.phone ? 'sms' : 'mail-unread-outline');
    };

    getSentTo();
  }, []);

  const onResendCode = async () => {
    const input = {
      ...(await getAuthCodeInput()),
    };

    await sendAuthCodeAsync(input, () => {});
  };

  const onFilled = async (code: string) => {
    const input = {
      ...JSON.parse(
        (await storageHandler.getItem(storageKeys.authCodeInput)) as string
      ),
      code,
    };
    await mutateAsync(
      { input: input },
      {
        onSettled: async (data: any, error: any) => {
          const result = data?.authentication?.validateAuthCode;

          if (result?.success) {
            const redirectPath = await storageHandler.getItem(
              storageKeys.redirectPath
            );

            const { accessToken } = result;
            await onAccessTokenReceived(accessToken);
            await invalidateConfig();

            if (props.handleRedirect) {
              if (props.isNewAccount) {
                await props.handleRedirect('/onboarding');
              } else {
                await props.handleRedirect(redirectPath || '/');
              }
            }
          } else {
            const requestError = t('login.error.processError');
            const invalidCredentials = t('login.error.invalidCredentials');

            if (error || !result) setError(requestError);

            if (!result.success) {
              if (result.message === 'Invalid credentials') {
                setError(invalidCredentials);
              } else {
                setError(requestError);
              }
            }
          }
        },
      }
    );
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? -90 : -120}
      style={styles.container}
    >
      {!props.hideBackButton && (
        <View style={localStyles.backButtonContainer}>
          <TouchableButton
            onPress={props.onBack}
            icon="arrow-left"
            color={Colors.grayText}
            iconSize={28}
          />
        </View>
      )}
      <View style={localStyles.logoHeaderContainer}>
        <LogoHeader />
      </View>
      <View style={localStyles.sentContainer}>
        {icon === 'sms' ? (
          <MaterialIcons
            name={icon}
            size={60}
            color={Colors.grayText}
            // style={localStyles.chatProcessing}
          />
        ) : (
          <Ionicons name={icon} size={60} color={Colors.grayText} />
        )}
        <Text style={[localStyles.sentTo]}>{t('login.codeSentTo')}</Text>
        <Text style={[localStyles.sentTo, localStyles.phone]}>{sentTo}</Text>
      </View>
      <View style={localStyles.otpWrapper}>
        <OtpInput
          autoFocus={true}
          numberOfDigits={4}
          focusColor={Colors.primary}
          onFilled={onFilled}
          theme={{
            containerStyle: localStyles.otpContainer,
            pinCodeContainerStyle: localStyles.pinCodeContainer,
          }}
        />
        <Text style={localStyles.emailSendCode}>
          {icon === 'sms'
            ? t('login.smsConfirmationMessage')
            : t('login.emailConfirmationMessage')}
        </Text>
      </View>
      <ViewError error={error} />
      <View style={localStyles.toggleContainer}>
        <ToggleLoginType
          text={t('login.resendCode')}
          onChangeLoginType={async () => await onResendCode()}
        />
        <DividerText text={t('or')} />
        <ToggleLoginType
          text={t('login.tryAnotherMethod')}
          onChangeLoginType={() =>
            props.onTryAnotherMethod && props.onTryAnotherMethod()
          }
        />
      </View>
    </KeyboardAvoidingView>
  );
};

const localStyles = StyleSheet.create({
  backButtonContainer: {
    marginTop: 50,
    paddingLeft: 20,
  },
  chatProcessing: {
    paddingVertical: 10,
  },
  logoHeaderContainer: {
    alignItems: 'center',
  },
  sentContainer: {
    alignItems: 'center',
    paddingBottom: 40,
  },
  otpWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 350,
  },
  otpContainer: {
    justifyContent: 'center',
    ...Platform.select({
      web: {
        maxWidth: 350,
      },
      default: {
        width: '80%',
      },
    }),
  },
  pinCodeContainer: {
    backgroundColor: Colors.white,
    height: 52,
    width: 74,
    marginHorizontal: 8,
  },
  sentTo: {
    fontSize: 16,
    lineHeight: 19,
    paddingTop: 5,
    color: Colors.blackText,
  },
  phone: {
    fontWeight: '700',
  },
  toggleContainer: {
    marginTop: 100,
    minWidth: 260,
  },
  emailSendCode: {
    fontSize: 12,
    color: Colors.grayText,
    paddingVertical: 12,
  },
});

const validateAuthCode = `
  mutation Mutation($input: ValidateAuthCodeInput!) {
    authentication {
      validateAuthCode(input: $input) {
        success
        message
        accessToken
      }
    }
  }
`;
