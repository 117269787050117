import { useUpdateIssue, useUpdateMutation } from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dimensions, Modal, Platform, StyleSheet, View } from 'react-native';
import { string, object as yupObject } from 'yup';
import { useAutoFocusRef } from '../../hooks';
import { Spacing } from '../../styles';
import { Colors } from '../../themes/colors';
import { ControlledTextInput, SaveFormTop } from '../forms';
import { TopModalBar } from '../modals/topModalBar';

type IssueTitleModalProps = {
  visible: boolean;
  hide: () => void;
  issueId: string;
  currentTitle: string;
};

export const IssueTitleModal = (props: IssueTitleModalProps) => {
  const { t } = useTranslation();
  const isDesktop = () => Platform.OS === 'web';

  const { mutateAsync: updateIssue } = useUpdateIssue(useUpdateMutation);

  const formSchema = yupObject({
    title: string().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    values: {
      title: props.currentTitle ?? '',
    },
  });

  const onSubmit = async (data: any) => {
    const input = {
      id: props.issueId,
      title: data.title,
    };

    await updateIssue({ input: input });
    props.hide();
  };

  const onCancel = () => {
    reset({ title: props.currentTitle });
    props.hide();
  };

  const elementFocusCallBack = useAutoFocusRef();

  return (
    <Modal
      visible={props.visible}
      onRequestClose={props.hide}
      animationType={isDesktop() ? 'none' : 'slide'}
      transparent={isDesktop()}
      presentationStyle={isDesktop() ? 'overFullScreen' : 'pageSheet'}
    >
      <View style={isDesktop() && styles.desktopWrapper}>
        <View
          style={isDesktop() ? styles.desktopContainer : styles.mobileContainer}
        >
          <SaveFormTop
            isDirty={isDirty}
            isValid={isValid}
            onSubmit={handleSubmit(onSubmit)}
            title={t('issue.editTitleModal.header')}
            saveLabel={t('issue.editTitleModal.save')}
            topBar={TopModalBar}
            onCancel={onCancel}
          >
            <View style={styles.formContainer}>
              <ControlledTextInput
                control={control}
                name="title"
                innerRef={elementFocusCallBack}
              />
            </View>
          </SaveFormTop>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  formContainer: {
    paddingHorizontal: Spacing.basePadding.paddingHorizontal,
    paddingTop: Spacing.basePadding.paddingVertical,
  },
  desktopWrapper: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: Dimensions.get('window').height / 4,
    backgroundColor: Colors.blackInactive,
  },
  desktopContainer: {
    backgroundColor: Colors.primaryGrayBackground,
    width: 560,
    minHeight: 167,
    borderRadius: 12,
  },
  mobileContainer: {
    backgroundColor: Colors.primaryGrayBackground,
    height: '100%',
  },
});
