/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AccountFilterModel,
  getFullName,
  Role,
  useAccountFilter,
  useCurrentAccount,
  useDeleteMutation,
  useRoles,
  useSetAccountFilter,
  useUpdateMutation,
} from '@gripp/shared-logic';
import {
  BaseColumnCheckbox,
  getAccountColumn,
  getFormattedPhoneNumber,
  getGroupColumn,
  WorkspaceColumn,
} from '@gripp/shared-ui';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, Text } from 'react-native';

export const useColumnsAccount = (
  showCheckboxColumn: boolean,
  showImpersonateWorkspace: boolean
) => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper();
  const [columns, setColumns] = useState<ColumnDef<unknown, never>[]>([]);
  const { data: accountFilter } = useAccountFilter();
  const { mutateAsync: setAccountFilter } = useSetAccountFilter();
  const { account } = useCurrentAccount();
  const { roles: allRoles } = useRoles();
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    if (allRoles && account) {
      if (!account.isGlobalAdmin()) {
        setRoles(allRoles.filter((x) => x.name !== 'Global Admin'));
      } else {
        setRoles(allRoles);
      }
    }
  }, [allRoles, account]);

  const { mutateAsync: deleteAccount } = useDeleteMutation({
    modelName: 'account',
    query: `
    mutation DeleteAccount($input: MutateAccountInput!) {
      deleteAccount(input: $input) {
        id
      }
    }`,
  });
  const { mutateAsync: updateAccountType } = useUpdateMutation({
    modelName: 'account',
    query: `mutation UpdateAccount($input: MutateAccountInput!) {
          updateAccount(input: $input) {
            id
          }
        }`,
  });

  const updateGroupFilter = async (
    groupId: string,
    accountFilter: AccountFilterModel
  ) => {
    await setAccountFilter({
      ...accountFilter,
      groups: [groupId],
    });
  };

  const allColumns: Record<string, ColumnDef<unknown, never>> = useMemo(
    () => ({
      selected: columnHelper.accessor('selected', {
        cell: BaseColumnCheckbox.checkbox,
        header: BaseColumnCheckbox.checkboxHeader,
        meta: { style: styles.checkboxCell },
      }),
      name: columnHelper.accessor('name', {
        cell: (info) => {
          const name = info.getValue();
          return <Text style={styles.cellText}>{getFullName(name)}</Text>;
        },
        header: () => t('account.list.name'),
        meta: { sortable: true },
      }),
      jobTitle: columnHelper.accessor('jobTitle', {
        cell: (info) => <Text>{info.getValue()}</Text>,
        header: () => t('account.list.jobTitle'),
        meta: { sortable: true },
      }),
      email: columnHelper.accessor('email', {
        cell: (info) => <Text style={styles.emailText}>{info.getValue()}</Text>,
        header: () => t('account.list.email'),
        meta: { sortable: true },
      }),
      phone: columnHelper.accessor('phone', {
        cell: (info) => {
          const phone = info.getValue() as any;
          if (phone) {
            const phoneNumber = getFormattedPhoneNumber(
              phone.countryCode,
              phone.callingCode,
              phone.phoneNumber
            );
            return <Text style={styles.emailText}>{phoneNumber}</Text>;
          }
        },
        header: () => t('account.list.phone'),
        meta: { sortable: true },
      }),
      roles: columnHelper.accessor('roles', {
        cell: (info) =>
          getAccountColumn(
            info,
            roles,
            async ({ id, action, selectedRoles }) => {
              if (action === 'remove') {
                await deleteAccount({ input: { id } });
              } else {
                await updateAccountType({
                  input: {
                    id,
                    roles: selectedRoles.map((role) => ({ id: role })),
                  },
                });
              }
            }
          ),
        header: () => t('account.list.accountType'),
        meta: { sortable: false },
      }),
      groups: columnHelper.accessor('groups', {
        cell: (info) =>
          getGroupColumn(
            info,
            async (groupId) => await updateGroupFilter(groupId, accountFilter)
          ),
        header: () => t('account.list.groups'),
        meta: { sortable: false },
      }),
      createdAt: columnHelper.accessor('createdAt', {
        cell: (info) => {
          if (info.getValue()) {
            const date = moment(info.getValue());
            return <Text>{date.fromNow()}</Text>;
          }
        },
        header: () => t('account.list.added'),
        meta: { sortable: true },
      }),
      workspace: columnHelper.accessor('workspace', {
        cell: (info) => (
          <WorkspaceColumn
            canImpersonate={showImpersonateWorkspace}
            workspace={info.getValue() as any}
          />
        ),
        header: () => t('account.list.workspace'),
        meta: { sortable: false },
      }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountFilter, roles]
  );

  useEffect(() => {
    if (!accountFilter) return;

    const columnList = [];
    if (showCheckboxColumn) {
      columnList.push(allColumns.selected);
    }

    columnList.push(allColumns.name);

    Object.keys(allColumns).forEach((key) => {
      if (accountFilter.columns?.includes(key)) {
        columnList.push(allColumns[key]);
      }
    });

    setColumns(columnList);
  }, [accountFilter, allColumns, showCheckboxColumn]);

  return columns;
};

const styles = StyleSheet.create({
  accountsContainer: {
    flex: 1,
    borderRadius: 12,
    overflow: 'hidden',
  },
  checkboxCell: {
    maxWidth: 50,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  cellText: {
    fontSize: 16,
    lineHeight: 17,
    ...Platform.select({
      web: {
        fontWeight: '500',
        flexWrap: 'nowrap',
      },
      default: {
        fontWeight: '400',
        flexWrap: 'wrap',
      },
    }),
  },
  emailText: {
    overflow: 'hidden',
  },
});
