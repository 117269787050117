import {
  FeatureName,
  Issue,
  Message,
  useAddIsueMessageReaction,
  useIsFeatureEnabled,
} from '@gripp/shared-logic';
import { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import EmojiPicker from 'rn-emoji-keyboard';
import { EmojiType } from 'rn-emoji-keyboard/lib/typescript/types';
import { EmojiPickerBaseRow } from './emojiPickerBaseRow';
import { ReactionPickerView } from './reactionPickerView';

export type ReactionProps = {
  isReactionVisible: boolean;
  issue: Issue;
  onCloseReaction: () => void;
  message: Message;
  anchorRef: React.RefObject<View>;
};

export const NewReaction = (props: ReactionProps) => {
  const isUserReactionMessageEnabled = useIsFeatureEnabled(
    FeatureName.IssueMessageReaction
  );
  const isWeb = Platform.OS === 'web';
  const [isBaseRowVisible, setIsBaseRowVisible] = useState(false);
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const { mutateAsync: AddReaction, isPending } = useAddIsueMessageReaction();

  const handleEmojiSelection = async (emoji: EmojiType) => {
    if (isPending) return;

    await AddReaction({
      input: {
        issueMessageId: props.message.id,
        reactionType: emoji.emoji,
      },
    });
    setIsEmojiPickerVisible(false);
    props.onCloseReaction();
  };

  useEffect(() => {
    setIsBaseRowVisible(props.isReactionVisible);
  }, [props.isReactionVisible]);

  if (!isUserReactionMessageEnabled || isWeb) return null;

  return (
    <ReactionPickerView
      anchorRef={props.anchorRef}
      isVisible={props.isReactionVisible}
      onDismiss={props.onCloseReaction}
      showContainer={isBaseRowVisible}
    >
      {isBaseRowVisible && (
        <EmojiPickerBaseRow
          onOpenEmojiPicker={() => {
            setIsEmojiPickerVisible(true);
            setIsBaseRowVisible(false);
          }}
          onEmojiSelected={handleEmojiSelection}
        />
      )}

      <EmojiPicker
        onEmojiSelected={handleEmojiSelection}
        open={isEmojiPickerVisible}
        onClose={() => {
          setIsEmojiPickerVisible(false);
          props.onCloseReaction();
        }}
        enableSearchBar
      />
    </ReactionPickerView>
  );
};
