import {
  MediaItem,
  SignatureScreenProps,
  useCurrentWorkspace,
} from '@gripp/shared-logic';
import { Colors, TopModalBar } from '@gripp/shared-ui';
import LoadingIndicator from 'libs/shared-ui/src/components/loadingIndicator';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Pressable, StyleSheet, Text, View } from 'react-native';
import {
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import { SaveFormTop } from '../../../../../../libs/shared-ui/src/components/forms';
import { useMediaUpload } from '../../hooks/useMediaUpload';
import { SignaturePad } from './signaturePad';

export const SignatureScreenWeb = (props: SignatureScreenProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageString, setImageString] = useState<string | null>(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const currentWorkspace = useCurrentWorkspace();
  const getWorkspaceId = useMemo(
    () => currentWorkspace?.id,
    [currentWorkspace]
  );
  const { t } = useTranslation();
  const [fileStr, setFileStr] = useState<string | undefined>();

  const insets = useSafeAreaInsets();

  useEffect(() => {
    if (imageFile && imageString) {
      setFileStr(imageString);
    } else {
      setFileStr(undefined);
    }
  }, [imageFile, imageString]);

  const onSubmit = async () => {
    if (imageFile) {
      uploadBlob(imageFile, getWorkspaceId);
      setImageString(null);
      setFileStr(undefined);
    }
  };

  const handleCancel = () => {
    setFileStr(undefined);
    setImageString(null);
    setModalVisible(false);
  };

  const uploadBlob = useMediaUpload({
    onStart: (mediaItem: MediaItem) => {
      setShowSpinner(true);
      props.setValue(props.fieldName, mediaItem.id, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    onComplete: (mediaId: string) => {
      setShowSpinner(false);
      setModalVisible(false);
    },
  });

  return (
    <>
      <Pressable style={styles.button} onPress={() => setModalVisible(true)}>
        <Text style={styles.buttonLabel}>{t('routines.signature.sign')}</Text>
      </Pressable>

      {imageFile && (
        <View style={styles.signaturePreview}>
          <img
            src={URL.createObjectURL(imageFile)}
            width={450}
            alt="sign file"
          />
        </View>
      )}

      <Modal
        animationType={'slide'}
        presentationStyle="fullScreen"
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <SafeAreaView
          style={[styles.contentContainer, { paddingTop: insets.top }]}
        >
          <SaveFormTop
            isValid={fileStr !== undefined}
            onSubmit={onSubmit}
            topBar={TopModalBar}
            title={t('routines.signature.signature')}
            saveLabel={t('routines.signature.submit')}
            onCancel={handleCancel}
          >
            {showSpinner ? (
              <View style={styles.loadingContainer}>
                <LoadingIndicator
                  backgroundColor={Colors.primaryGrayBackground}
                />
              </View>
            ) : (
              <SignaturePad
                setImageString={(str: string) => setImageString(str)}
                imgFile={(file: File | null) => {
                  setImageFile(file);
                }}
              />
            )}
          </SaveFormTop>
        </SafeAreaView>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    height: 44,
    width: '100%',
    borderWidth: 2,
    borderColor: Colors.primary,
    borderRadius: 12,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.primaryDark,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: Colors.primaryGrayBackground,
    textAlign: 'center',
    flexDirection: 'column',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  signaturePreview: {
    alignSelf: 'center',
    paddingTop: 20,
  },
});

export default SignatureScreenWeb;
