import { useStorageKeys } from '@gripp/shared-logic';
import { Colors, LogoHeader } from '@gripp/shared-ui';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';
import { localStorageHandler } from '../shared/localStorageHandler';

export const OnboardingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const storageKeys = useStorageKeys();

  const resetRegisterStorage = async () => {
    await localStorageHandler.setItem(storageKeys.newRegister, '');
  };

  useEffect(() => {
    resetRegisterStorage();
    setTimeout(() => {
      navigate('/');
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={styles.container}>
      <View style={{ marginTop: 80, alignItems: 'center' }}>
        <LogoHeader />
      </View>
      <Text style={styles.title}>{t('permissions.allSet')}</Text>
      <ActivityIndicator size="large" color={Colors.grayDivider} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontWeight: '800',
    fontSize: 24,
    color: Colors.blackText,
    marginBottom: 24,
  },
});
