import { useCustomMutation } from '../../graphql';
import { useGrippContext } from '../../grippContext';
import { useStorageKeys } from '../../storage/useStorageKeys';

type AuthenticationData = {
  authentication: {
    sendAuthCode: {
      success: boolean;
      message?: string;
      existed?: boolean;
    };
  };
};

export interface PhoneInput {
  countryCode: string;
  callingCode: string;
  phoneNumber: string;
}

export interface NameInput {
  first: string;
  last: string;
}

export interface AuthCodeInput {
  phone?: PhoneInput;
  email?: string;
}

export interface AccountInput {
  name: NameInput;
  phone: PhoneInput;
  email?: string;
  jobTitle?: string;
}

export const useSendAuthCode = () => {
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();

  const { mutateAsync, isPending } = useCustomMutation({
    query: sendAuthCode,
  });

  const { mutateAsync: mutateRegisterAsync, isPending: isRegistering } =
    useCustomMutation({
      query: registerWithAuthCode,
    });

  return {
    sendAuthCodeAsync: async (
      input: AuthCodeInput,
      onSettled: () => void,
      onRegister: () => void = () => null
    ) => {
      await storageHandler.setItem(
        storageKeys.authCodeInput,
        JSON.stringify(input)
      );

      await mutateAsync(
        { input },
        {
          onSettled(data: AuthenticationData) {
            if (data?.authentication?.sendAuthCode?.success) {
              onSettled();
            }
            if (
              !data?.authentication?.sendAuthCode?.success &&
              data?.authentication?.sendAuthCode?.existed === false
            ) {
              onRegister();
            }
            return;
          },
        }
      );
    },
    registerWithAuthCode: async (
      input: AccountInput,
      onSettled: () => void
    ) => {
      await storageHandler.setItem(
        storageKeys.authCodeInput,
        JSON.stringify({
          ...(input.email && { email: input.email }),
          ...(input.phone && { phone: input.phone }),
        })
      );

      await mutateRegisterAsync(
        { input },
        {
          onSettled() {
            onSettled();
          },
        }
      );
    },
    isPending: isPending || isRegistering,
  };
};

const sendAuthCode = `
  mutation Mutation($input: AuthCodeInput!) {
    authentication {
      sendAuthCode(input: $input) {
        success
        message
        existed
      }
    }
  }
`;

const registerWithAuthCode = `
  mutation Mutation($input: AccountInput!) {
    authentication {
      registerWithAuthCode(input: $input) {
        success
      }
    }
  }
`;
