import {
  PermissionValues,
  SortDirectionEnum,
  Workspace,
  getFullName,
  useCurrentAccount,
  useImpersonateWorkspace,
} from '@gripp/shared-logic';
import { Colors, InfiniteDataGrid } from '@gripp/shared-ui';
import { createColumnHelper } from '@tanstack/react-table';
import { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import { Button } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';
import { ModalButton } from '../../shared/components/modalButton';
import { useMaxHeight } from '../../shared/hooks/useMaxHeight';
import { AllowedWorkspacesAdmin } from './allowedWorkspacesAdmin';
import { AssetAdmin } from './assetAdmin';
import { ExportButton } from './dataExport/exportButton';
import { useAccountsExport } from './dataExport/useAccountsExport';
import { useActivityExport } from './dataExport/useActivityExport';
import { useAssetExport } from './dataExport/useAssetExport';
import { useRoutineExport } from './dataExport/useRoutineExport';
import { DeleteWorkspace } from './deleteWorkspace';
import { EditWorkspace } from './editWorkspace';
import { WorkspaceRoutineAdmin } from './workspaceRoutineAdmin';

// Memoized Cell Components
const AssetCell = memo(({ workspaceId, workspaceName }) => (
  <ModalButton
    icon="format-list-bulleted-type"
    modalComponent={() => (
      <AssetAdmin workspaceId={workspaceId} workspaceName={workspaceName} />
    )}
  />
));

const RoutineCell = memo(({ workspaceId, workspaceName }) => (
  <ModalButton
    icon="format-list-bulleted-type"
    modalComponent={() => (
      <WorkspaceRoutineAdmin
        workspaceId={workspaceId}
        workspaceName={workspaceName}
      />
    )}
  />
));

const UsersCell = memo(({ workspaceId, workspaceName }) => (
  <ModalButton
    icon="format-list-bulleted-type"
    modalComponent={() => (
      <AllowedWorkspacesAdmin
        workspaceId={workspaceId}
        workspaceName={workspaceName}
      />
    )}
  />
));

const ExportCell = memo(({ workspace }) => (
  <View style={styles.export}>
    <ExportButton
      workspace={workspace}
      icon="tractor"
      toolTip="Export Assets"
      useExport={useAssetExport}
      fileName="assets"
    />
    <ExportButton
      workspace={workspace}
      icon="chart-timeline-variant-shimmer"
      toolTip="Export Activity"
      useExport={useActivityExport}
      fileName="activity"
    />
    <ExportButton
      workspace={workspace}
      icon="clipboard-list-outline"
      toolTip="Export Routines"
      useExport={useRoutineExport}
      fileName="routines"
    />
    <ExportButton
      workspace={workspace}
      icon="account-outline"
      toolTip="Export Accounts"
      useExport={useAccountsExport}
      fileName="accounts"
    />
  </View>
));

const EditCell = memo(({ workspace }) => (
  <View style={styles.editColumn}>
    <ModalButton
      icon="pencil"
      containerStyle={styles.editModal}
      modalComponent={({ onComplete }) => (
        <EditWorkspace workspace={workspace} onComplete={onComplete} />
      )}
    />
    <DeleteWorkspace workspace={workspace} />
  </View>
));

const ExportAllButton = memo(() => (
  <ExportButton
    icon="account-multiple-outline"
    toolTip="Export All Accounts"
    useExport={useAccountsExport}
    fileName="accounts"
  />
));

export const Workspaces = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { account } = useCurrentAccount();
  const { impersonateWorkspace } = useImpersonateWorkspace();

  const { containerMaxHeight, listMaxHeight } = useMaxHeight();

  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: ({ row }) => {
          const original = row.original;
          const onPress = async () => {
            await impersonateWorkspace(original as Workspace);
            navigate('/activities');
          };
          return (
            <Text>
              <Button mode="text" onPress={onPress}>
                {original.name}
              </Button>
            </Text>
          );
        },
        header: () => t('workspace.list.workspace'),
        meta: { sortable: true },
      }),
      columnHelper.accessor('owner', {
        cell: (info) => {
          const name = info.getValue()?.name;
          return <Text>{getFullName(name)}</Text>;
        },
        header: () => t('workspace.list.administrator'),
        meta: { sortable: true },
      }),
      columnHelper.accessor('id', {
        cell: ({ row }) => {
          const original = row.original;
          return (
            <AssetCell
              workspaceId={original.id}
              workspaceName={original.name}
            />
          );
        },
        header: () => 'Assets',
      }),
      columnHelper.accessor('assetId', {
        cell: ({ row }) => {
          const original = row.original;
          return (
            <RoutineCell
              workspaceId={original.id}
              workspaceName={original.name}
            />
          );
        },
        header: () => 'Routines',
      }),
      columnHelper.accessor('users', {
        cell: ({ row }) => {
          const original = row.original;
          return (
            <UsersCell
              workspaceId={original.id}
              workspaceName={original.name}
            />
          );
        },
        header: () => 'Users',
      }),
      columnHelper.accessor('exports', {
        cell: ({ row }) => {
          const original = row.original;
          return <ExportCell workspace={original as Workspace} />;
        },
        header: () => 'Exports',
      }),
      columnHelper.accessor('edit', {
        cell: ({ row }) => {
          const original = row.original;
          return <EditCell workspace={original as Workspace} />;
        },
        header: () => '',
      }),
    ],
    [t, navigate, impersonateWorkspace]
  );

  useEffect(() => {
    if (!account) return;

    if (!account.hasPermission(PermissionValues.globalAdmin)) {
      navigate(`/accounts/${account.workspace?.id}`);
    }
  }, [account, navigate]);

  return (
    <View>
      <View style={styles.exportAllAccountsContainer}>
        <ExportAllButton />
      </View>
      <View
        style={[
          styles.workspacesContainer,
          {
            maxHeight: containerMaxHeight,
          },
        ]}
      >
        <InfiniteDataGrid
          columns={columns}
          query={GET_WORKSPACES}
          modelName="workspace"
          orderBy={{
            field: 'name',
            order: SortDirectionEnum.Asc,
          }}
          pageSize={50}
          hideHeader={false}
          listMaxHeight={listMaxHeight}
          showScrollIndicator={true}
        />
      </View>
    </View>
  );
};

const GET_WORKSPACES = `
  query GetWorkspaces($filter: WorkspaceFilter, $page: PageRequest, $orderBy: OrderByInput) {
    findWorkspaces(filter: $filter, page: $page, orderBy: $orderBy) {
      count
      offset
      limit
      items {
        id
        name
        owner {
          name {
            first
            last
          }
        }
      }
    }
  }
`;

const styles = StyleSheet.create({
  workspacesContainer: {
    flex: 1,
    borderRadius: 12,
    overflow: 'hidden',
  },
  export: {
    flexDirection: 'row',
    margin: 5,
  },
  exportAllAccountsContainer: {
    flexDirection: 'column',
    alignSelf: 'flex-end',
  },
  editColumn: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: 30,
    maxWidth: 30,
    marginLeft: 50,
  },
  editModal: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: Dimensions.get('window').height / 4,
    backgroundColor: Colors.blackInactive,
  },
});
