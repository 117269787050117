import {
  useCreateMutation,
  useCustomQuery,
  useDeleteMutation,
} from '../../graphql';
import { Reaction } from '../domain';

export const useAddIsueMessageReaction = () => {
  return useCreateMutation({
    modelName: 'issueMessage',
    query: ADD_USER_REACTION_MESSAGE,
  });
};

export const useDeleteIsueMessageReaction = () => {
  return useDeleteMutation({
    modelName: 'issueMessage',
    query: DELETE_USER_REACTION_MESSAGE,
  });
};

export const useGetUsersIsueMessageReaction = (reactions: Reaction[] = []) => {
  const userIds = reactions?.map((reaction) => reaction.createdBy) ?? [];

  return useCustomQuery({
    query: LIST_REACTION_USERS,
    cacheKey: [],
    variables: {
      accountIds: userIds,
    },
    options: {
      enabled: userIds.length > 0,
    },
  });
};

const ADD_USER_REACTION_MESSAGE = `
  mutation AddReaction($input: AddReactionInput!) {
    issueMessage {
      addReaction(input: $input) {
        message
        success
      }
    }
  }
`;

const DELETE_USER_REACTION_MESSAGE = `
  mutation DeleteReactionIssue($input: DeleteReactionInput!) {
    issueMessage {
      deleteReaction(input: $input) {
        success
      }
    }
  }
`;

const LIST_REACTION_USERS = `
  query ListReactionUsers($accountIds: [String]!) {
  account {
    listReactionUsers(accountIds: $accountIds) {
      count
      items {
        id
        name {
          first
          last
        }
        avatar {
          id
          name
          extension
          status
        }
      }
    }
  }
}
`;
