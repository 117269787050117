import { EnvConfig, Media, MediaData } from '@gripp/shared-logic';
import { useEffect, useRef, useState } from 'react';
import { createTypedMediaItem, processTypedMediaItem } from './mediaUtils';

export const useTypedMediaItem = <T extends MediaData>(
  existingMediaItem: Media | undefined
) => {
  const [typedMediaItem, setTypedMediaItem] = useState<T | undefined>();
  const baseMediaUrl = EnvConfig.mediaUrl;
  const baseApiUrl = EnvConfig.apiUrl;
  const existingMediaItemProcessed = useRef(false);

  useEffect(() => {
    if (
      (existingMediaItem && !existingMediaItemProcessed.current) ||
      (existingMediaItem &&
        existingMediaItemProcessed.current &&
        typedMediaItem?.id !== existingMediaItem?.id)
    ) {
      setTypedMediaItem(
        createTypedMediaItem(existingMediaItem, baseMediaUrl, baseApiUrl) as T
      );
      existingMediaItemProcessed.current = true;
    }
  }, [existingMediaItem]);

  const handleSet = (
    newMediaItem: T,
    callback?: (newMediaItem: Media) => void
  ) => {
    processTypedMediaItem(newMediaItem);
    setTypedMediaItem(() => {
      callback?.(newMediaItem);
      return newMediaItem;
    });
  };

  const handleUpdate = (updatedMediaItem: T) => {
    setTypedMediaItem((prevState: T | undefined) =>
      prevState && prevState.id === updatedMediaItem.id
        ? { ...prevState, ...updatedMediaItem }
        : prevState
    );
  };

  const handleUploadProgress = (mediaId: string, progress: number) => {
    const toSetProgress = {
      id: mediaId,
      uploadProgress: progress,
      isUploading: progress !== 1,
    } as T;
    handleUpdate(toSetProgress);
  };

  const handleRefreshMediaItem = (media?: T) => {
    setTypedMediaItem(media);
  };

  return {
    typedMediaItem,
    handleSet,
    handleUploadProgress,
    handleUpdate,
    handleRefreshMediaItem,
  };
};
