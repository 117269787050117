import {
  IssueType,
  Media,
  MediaPickerComponent,
  MediaType,
  PickerMode,
  ReportIssueInput,
  Severity,
  useCreateIssueWithMessage,
  useCurrentLocation,
} from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { InferType } from 'yup';
import { Layout } from '../../styles';
import { Colors } from '../../themes';
import { SaveFormTop } from '../forms';
import { KeyboardSafeView } from '../keyboard/keyboardSafeView';
import { HorizontalMediaGallery } from '../media';
import { TopComponent } from '../navigation';
import {
  NewIssueReportForm,
  NewIssueReportFormSchema,
} from './newIssueReportForm';

type NewIssueProps = {
  assetId?: string;
  workspaceId: string;
  mediaPicker: MediaPickerComponent;
  acceptedMediaTypes?: MediaType[];
  onIssueCreated: (id: string) => void;
  onCancel?: () => void;
  topBar: TopComponent;
  mode?: PickerMode;
};

type NewIssueReportFormData = InferType<typeof NewIssueReportFormSchema>;

export const NewIssue = (props: NewIssueProps) => {
  const { assetId, workspaceId } = props;
  const MediaPicker = props.mediaPicker;
  const { mutateAsync, isPending } = useCreateIssueWithMessage();
  const { getLocation } = useCurrentLocation();
  const { t } = useTranslation();
  const [issueMedia, setIssueMedia] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const issueTypeTitle = t('issue.new.addIt');

  const addMedia = (newMediaItem: Media) => {
    if (!newMediaItem) return;
    setIssueMedia((prevState) => [...prevState, newMediaItem.id]);
  };

  const onSubmit = async (data: NewIssueReportFormData) => {
    const location = await getLocation();
    const input: ReportIssueInput = {
      severity: data.type === IssueType.Report ? Severity.Red : Severity.Purple,
      title:
        data.title === ''
          ? data.message.match('^(?:[\\w-]+[^\\w-]+){0,4}[\\w-]+')?.[0] || ''
          : data.title || '',
      type: data.type as IssueType,
      message: data.message,
      workspaceId: workspaceId,
      media: issueMedia.length > 0 ? issueMedia : undefined,
      location,
    };
    if (assetId) input.assetId = assetId;

    await mutateAsync(
      { input },
      {
        onSettled: async (data: { issue: { reportIssue: { id: string } } }) => {
          props.onIssueCreated(data.issue.reportIssue.id);
        },
      }
    );
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    resolver: yupResolver(NewIssueReportFormSchema),
    mode: 'onChange',
    defaultValues: {
      message: '',
      title: '',
    },
  });

  return (
    <KeyboardSafeView style={styles.container}>
      <SaveFormTop
        isDirty={isDirty && !isPending}
        isValid={isValid && !isUploading && !isPending}
        onSubmit={handleSubmit(onSubmit)}
        title={issueTypeTitle}
        saveLabel={t('issue.new.submit')}
        topBar={props.topBar}
        onCancel={props.onCancel}
      >
        <NewIssueReportForm
          workspaceId={workspaceId}
          mediaGallery={HorizontalMediaGallery}
          control={control}
          mediaPicker={MediaPicker}
          addMedia={addMedia}
          setIsUploading={setIsUploading}
          acceptedMediaTypes={props.acceptedMediaTypes}
        />
      </SaveFormTop>
    </KeyboardSafeView>
  );
};

const styles = StyleSheet.create({
  container: {
    ...Layout.container,
    backgroundColor: Colors.primaryGrayBackground,
    borderRadius: 12,
  },
});
