import { useCurrentAccount } from '@gripp/shared-logic';
import { Colors } from 'libs/shared-ui/src/themes/colors';
import { FC, useState } from 'react';
import {
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { Button, Modal, Portal } from 'react-native-paper';

type ModalComponent = FC & {
  onComplete: () => void;
};

export type ModalButtonProps = {
  modalComponent: ModalComponent;
  label: string;
  customButton?: JSX.Element;
  icon?: string;
  requiredPermission?: string;
  buttonMode?:
    | 'text'
    | 'outlined'
    | 'contained'
    | 'elevated'
    | 'contained-tonal';
  containerStyle?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
};

/**
 * ModalButton
 *
 * Renders a button that opens a modal when clicked.
 *
 * @param {ModalButtonProps} props
 *    ModalComponent: React.FC; - component to be rendered in modal
 *    label: string; - label for button
 *    icon?: string; - icon for button
 *    requiredPermission?: string; - permission required to render button
 */

export const ModalButton = (props: ModalButtonProps) => {
  const { account } = useCurrentAccount();
  const [visible, toggleVisibility] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const Component = props.modalComponent;

  const canView =
    !props.requiredPermission ||
    account?.hasPermission(props.requiredPermission);

  return canView ? (
    <View>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={() => toggleVisibility(false)}
          contentContainerStyle={props.containerStyle ?? styles.modal}
        >
          <Component onComplete={() => toggleVisibility(false)} />
        </Modal>
      </Portal>

      {props.customButton ? (
        <Pressable
          onHoverIn={() => setIsHovered(true)}
          onHoverOut={() => setIsHovered(false)}
          style={({ pressed }) => [
            styles.customButton,
            props.buttonStyle,
            isHovered && styles.hovered,
            pressed && styles.pressed,
          ]}
          onPress={() => toggleVisibility(true)}
        >
          {props.customButton}
        </Pressable>
      ) : (
        <Button
          icon={props.icon}
          mode={props.buttonMode}
          onPress={() => toggleVisibility(true)}
          labelStyle={styles.button}
          textColor={Colors.primaryDark}
        >
          {props.label}
        </Button>
      )}
    </View>
  ) : (
    <></>
  );
};

export const styles = StyleSheet.create({
  modal: {
    alignSelf: 'center',
    backgroundColor: 'white',
    padding: 20,
    margin: 20,
    borderRadius: 10,
    width: 500,
    minHeight: 400,
  },
  button: { fontSize: 16, marginLeft: 14, letterSpacing: -0.25 },
  customButton: {
    paddingHorizontal: 6,
    paddingVertical: 5,
    borderRadius: 8,
  },
  hovered: {
    backgroundColor: Colors.primaryGrayHeader,
  },
  pressed: {
    backgroundColor: Colors.blackWithAlpha,
  },
});
