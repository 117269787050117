import { useNavigate, useParams } from 'react-router-dom';
import { useWindowDimensions } from '../../shared/hooks/useWindowDimensions';
import { ActivityFold } from './activityFold';

export const Activities = () => {
  const navigate = useNavigate();
  const { issueId } = useParams();
  const windowDimensions = useWindowDimensions();
  const maxHeight = (windowDimensions?.height ?? 0) - 200; // substract banner + padding height

  const onIssueSelected = (issueId: string) => {
    navigate(`/activities/${issueId}`);
  };

  return (
    <ActivityFold
      onIssueSelected={onIssueSelected}
      showAssetName={true}
      selectedIssue={issueId}
      maxHeight={maxHeight}
    />
  );
};
