import { PickerMode } from '@gripp/shared-logic';
import { StyleSheet, View } from 'react-native';
import { Card } from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { ImageComponent } from './imageComponent';
import { ImageData, SingleImageInputProps } from './types';
import { useTypedMediaItem } from './useTypedMediaItem';

export const SingleImageInput = (props: SingleImageInputProps) => {
  const {
    typedMediaItem: image,
    handleSet,
    handleUploadProgress,
  } = useTypedMediaItem<ImageData>(props.image);

  const ImagePicker = props.imagePicker;

  const { displayWidth = 100 } = props;

  return (
    <View
      style={[
        styles.imageCardContainer,
        { height: displayWidth, width: displayWidth },
      ]}
    >
      {image && (
        <Card
          style={[
            styles.imageCard,
            { height: displayWidth + 1, width: displayWidth },
          ]}
        >
          <ImageComponent mediaItem={image} displayWidth={displayWidth} />
        </Card>
      )}

      <ImagePicker
        mode={PickerMode.CAMERA}
        icon={'camera-outline'}
        iconColor={image ? Colors.white : Colors.primaryDark}
        iconSize={image ? 16 : 30}
        setMedia={(media: ImageData) => handleSet(media, props.onImageAdded)}
        setProgress={handleUploadProgress}
        setUploading={props.onUploadingChanged}
        style={
          image
            ? [styles.pickerOverlay, { width: displayWidth }]
            : [styles.picker, { height: displayWidth, width: displayWidth }]
        }
        allowsEditing={props.allowsEditing}
        IsSquareCameraView={true}
      />
    </View>
  );
};
const styles = StyleSheet.create({
  imageCard: {
    borderRadius: 8,
    position: 'absolute',
  },
  imageCardContainer: {
    borderRadius: 8,
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    marginLeft: 2,
  },
  picker: {
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pickerOverlay: {
    height: 20,
    opacity: 0.75,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderRadius: 0,
    backgroundColor: Colors.primary,
  },
});
