// App.jsx
import { StyleSheet } from 'react-native';
import { BaseToast, ErrorToast } from 'react-native-toast-message';
import { Colors } from '../themes';

type toastProps = {
  title: string;
  body?: string;
};
/*
  1. Create the config
*/
export const toastConfig = {
  /*
    Overwrite 'success' type,
    by modifying the existing `BaseToast` component
  */
  success: (props: any) => (
    <BaseToast
      {...props}
      style={{ borderLeftColor: 'pink' }}
      contentContainerStyle={{ paddingHorizontal: 15 }}
      text1Style={{
        fontSize: 15,
        fontWeight: '400',
      }}
    />
  ),
  /*
    Overwrite 'error' type,
    by modifying the existing `ErrorToast` component
  */
  error: (props: any) => (
    <ErrorToast
      {...props}
      text1Style={{
        fontSize: 17,
      }}
      text2Style={{
        fontSize: 15,
      }}
    />
  ),
  blackToast: (props: any) => (
    <BaseToast
      {...props}
      style={[
        styles.blackToast,
        { position: 'absolute', right: 40, bottom: 20 },
      ]}
      contentContainerStyle={{ paddingHorizontal: 15, marginLeft: 20 }}
      text1Style={styles.whiteTitle}
      text2Style={styles.whiteMessage}
    />
  ),
  /*
    Or create a completely new type - `tomatoToast`,
    building the layout from scratch.

    I can consume any custom `props` I want.
    They will be passed when calling the `show` method (see below)
  */
  // tomatoToast: ({ text1, props }) => (
  //   <View style={{ height: 60, width: '100%', backgroundColor: 'tomato' }}>
  //     <Text>{text1}</Text>
  //     <Text>{props.uuid}</Text>
  //   </View>
  // ),
};

/*
  2. Pass the config as prop to the Toast component instance
*/
// export function App(props) {
//   return (
//     <>
//       {...}
//       <Toast config={toastConfig} />
//     </>
//   );
// }

const styles = StyleSheet.create({
  blackToast: {
    width: 369,
    height: 79,
    borderRadius: 12,
    backgroundColor: Colors.black,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  whiteTitle: {
    fontSize: 20,
    fontWeight: '700',
    color: Colors.white,
  },
  whiteMessage: {
    fontSize: 16,
    fontWeight: '500',
    letterSpacing: -0.25,
    color: Colors.white,
  },
});
