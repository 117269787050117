import {
  FeatureName,
  useIsFeatureEnabled,
  useSendAuthCode,
} from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Button, Text, TextInput } from 'react-native-paper';
import { InferType, string, object as yupObject } from 'yup';
import { Colors } from '../../themes';
import { FieldCheckIcon } from './fieldCheckIcon';
import { LoginProps } from './props';
import { styles } from './styles';
import { ViewError } from './viewError';

const formSchema = yupObject({
  email: string().required().email(),
});

type FormData = InferType<typeof formSchema>;

export default function EmailCodeLogin(props: LoginProps) {
  const { t } = useTranslation();
  const [error] = useState<string | undefined>(undefined);

  const { sendAuthCodeAsync, isPending: isLoading } = useSendAuthCode();
  const isNewRegistrationFlow = useIsFeatureEnabled(
    FeatureName.NewRegistrationFlow
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
    getFieldState,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: FormData) => {
    const input = {
      email: data.email,
    };
    const handleCodeSent = props.onCodeSent ?? (() => null);
    if (isNewRegistrationFlow) {
      await sendAuthCodeAsync(input, handleCodeSent, props.onRegister);
    } else {
      await sendAuthCodeAsync(input, handleCodeSent);
    }
  };

  return (
    <View
      style={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View style={styles.inputContainer}>
        {isNewRegistrationFlow && (
          <Text style={styles.inputLabel}>{t('login.email')}</Text>
        )}
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              mode="outlined"
              outlineStyle={{ borderWidth: 0, borderRadius: 6 }}
              style={styles.textInput}
              value={value}
              onBlur={onBlur}
              onChangeText={(value) => onChange(value)}
              placeholder={t('login.email')}
              placeholderTextColor={Colors.grayFill}
              keyboardType="email-address"
              right={FieldCheckIcon({
                invalid: getFieldState('email').invalid,
                value: value,
              })}
            />
          )}
        />
      </View>
      <View style={styles.containerButton}>
        <Button
          mode="contained"
          disabled={!isValid || isLoading}
          onPress={handleSubmit(onSubmit)}
          loading={isLoading}
          style={[
            styles.button,
            !isValid || isLoading
              ? styles.disabledButton
              : styles.enabledButton,
          ]}
        >
          {isNewRegistrationFlow ? (
            <Text style={styles.buttonText}>{t('login.continue')}</Text>
          ) : (
            <Text style={styles.buttonText}>{t('login.login')}</Text>
          )}
        </Button>
      </View>
      <ViewError error={error} />
    </View>
  );
}
