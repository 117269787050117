.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 48px;
  margin: 0;
  padding: 0;
}

.react-tabs__tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75px;
  box-sizing: border-box;
  bottom: -1px;
  position: relative;
  list-style: none;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  font-size: 16px;
  font-weight: 500;
  font-family: sans-serif;
  background: #e9e9ef;
  color: #6c6c70;
  margin-right: 4px;
}

.react-tabs__tab--selected {
  background: #6c6c70;
  color: #fff;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
