import { BlurView } from 'expo-blur';
import React, { useEffect, useRef, useState } from 'react';
import {
  Animated,
  KeyboardAvoidingView,
  Modal,
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { Colors } from '../../themes/colors';

type PopUpViewProps = {
  children: React.ReactNode;
  isVisible: boolean;
  onDismiss: () => void;
  anchorRef?: React.RefObject<View>;
  containerHeight?: number;
  containerStyle?: StyleProp<ViewStyle>;
};

export const PopUpView = ({
  children,
  isVisible,
  onDismiss,
  anchorRef,
  containerHeight,
  containerStyle,
}: PopUpViewProps) => {
  const opacity = useRef(new Animated.Value(0)).current;
  const translateY = useRef(new Animated.Value(0)).current;
  const scaleContainer = useRef(new Animated.Value(0.5)).current;
  const yAnimationDist = 30;
  const menuHeight = containerHeight || 50;

  const [containerPosition, setContainerPosition] = useState({
    top: 0,
  });

  useEffect(() => {
    if (isVisible) {
      Animated.parallel([
        Animated.timing(opacity, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(translateY, {
          toValue: -yAnimationDist,
          duration: 200,
          useNativeDriver: true,
        }),
        Animated.timing(scaleContainer, {
          toValue: 1.0,
          duration: 200,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.timing(opacity, {
          toValue: 0,
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(translateY, {
          toValue: 0,
          duration: 50,
          useNativeDriver: true,
        }),
        Animated.timing(scaleContainer, {
          toValue: 0.5,
          duration: 50,
          useNativeDriver: true,
        }),
      ]).start();
    }
  }, [isVisible, opacity, translateY]);

  // Calculate position based on anchor ref
  useEffect(() => {
    if (!isVisible) return;

    if (anchorRef?.current) {
      anchorRef.current.measureInWindow((x, y, width, height) => {
        setContainerPosition({
          top: y + yAnimationDist + (height - menuHeight) / 2,
        });
      });
    } else {
      setContainerPosition({
        top: yAnimationDist,
      });
    }
  }, [isVisible, anchorRef]);

  return (
    <Modal transparent visible={isVisible} animationType="fade">
      <BlurView intensity={5} style={{ flex: 1 }}>
        <KeyboardAvoidingView
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Pressable style={styles.overlayDark} onPress={onDismiss} />

          <Animated.View
            style={[
              styles.container,
              containerStyle,
              {
                opacity,
                transform: [{ translateY }, { scale: scaleContainer }],
              },
              anchorRef?.current && {
                height: menuHeight,
                top: containerPosition.top,
              },
            ]}
          >
            {children}
          </Animated.View>
        </KeyboardAvoidingView>
      </BlurView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  overlayDark: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Colors.blackOverlay,
  },
  container: {
    flex: 1,
    position: 'absolute',
    backgroundColor: Colors.white,
    left: 20,
    right: 20,
    borderRadius: 24,
    overflow: 'hidden',
  },
});
