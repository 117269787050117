import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ScrollView, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { Chip, TextInput } from 'react-native-paper';
import { Colors } from '../../themes/colors';
import { ControlledCheckboxItems } from './controlledCheckboxItems';
import { DebouncedTextInput } from './debouncedTextInput';

type ControlledMultiSelectDropdownProps = {
  control: any;
  name: string;
  options: CheckboxOption[];
  placeholder?: string;
  searchPlaceholder?: string;
  stylesSelectionsDropdown?: ViewStyle;
};

type CheckboxOption = {
  value: string;
  label?: string;
};

export const ControlledMultiSelectDropdown = (
  props: ControlledMultiSelectDropdownProps
) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { value } }) => (
        <View style={styles.container}>
          <SelectionsDropdown
            values={
              value?.map(
                (option: string) =>
                  props.options.filter((o) => o.value === option)[0].label
              ) as string[]
            }
            showOptions={showOptions}
            onToggle={() => setShowOptions(!showOptions)}
            placeholder={props.placeholder}
            styles={props.stylesSelectionsDropdown}
          />
          {showOptions && (
            <OptionsList
              control={props.control}
              name={props.name}
              options={props.options}
              placeholder={props.searchPlaceholder}
            />
          )}
        </View>
      )}
    />
  );
};

type OptionsListProps = {
  control: any;
  name: string;
  options: CheckboxOption[];
  placeholder?: string;
};

const OptionsList = (props: OptionsListProps) => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState(props.options);

  useEffect(() => {
    setOptions(
      props.options.filter(
        (option) =>
          option &&
          option.label &&
          option.label.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search]);

  return (
    <View style={styles.optionsListMainContainer}>
      <DebouncedTextInput
        debounceTimeMs={500}
        debounceLength={3}
        debounceFunc={() => {}}
        placeholder={props.placeholder}
        value={search}
        onBlur={() => {}}
        onChangeText={(text) => setSearch(text)}
        left={<TextInput.Icon icon="magnify" color={Colors.grayFill} />}
        height={40}
        outlineStyle={styles.searchInputStyle}
        disableInnerStyle={true}
      />
      <View style={styles.optionsListContainer}>
        <ScrollView>
          <ControlledCheckboxItems
            control={props.control}
            name={props.name}
            options={options}
            style={styles.checkboxContainer}
            labelStyle={styles.checkboxLabel}
            position="leading"
            mode="android"
          />
        </ScrollView>
      </View>
    </View>
  );
};

type SelectionsProps = {
  values: string[];
  showOptions: boolean;
  onToggle: () => void;
  placeholder?: string;
  styles?: ViewStyle;
};

const SelectionsDropdown = (props: SelectionsProps) => {
  return (
    <View style={[styles.selectionsContainer, props.styles]}>
      <View style={styles.chipsContainer}>
        {props.values && props.values.length > 0 ? (
          props.values.map((value) => (
            <Chip
              key={value}
              style={styles.chip}
              textStyle={styles.chipText}
              ellipsizeMode="tail"
            >
              {value}
            </Chip>
          ))
        ) : (
          <Text style={styles.placeholderText}>{props.placeholder}</Text>
        )}
      </View>
      <View style={styles.chevronContainer}>
        <TextInput.Icon
          size={20}
          icon={props.showOptions ? 'chevron-up' : 'chevron-down'}
          color={Colors.grayText}
          onPress={props.onToggle}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
  },
  optionsListMainContainer: {
    marginTop: 8,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.tabInactive,
    backgroundColor: Colors.white,
  },

  optionsListContainer: {
    marginBottom: 10,
    maxHeight: 400,
  },
  selectionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
    borderWidth: 1,
    backgroundColor: Colors.white,
    borderColor: Colors.tabInactive,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  chip: {
    backgroundColor: '#FFE1C6',
    alignSelf: 'flex-start',
    maxWidth: 140,
    marginHorizontal: 5,
    marginVertical: 5,
    borderRadius: 15,
  },
  chipText: {
    lineHeight: 14,
    fontWeight: '400',
    color: Colors.black,
    paddingVertical: 1,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 5,
    marginBottom: 5,
  },
  placeholderText: {
    color: Colors.grayDisabled,
    fontSize: 16,
    marginLeft: 10,
    paddingVertical: 7,
  },
  chipsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '100%',
    width: '90%',
  },
  chevronContainer: {
    paddingVertical: 5,
    marginHorizontal: 'auto',
    height: '100%',
    alignItems: 'center',
  },
  areaInputContent: {
    height: 100,
    paddingTop: 20,
  },
  baseInputContent: {
    paddingHorizontal: 20,
    backgroundColor: '#fff',
    borderRadius: 8,
    width: 'auto',
  },
  textOutline: {
    borderWidth: 0,
  },
  searchInputStyle: {
    backgroundColor: Colors.white,
    borderWidth: 0,
  },
  checkboxContainer: {
    paddingHorizontal: 10,
  },
  checkboxLabel: {
    fontSize: 16,
    lineHeight: 19,
    color: Colors.black,
    fontWeight: '400',
    textAlign: 'left',
  },
});
