import { ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { TopComponent } from '../navigation';

export type SaveFormProps = {
  children: ReactNode;
  onSubmit: () => Promise<void>;
  title?: string;
  isDirty?: boolean;
  isValid?: boolean;
  saveLabel?: string;
  hasBorder?: boolean;
  topBar?: TopComponent;
  buttonStyle?: StyleProp<ViewStyle>;
};

export type SaveFormTopProps = SaveFormProps & {
  title: string;
  topBar: TopComponent;
  onCancel?: () => void;
  additionalHeaderBlock?: ReactNode;
};

export type SaveFormComponent = (props: SaveFormProps) => ReactNode;

export type AutocompleteTextInput =
  | 'additional-name'
  | 'address-line1'
  | 'address-line2'
  | 'birthdate-day'
  | 'birthdate-full'
  | 'birthdate-month'
  | 'birthdate-year'
  | 'cc-csc'
  | 'cc-exp'
  | 'cc-exp-day'
  | 'cc-exp-month'
  | 'cc-exp-year'
  | 'cc-number'
  | 'country'
  | 'current-password'
  | 'email'
  | 'family-name'
  | 'gender'
  | 'given-name'
  | 'honorific-prefix'
  | 'honorific-suffix'
  | 'name'
  | 'name-family'
  | 'name-given'
  | 'name-middle'
  | 'name-middle-initial'
  | 'name-prefix'
  | 'name-suffix'
  | 'new-password'
  | 'nickname'
  | 'one-time-code'
  | 'organization'
  | 'organization-title'
  | 'password'
  | 'password-new'
  | 'postal-address'
  | 'postal-address-country'
  | 'postal-address-extended'
  | 'postal-address-extended-postal-code'
  | 'postal-address-locality'
  | 'postal-address-region'
  | 'postal-code'
  | 'street-address'
  | 'sms-otp'
  | 'tel'
  | 'tel-country-code'
  | 'tel-national'
  | 'tel-device'
  | 'url'
  | 'username'
  | 'username-new'
  | 'off'
  | undefined;
