export const FIND_ASSIGNABLE_ACCOUNTS = `
  query FindAssignableAccounts(
  $filter: FindAssignableAccountsFilter!
  $page: PageRequest
) {
  group {
    findAssignableAccounts(filter: $filter, page: $page) {
      count
      limit
      offset
      items {
        id
        name
        jobTitle
        accountType {
          name
        }
        groups {
          id
          name
        }
      }
    }
  }
}
`;
