import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { anonymousRoutes } from '../anonymous/routes';
import { authRoutes } from '../auth/routes';
import { dashboardRoutes } from '../dashboard/routes';
import { onboardingRoutes } from '../onboarding/routes';
import { CheckAppVersion } from '../shared/components/checkAppVersion';
import { ErrorBoundary } from '../shared/logging/errorBoundry';
import { Home } from './home';
import { Tag } from './tag';

const ErrorBoundaryLayout = () => (
  <ErrorBoundary>
    <Outlet />
    <CheckAppVersion />
  </ErrorBoundary>
);

export const routes: RouteObject[] = [
  {
    element: <ErrorBoundaryLayout />,
    children: [
      { path: '/', element: <Home /> },
      {
        path: '/dashboard',
        element: <Navigate to="/" replace />,
      },
      {
        path: '/dashboard/*',
        element: <Navigate to="/" replace />,
      },
      ...dashboardRoutes,
      ...onboardingRoutes,
      ...authRoutes,
      ...anonymousRoutes,
      { path: ':tagId', element: <Tag /> }, // default route for tags (needs to be last)
    ],
  },
];
