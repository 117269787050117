export const FIND_GROUP = `
  query FindGroups($filter: GroupFilter) {
  findGroups(filter: $filter) {
    items {
      id
      name
    }
  }
}
`;
