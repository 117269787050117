import { StyleProp, Text, View, ViewStyle } from 'react-native';
import { styles } from './styles';

type DividerTextProps = {
  text: string;
  styleContainer?: StyleProp<ViewStyle>;
};

export const DividerText = (props: DividerTextProps) => {
  return (
    <View
      style={[
        styles.dividerContainer,
        props?.styleContainer ? props.styleContainer : {},
      ]}
    >
      <View style={styles.divider} />
      <View>
        <Text style={styles.dividerText}>{props.text}</Text>
      </View>
      <View style={styles.divider} />
    </View>
  );
};
