import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { useCurrentWorkspace } from '@gripp/shared-logic';
import { Colors } from '@gripp/shared-ui';
import { StyleSheet, Text, View } from 'react-native';
import { ModalButton } from '../../shared/components/modalButton';
import { EditGroupForm } from './editGroupForm';

type EditGroupMenuProps = {
  label: any;
  groupId: string;
};

export const EditGroupMenu = (props: EditGroupMenuProps) => {
  const currentWorkspace = useCurrentWorkspace();

  return (
    currentWorkspace && (
      <View style={styles.groupEditContainer}>
        <ModalButton
          label=""
          omitPadding={true}
          customButton={
            <View style={{ flexDirection: 'row' }}>
              <MaterialCommunityIcons
                name="pencil-outline"
                color={Colors.blackText}
                size={16}
                style={styles.icon}
              />
              <Text style={styles.label}>{props.label}</Text>
            </View>
          }
          buttonStyle={styles.editGroupButton}
          modalComponent={({ onComplete }) => {
            return (
              <EditGroupForm
                onComplete={onComplete}
                workspaceId={currentWorkspace}
                groupId={props.groupId}
              />
            );
          }}
          containerStyle={styles.modalContainer}
        />
      </View>
    )
  );
};

const styles = StyleSheet.create({
  groupEditContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20,
  },
  editGroupButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 117,
    height: 32,
    backgroundColor: Colors.white,
    borderRadius: 16,
  },
  icon: {
    marginRight: 4,
  },
  label: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: -0.25,
  },
  modalContainer: {
    alignSelf: 'center',
    backgroundColor: Colors.primaryGrayBackground,
    borderRadius: 10,
    width: 560,
    height: 218,
  },
});
