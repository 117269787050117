import { EnvConfig } from '@gripp/shared-logic';
import { FlatList, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { Colors } from '../../../themes';
import { AccountAvatar } from '../../accountAvatar';
import { SingleEmoji } from '../../emoji/singleEmoji';
import { UserInfoModal } from './types';

export const UserReaction = ({ users, reactionType }: UserInfoModal) => {
  return (
    <View style={styles.container}>
      <View style={styles.emojiHeader}>
        <SingleEmoji emojiStyle={styles.emojiStyle} emoji={reactionType} />
      </View>
      <FlatList
        data={users}
        keyExtractor={(item, index) =>
          `reaction-${reactionType}-${item.id}-${index}`
        }
        renderItem={({ item }) => (
          <View style={styles.userContainer}>
            <AccountAvatar
              avatar={item.avatar || ''}
              mediaUrl={EnvConfig.mediaUrl}
              size={36}
            />
            <Text key={item.id} style={styles.nameLabel}>
              {item.name.first} {item.name.last}
            </Text>
          </View>
        )}
        showsVerticalScrollIndicator
        style={{ flex: 1 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 9,
    backgroundColor: Colors.white,
    borderRadius: 12,
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  emojiHeader: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    borderBottomWidth: 0.5,
    borderBottomColor: Colors.grayDivider,
    paddingBottom: 8,
    marginBottom: 8,
    marginHorizontal: 8,
  },
  emojiStyle: {
    fontSize: 26,
    marginRight: 3,
    letterSpacing: -0.25,
    shadowOffset: {
      width: 2,
      height: 3,
    },
    shadowColor: Colors.black,
    shadowOpacity: 0.4,
  },
  emojiStyleCounter: {
    position: 'absolute',
    top: -5,
    right: '43%',
    padding: 2,
    color: Colors.primaryDark,
  },
  userContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 50,
    marginLeft: 8,
  },
  nameLabel: {
    fontSize: 16,
    fontWeight: '500',
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginLeft: 4,
  },
});
