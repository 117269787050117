import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  AccountInput,
  useGrippContext,
  useSendAuthCode,
  useStorageKeys,
} from '@gripp/shared-logic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { Button, PaperProvider } from 'react-native-paper';
import { string, object as yupObject } from 'yup';
import { EditProfileForm, FormContext, PhoneValidator } from '../../components';
import { BaseTheme, Colors } from '../../themes';

type Props = {
  onVerify: () => void;
  onBack: () => void;
};

export const RegisterForm = (props: Props) => {
  const { t } = useTranslation();
  const { storageHandler } = useGrippContext();
  const storageKeys = useStorageKeys();
  const { registerWithAuthCode, isPending } = useSendAuthCode();

  const formSchema = yupObject({
    firstName: string().required(),
    lastName: string().optional().nullable(),
    jobTitle: string().optional().nullable(),
    email: string().email().required(),
    phone: PhoneValidator.optional().nullable(),
    registerType: string().required(),
  }).test('email or phone', (value) => !!(value.phone || value.email));

  const formMethods = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    values: {
      firstName: '',
      lastName: '',
      jobTitle: '',
      email: '',
      phone: null,
      registerType: 'email',
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    setValue,
  } = formMethods;

  useEffect(() => {
    const loadAuthInput = async () => {
      const storedInput = await storageHandler.getItem(
        storageKeys.authCodeInput
      );

      if (storedInput && JSON.parse(storedInput)?.email) {
        setValue('email', JSON.parse(storedInput).email);
      }
      if (storedInput && JSON.parse(storedInput)?.phone) {
        setValue('phone', JSON.parse(storedInput).phone);
        setValue('registerType', 'phone');
      }
    };

    loadAuthInput();
  }, [setValue, storageHandler, storageKeys.authCodeInput]);

  const onSubmit = async (data: any) => {
    const input = {
      name: {
        first: data.firstName,
        last: data.lastName,
      },
      phone: data.phone,
      email: data.email,
      jobTitle: data.jobTitle,
      registerType: data.registerType,
    } as AccountInput;

    await registerWithAuthCode(input, props.onVerify);
  };

  return (
    <PaperProvider theme={BaseTheme}>
      <FormContext.Provider value={formMethods}>
        <View style={styles.container}>
          <View style={styles.formContainer}>
            <Button
              style={{ width: 24 }}
              labelStyle={{ marginHorizontal: 0 }}
              contentStyle={{
                alignSelf: 'flex-start',
              }}
              onPress={props.onBack}
            >
              <MaterialCommunityIcons
                name="arrow-left"
                size={24}
                color={Colors.black}
              />
            </Button>
            <Text style={styles.headerText}>{t('login.aboutYourself')}</Text>
            <Button
              style={{ alignSelf: 'flex-start', borderRadius: 0 }}
              labelStyle={styles.button}
              onPress={props.onBack}
            >
              {t('login.existingAccount')}
            </Button>
            <EditProfileForm control={control} autoFocus />
            <Button
              mode="contained"
              contentStyle={styles.btnContent}
              style={styles.continueButton}
              labelStyle={styles.label}
              disabled={!(isDirty && isValid)}
              onPress={handleSubmit(onSubmit)}
              loading={isPending}
            >
              {t('login.continue')}
            </Button>
          </View>
        </View>
      </FormContext.Provider>
    </PaperProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.primaryGrayBackground,
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    flex: 1,
    width: 350,
    justifyContent: 'center',
  },
  continueButton: { borderRadius: 8, width: '100%' },
  btnContent: { height: 44 },
  label: {
    fontSize: 16,
    fontWeight: 500,
  },
  headerText: {
    fontSize: 22,
    fontWeight: '700',
  },
  button: {
    color: Colors.primaryDark,
    textDecorationLine: 'underline',
    fontSize: 14,
    fontWeight: 400,
    marginHorizontal: 0,
  },
});
