import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { useCurrentAccount } from '@gripp/shared-logic';
import { Colors, RadioSelect } from '@gripp/shared-ui';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { DataTable } from 'react-native-paper';
import { ExportButton } from '../../workspaces/components/dataExport/exportButton';
import { useUserActivityExport } from '../../workspaces/components/dataExport/useUserActivityExport';

export const AccountPage = () => {
  const { t } = useTranslation();
  const { account } = useCurrentAccount();
  const [value, setValue] = useState<string | null>(null);
  const defaultUserSetup = useMemo(() => {
    return [
      { label: t('accountPage.types.general'), value: 'g' },
      { label: t('accountPage.types.admin'), value: 'a' },
      { label: t('accountPage.types.restricted'), value: 'r' },
    ];
  }, [t]);
  return (
    <View style={styles.container}>
      <Text style={styles.headerLabel}>
        {t('accountPage.header.accountTypes')}
      </Text>
      <View style={styles.newUsersContainer}>
        <Text style={styles.headerL2Label}>
          {t('accountPage.header.newUsersSetup')}:
        </Text>
        <View style={styles.newUsersSelectorContainer}>
          <RadioSelect
            label="--"
            items={defaultUserSetup}
            onValueChange={(value) => {
              setValue(value);
            }}
            selectedValue={value}
          />
        </View>
      </View>
      <View style={styles.tableEnvelopeContainer}>
        <View style={styles.tableContainer}>
          <DataTable>
            <DataTable.Header style={styles.tableHeader}>
              <DataTable.Title>
                <Text style={styles.titleText}>
                  {t('accountPage.table.headers.accountType')}
                </Text>
              </DataTable.Title>
              <DataTable.Title>
                <Text style={styles.titleText}>
                  {t('accountPage.table.headers.accessActivity')}
                </Text>
              </DataTable.Title>
              <DataTable.Title>
                <Text style={styles.titleText}>
                  {t('accountPage.table.headers.accessSettings')}
                </Text>
              </DataTable.Title>
            </DataTable.Header>
            <DataTable.Row style={styles.tableRow}>
              <DataTable.Cell>{t('accountPage.types.admin')}</DataTable.Cell>
              <DataTable.Cell style={styles.tableCell}>
                <Icon name="check" style={styles.icon} />
              </DataTable.Cell>
              <DataTable.Cell style={styles.tableCell}>
                <Icon name="check" style={styles.icon} />
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={styles.tableRow}>
              <DataTable.Cell>{t('accountPage.types.general')}</DataTable.Cell>
              <DataTable.Cell style={styles.tableCell}>
                <Icon name="check" style={styles.icon} />
              </DataTable.Cell>
              <DataTable.Cell style={styles.tableCell}>
                {t('accountPage.table.columns.generalToSettingsNote')}
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={styles.tableRow}>
              <DataTable.Cell>
                {t('accountPage.types.restricted')}
              </DataTable.Cell>
              <DataTable.Cell style={styles.tableCell}>
                {t('accountPage.table.columns.restrictedToActivitiesNote')}
              </DataTable.Cell>
              <DataTable.Cell style={styles.tableCell}>
                <Icon name="close" style={styles.icon} />
              </DataTable.Cell>
            </DataTable.Row>
            <DataTable.Row style={styles.tableRow}>
              <DataTable.Cell>
                {t('accountPage.types.anonymous')}
              </DataTable.Cell>
              <DataTable.Cell style={styles.tableCell}>
                <Icon name="close" style={styles.icon} />
              </DataTable.Cell>
              <DataTable.Cell style={styles.tableCell}>
                <Icon name="close" style={styles.icon} />
              </DataTable.Cell>
            </DataTable.Row>
          </DataTable>
        </View>
      </View>
      <View style={styles.infoContainer}>
        <Text style={styles.infoHeader}>{t('accountPage.info.header')}:</Text>
        <ul style={styles.list}>
          <li>
            <View style={styles.infoRow}>
              <Text style={styles.infoHeader}>
                {t('accountPage.types.admin')}
              </Text>
              <Text style={styles.headerL2Label}>
                -&nbsp;{t('accountPage.info.admin')}
              </Text>
            </View>
          </li>
          <li>
            <View style={styles.infoRow}>
              <Text style={styles.infoHeader}>
                {t('accountPage.types.general')}
              </Text>
              <Text style={styles.headerL2Label}>
                -&nbsp;{t('accountPage.info.general')}
              </Text>
            </View>
          </li>
          <li>
            <View style={styles.infoRow}>
              <Text style={styles.infoHeader}>
                {t('accountPage.types.restricted')}
              </Text>
              <Text style={styles.headerL2Label}>
                -&nbsp;{t('accountPage.info.restricted')}
              </Text>
            </View>
          </li>
          <li>
            <View style={styles.infoRow}>
              <Text style={styles.infoHeader}>
                {t('accountPage.types.anonymous')}
              </Text>
              <Text style={styles.headerL2Label}>
                -&nbsp;{t('accountPage.info.anonymous')}
              </Text>
            </View>
          </li>
        </ul>
        <View style={styles.download}>
          <Text style={styles.titleText}>Export User Activities:</Text>
          <ExportButton
            workspace={account?.workspace}
            icon="download"
            toolTip="Export User Activities"
            useExport={useUserActivityExport}
            fileName="User Activities"
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  headerOptions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 35,
    paddingHorizontal: 4,
    marginBottom: 12,
  },
  headerLabel: {
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.25,
    marginRight: 12,
  },
  headerL2Label: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.grayText,
  },
  newUsersContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 32,
    marginTop: 16,
    zIndex: 1000,
  },
  newUsersSelectorContainer: {
    marginLeft: 16,
    minWidth: 250,
    zIndex: 1000,
  },
  tableEnvelopeContainer: {
    maxHeight: 800,
  },
  tableContainer: {
    marginTop: 16,
    flex: 1,
    borderRadius: 12,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  tableHeader: {
    backgroundColor: Colors.primaryGrayHeader,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottomWidth: 0,
    paddingLeft: 0,
    paddingTop: 0,
    marginTop: 0,
    height: 'auto',
    paddingRight: 31,
  },
  tableRow: {
    cursor: 'auto',
  },
  tableCell: {
    borderLeftWidth: 1,
    borderLeftColor: Colors.graySpacer,
    paddingLeft: 12,
  },
  titleText: {
    fontWeight: '500',
    fontSize: 16,
    color: Colors.blackText,
    letterSpacing: -0.2,
    paddingLeft: 20,
  },
  icon: {
    color: Colors.primary,
    paddingRight: 10,
    fontSize: 30,
  },
  infoContainer: {
    marginTop: 16,
    flex: 1,
    overflow: 'hidden',
  },
  infoHeader: {
    fontSize: 16,
    fontWeight: '700',
    letterSpacing: -0.25,
    marginRight: 12,
    marginBottom: 0,
  },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  list: {
    paddingLeft: 30,
    marginTop: 8,
  },
  download: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
