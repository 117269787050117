import { RoutineModules } from '../routine';
import { MessageComponent, MessageComponentProps } from './types';

export const RoutineMessage: MessageComponent = (
  props: MessageComponentProps
) => {
  const routineType = props.message.assetRoutine?.routineVersion?.type;

  if (!routineType) {
    return null;
  }

  const routinModule = RoutineModules[routineType];
  const Message = routinModule.message;

  return <Message {...props} />;
};
