import { Switch, Text, View } from 'react-native';
import { Colors } from '../../themes';
import { useFormContext } from '../forms';
import { filterStyles as styles } from './filterStyle';

type SwitchToggleProps = {
  fieldName: string;
  label: string;
};

export const SwitchToggle = (props: SwitchToggleProps) => {
  const { setValue, watch } = useFormContext();

  const toggleFilter = watch(props.fieldName) || false;

  const toggleSwitch = () => {
    setValue(props.fieldName, !toggleFilter, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  return (
    <View style={styles.toggleFilterContainer}>
      <Text
        style={[
          styles.filterRowHeader,
          {
            marginBottom: 0,
            flexDirection: 'row',
            alignItems: 'center',
          },
        ]}
      >
        {props.label}
      </Text>
      <Switch
        trackColor={{
          false: Colors.grayButtonBorder,
          true: Colors.primary,
        }}
        thumbColor={toggleFilter ? Colors.primaryGrayBackground : undefined}
        activeThumbColor={
          toggleFilter ? Colors.primaryGrayBackground : undefined
        }
        value={toggleFilter}
        onValueChange={toggleSwitch}
      />
    </View>
  );
};
