import { CodeLogin } from '@gripp/shared-ui';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  const navigate = useNavigate();

  const handleRedirect = async (redirectPath: string) => {
    navigate(redirectPath);
    return Promise.resolve();
  };

  return (
    <CodeLogin
      handleRedirect={handleRedirect}
      onCodeSent={() => navigate('/verify-auth-code')}
      onRegister={() => navigate('/register')}
    />
  );
};
