/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LIST_GROUPS,
  useCurrentWorkspace,
  useGroupListFilter,
  useGroupListOrder,
} from '@gripp/shared-logic';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteDataGrid } from '../infiniteDataGrid';
import { GroupTableData, useColumns } from './useColumns';

export type GroupListProps = {
  maxHeight?: number;
  paddingBottom?: number;
  preserveScrollKey?: string;
  assetButton?: (data: GroupTableData) => JSX.Element;
  peopleButton?: (data: GroupTableData) => JSX.Element;
  editGroupMenu: (label: any) => JSX.Element;
};

export const GroupsList = ({
  maxHeight,
  paddingBottom,
  preserveScrollKey,
  assetButton,
  peopleButton,
  editGroupMenu,
}: GroupListProps) => {
  const { t } = useTranslation();

  const AssetButton = assetButton;
  const PeopleButton = peopleButton;
  const EditGroupMenu = editGroupMenu;

  const { data } = useGroupListFilter();
  const { data: orderData } = useGroupListOrder();
  const workspace = useCurrentWorkspace(true);

  const [filter, setFilter] = useState<any>(undefined);
  const [groupsSelected, setGroupsSelected] = useState<string[] | undefined>(
    undefined
  );

  const showGroupMenu = useMemo(() => {
    return groupsSelected?.length === 1 ? true : false;
  }, [groupsSelected]);

  const updateGroupSelection = (rows: any) => {
    setGroupsSelected(rows);
  };

  useEffect(() => {
    if (!data || workspace === undefined) return;
    const f = { ...data };
    f.workspaceId = workspace?.id;
    if (f.keyword === '') delete f.keyword;
    setFilter(f);
  }, [data, workspace]);

  const columns = useColumns(filter, AssetButton, PeopleButton) as ColumnDef<
    any,
    any
  >[];

  const RenderEditGroupMenu = () =>
    showGroupMenu && groupsSelected ? (
      <EditGroupMenu
        groupId={groupsSelected[0]}
        label={t('groups.menu.editGroup')}
      />
    ) : (
      <></>
    );

  return !filter ? null : (
    <InfiniteDataGrid
      modelName="group"
      query={LIST_GROUPS}
      orderBy={orderData}
      filter={filter}
      pageSize={50}
      columns={columns}
      listMaxHeight={maxHeight as number}
      paddingBottom={paddingBottom as number}
      textEmpty={t('groups.common.textEmpty')}
      dataPrefix="group.listGroups"
      enableScrollRefresh={true}
      preserveScrollKey={preserveScrollKey}
      showScrollIndicator={true}
      exportFileName="groups"
      updateRowsSelected={(rows) => updateGroupSelection(rows)}
      headerContent={RenderEditGroupMenu()}
    />
  );
};
